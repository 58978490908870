/* eslint-disable */
import { useEffect, useState } from 'react';
import { useRecoilState } from "recoil";

import api from './../../libs/api';

import { farmCode } from '../../store/store';
import { Alert } from './../Alert';

function FarmSelect() {
    const [farmList, setFarmList] = useState([]);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);

    const pathname = window.location.pathname;

    useEffect(() => {
        // 로그인, 회원가입 예외처리
        (pathname !== "/login" && pathname !== "/join" && pathname !== "/term" && pathname !== "/overseas_login_blocker") && getFarmList();
    }, []);

    // 내 농장 리스트
    async function getFarmList() {
        const response = await api.get('/farm/myFarmList');

        // 성공
        if (response.data.code === "200") {
            setFarmList(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;

    // 접속 농장 변경
    function farmChange(e) {
        let farmCode = farmList.filter(farm => farm.farmCode == e.target.value)[0].farmCode;

        localStorage.setItem("farmCode", farmCode);
        setCurrentFarmCode(farmCode);
        
        if (pathname === "/service_mng/purchasing_product") {
            window.location.href = "/service_mng/purchasing_product";
        }
    }

    if (window.location.pathname === "/login" || window.location.pathname === "/join" || window.location.pathname === "/term" || window.location.pathname === "/overseas_login_blocker") return null; // 로그인, 회원가입, 약관, 해외 로그인 차단 페이지 제외
    return (
        <div className="farm_select">
            <select 
                name="" 
                // defaultValue={currentFarm[0].id} // TODO
                value={current}
                onChange={farmChange}
            >
                {
                    farmList.length > 0 &&
                    farmList.map((farm, i) => {
                        return (
                            <option 
                                key={farm.farmCode} 
                                value={farm.farmCode}
                            >
                                {farm.farmName}
                            </option>
                        );
                    })
                }
            </select>

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    );
}

export default FarmSelect;