/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import api from './../../libs/api';
import { Link } from 'react-router-dom';

import { useRecoilState } from "recoil";
import { farmCode } from '../../store/store';
import { Alert } from '../../components/Alert';
import getMessage from "../../libs/getMessage";

function AccordionItem({ farm, handleClick, click}) {
    const nodeRef = useRef(null);

    return (
        <li 
            className={`accordionItem ${click === farm.farmCode ? "on" : ""}`}
            onClick={()=> handleClick(farm.farmCode)}
        >
            <div className="title">
                <p className="text mr-5">{farm.farmName}</p>
            </div>
            <CSSTransition
                in={click === farm.farmCode}
                timeout={300}
                classNames="accordion"
                unmountOnExit
                nodeRef={nodeRef}
            >
                <div className="desc" ref={nodeRef}>
                    <div className="d-flex">
                        <h4 className="tit">Number of cows</h4>
                        <p className="">{farm.cowCount}</p>
                    </div>
                    <div className="d-flex">
                        <h4 className="tit">Service in use</h4>
                        <p className="">{farm.serviceType}</p>
                    </div>
                    <div className="d-flex">
                        <h4 className="tit">Farm code</h4>
                        <p className="">{farm.farmCode}</p>
                    </div>
                    <div className="d-flex">
                        <h4 className="tit">Farm address</h4>
                        <p className="">{farm.farmAddr}</p>
                    </div>
                    {/* <div className="btn_wrap">
                        <Link to={`/farm_mng/${farm.farmCode}`}>수정</Link>
                    </div> */}
                </div>
            </CSSTransition>
        </li>
    )
}

function FarmMng() {
    const [farmList, setFarmList] = useState([]);
    
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode == undefined ? currentFarmCode : currentFarmCode.farmCode;

    useEffect(() => {
        getFarmList();
    }, [currentFarmCode]);

    // 농장 리스트 
    async function getFarmList() {
        const response = await api.get('/farm/myFarmList');
        
        // 성공
        if (response.data.code === "200") {
            setFarmList(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    console.log('farmList', farmList);

    let [currentFarm] = farmList.filter((list) => list.farmCode === current);
    let otherFarm = farmList.filter((list) => list.farmCode !== current);

    // accordion
    const [click, setClick] = useState(null);
    function handleClick(id) {
        if (click === id) {
            setClick(null);
        } else {
            setClick(id);
        }
    }

    return (
        <div className="container">
            <h1 className="title">{getMessage("Farmmng.farmmanagement")}</h1>
            <div className="current con">
                <div className="content_header d-flex">
                    <span className="icon_wrap not_bg">
                        <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                    </span>
                    <span className="div_title mr-auto">{getMessage("Farmmng.information")}</span>
                    <Link to="/farm_mng/reg" className="button mr-5">
                        <img className="icon mr-10" src="/assets/images/common/icon_reg.png" alt="registration" />
                    </Link>
                    <Link to={`/farm_mng/${current}`} className="button">
                        <img className="icon" src="/assets/images/common/icon_edit.png" alt="edit" />
                    </Link>
                </div>
                <div className="content_body">
                    <div className="d-flex">
                        <h4 className="tit">{getMessage("Farmmng.farmname")}</h4>
                        <p className="">{currentFarm?.farmName}</p>
                    </div>
                    <div className="d-flex dashed_b">
                        <h4 className="tit">{getMessage("Farmmng.farmcode")}</h4>
                        <p className="">{currentFarm?.farmCode}</p>
                    </div>
                    <div className="d-flex dashed_b">
                        <h4 className="tit">{getMessage("Farmmng.farmaddress")}</h4>
                        <p className="">{currentFarm?.farmAddr}</p>
                    </div>
                    <div className="d-flex dashed_b">
                        <h4 className="tit">{getMessage("Farmmng.numberofcows")}</h4>
                        <p className="">{currentFarm?.cowCount}</p>
                    </div>
                    <div className="d-flex dashed_b">
                        <h4 className="tit">{getMessage("Farmmng.partner")}</h4>
                        <p className="">{currentFarm?.partnerId}</p>
                    </div>
                </div>
            </div>
            <div className="another con">
                <div className="content_header d-flex">
                    <span className="icon_wrap not_bg">
                        <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                    </span>
                    <span className="div_title mr-auto">{getMessage("Farmmng.anotherfarm")}</span>
                </div>
                <div className="content_body">
                    <ul>
                        {
                            otherFarm &&
                            otherFarm.map((farm, i) => {
                                return (
                                    <AccordionItem
                                        farm={farm}
                                        key={farm.farmCode}
                                        handleClick={handleClick}
                                        click={click}
                                        setClick={setClick}
                                    />
                                )
                            })
                        }
                    </ul>
                </div>
            </div>

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    );
}

export default FarmMng;