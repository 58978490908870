// 날짜
function getDate(date) {
    if (date) {
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        
        month = (month < 10) ? "0" + String(month) : month;
        day = (day < 10) ? "0" + String(day) : day;

        return year + '-' + month + '-' + day;
    }
}

// 날짜 시간
function getDateTime(date) {
    if (date) {
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        
        month = (month < 10) ? "0" + String(month) : month;
        day = (day < 10) ? "0" + String(day) : day;

        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();

        hours = (hours < 10) ? "0" + String(hours) : hours;
        minutes = (minutes < 10) ? "0" + String(minutes) : minutes;
        seconds = (seconds < 10) ? "0" + String(seconds) : seconds;

        return year + '-' + month + '-' + day + " " + hours + ":" + minutes + ":" + seconds;
    }
}

// n일 전/후 날짜 계산
function calculateDate(calDate) {
    let date = new Date();
    let dayOfMonth = date.getDate();
    date.setDate(dayOfMonth + calDate);

    return date;
}

export { getDate, getDateTime, calculateDate };