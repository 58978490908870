/* eslint-disable */
import { useEffect, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import api from '../../libs/api';

import { useRecoilState } from "recoil";
import { farmCode } from '../../store/store';
import DateTermBtn from '../../components/DateTermBtn';
import { calculateDate, getDate } from '../../libs/getDate';
import DatePicker from 'react-datepicker';
import { numberPipe } from '../../libs/pipe';
import { Alert } from '../../components/Alert';
import getMessage from "../../libs/getMessage";
// TODO-JK : 번역 필요
function AccordionItem({ history, handleClick, click }) {
    const nodeRef = useRef(null);

    return (
        <>
            <tr 
                className={`accordionItem ${history.dailyFarmPointList.length < 1 ? "arrow_none " : ""} ${click === history.usedDate ? "on" : ""}`}
                onClick={()=> handleClick(history)}
            >
                <td>{history.usedDate}</td>

                <td className={history.addPoint !== "0" ? "charging t-right pr-10" : "t-right pr-10"}>
                    {history.addPoint !== "0" ? numberPipe(history.addPoint) : "-"}
                </td>

                <td className="t-right pr-10">
                    {/* TODO -값으로 내려오는 거 확인 후 삼항연산자 없애고 split 값만 남겨주면 될듯 */}
                    {
                        history.usedPoint === "0" 
                        ? "-" 
                        : history.usedPoint.indexOf("-") > -1
                            ? numberPipe(history.usedPoint.split("-")[1])
                            : history.usedPoint
                    }
                </td>

                <td className={Number(history.restPoint) < 300000 ? "t-center pr-10 t_danger" : "t-center pr-10"}>{numberPipe(history.restPoint)}</td>
            </tr>
            {
                history.usedPoint !== "0" &&
                <CSSTransition
                    in={click === history.usedDate}
                    timeout={300}
                    classNames="accordion"
                    unmountOnExit
                    nodeRef={nodeRef}
                >
                    <tr>
                        <td colSpan={4} className="p-5">
                            <div className="desc" ref={nodeRef}>
                                <div className="con mb-5">
                                    <div className="d-flex">
                                        <span className="d_title mr-auto">
                                            Detail
                                        </span>
                                    </div>
                                    <table className="farm_point_history">
                                        <colgroup>
                                            <col width="80px" />
                                            <col width="*" />
                                            <col width="60px" />
                                            <col width="50px" />
                                        </colgroup>
                                        <thead>
                                            <tr className="border-b">
                                                <th className="bg p-5">Farm</th>
                                                <th className="bg p-5">Service use</th>
                                                <th className="bg t-right p-5">Use credit</th>
                                                <th className="bg t-center p-5">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                history.dailyFarmPointList.map((list, i) => {
                                                    return (
                                                        // TODO 중복 키값 없어지면 i 빼야할듯
                                                        <tr key={list.serviceName + i}>
                                                            <td className="p-5">{list.farmName}</td>
                                                            <td className="p-5">{list.serviceName}</td>
                                                            <td className="t-right p-5">
                                                                {/* TODO -값으로 내려오는 거 확인 후 삼항연산자 없애고 split 값만 남겨주면 될듯 */}
                                                                {
                                                                    list.usedPoint.indexOf("-") > -1
                                                                    ? numberPipe(list.usedPoint.split("-")[1])
                                                                    : numberPipe(list.usedPoint)
                                                                }
                                                            </td>
                                                            <td className="t-center p-5">
                                                                <span className="total">
                                                                    {/* TODO -값으로 내려오는 거 확인 후 삼항연산자 없애고 split 값만 남겨주면 될듯 */}
                                                                    {
                                                                        history.usedPoint.indexOf("-") > -1 
                                                                        ? numberPipe(history.usedPoint.split("-")[1])
                                                                        : numberPipe(history.usedPoint)
                                                                    }
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </td>
                    </tr>
                </CSSTransition>
            }
        </>
    )
}


function CreditUsageHistory() {
    const [pointHistory, setPointHistory] = useState([]);
    const [startDate, setStartDate] = useState(calculateDate(-30));
    const [endDate, setEndDate] = useState(calculateDate(0));
    const [dateTerm, setDateTerm] = useState("day");
    
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;

    useEffect(() => {
        getPointHistory();
    }, [currentFarmCode, startDate, endDate]);

    let memberId = JSON.parse(localStorage.getItem("userInfo")).memberId;

    async function getPointHistory() {
        const response = await api.get(`/point/myPointList?memberId=${memberId}&startDate=${getDate(startDate)}&endDate=${getDate(endDate)}`);
        
        // 성공
        if (response.data.code === "200") {
            setPointHistory(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    console.log('pointHistory', pointHistory);

    function handleChange(date, type) {
        if (type === "start") {
            setStartDate(date);
        } else {
            setEndDate(date);
        }
    }

    // accordion
    const [click, setClick] = useState(null);
    function handleClick(history) {
        if (history.dailyFarmPointList.length > 0) {
            if (click === history.usedDate) {
                setClick(null);
            } else {
                setClick(history.usedDate);
            }
        }
    }

    return (
        <div className="container">
            <h1 className="title">{getMessage("Creditusagehistory.creditusagehistory")}</h1>
            <div className="content_header d-flex">
                <span className="icon_wrap not_bg">
                    <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                </span>
                <span className="div_title mr-auto">
                    {getMessage("Creditusagehistory.creditusageinquiry")} &#40;All farm&#41;
                </span>
            </div>
            <div className="d-flex date_wrap">                
                <div className="ml-auto">
                    {/* <DateTermBtn dateTerm={dateTerm} setDateTerm={setDateTerm} /> */}
                </div>
                <div className="datepicker_wrap from_to">
                    <img src="/assets/images/common/icon_calendar.png" className="calendar" alt="" />
                    <DatePicker
                        selected={startDate}
                        // onChange={(date) => setStartDate(date)}
                        onChange={(date) => handleChange(date, 'start')}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        // locale={ko}
                        // dateFormat="yy.MM.dd"
                        dateFormat={
                            dateTerm === "day"
                            ? "yy.MM.dd"
                            : dateTerm === "month"
                                ? "yyyy.MM"
                                : "yyyy"
                        }
                        showMonthYearPicker={dateTerm === "month" ? true : false}
                        showYearPicker={dateTerm === "year" ? true : false}
                    />
                    <span className="from_to">~</span>
                    <DatePicker
                        selected={endDate}
                        // onChange={(date) => setEndDate(date)}
                        onChange={(date) => handleChange(date, 'end')}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        // locale={ko}
                        // dateFormat="yy.MM.dd"
                        dateFormat={
                            dateTerm === "day"
                            ? "yy.MM.dd"
                            : dateTerm === "month"
                                ? "yyyy.MM"
                                : "yyyy"
                        }
                        showMonthYearPicker={dateTerm === "month" ? true : false}
                        showYearPicker={dateTerm === "year" ? true : false}
                    />
                    <img src="/assets/images/common/icon_calendar_arrow.png" className="arrow" alt="" />
                </div>
            </div>
            <div className="content_body">
                <table>
                    <thead>
                        <tr>
                            <th className="p-5">{getMessage("Credit_usage_history.date")}</th>
                            <th className="t-right pr-10">{getMessage("Credit_usage_history.charging")}</th>
                            <th className="t-right pr-10">{getMessage("Credit_usage_history.creditused")}</th>
                            <th className="t-center pr-10">{getMessage("Credit_usage_history.currentcredit")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            pointHistory &&
                            pointHistory.map((history) => {
                                return (
                                    <AccordionItem
                                        history={history}
                                        key={history.usedDate}
                                        handleClick={handleClick}
                                        click={click}
                                        setClick={setClick}
                                    />
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    );
}

export default CreditUsageHistory;