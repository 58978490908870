/* eslint-disable */
import { useEffect, useState, useRef, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';

import api from '../../libs/api';

import { useRecoilState } from "recoil";
import { farmCode } from '../../store/store';
import { Controller, useForm } from 'react-hook-form';
import { getDate } from '../../libs/getDate';
import DatePicker from 'react-datepicker';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Alert } from './../../components/Alert';

function ConfirmPopup({ setOpen, application, submitData }) {
    return (
        <>
            <div className="popup">
                <div className="popup_header">
                
                </div>
                <div className="popup_body">
                    <div className="d-flex">
                        <div className="popup_alert">
                            <img src="/assets/images/common/icon_alert.png" alt="" />
                        </div>
                        <span>
                            {
                                submitData.gubun === undefined 
                                ? <>산차 정보 초기화 후 재생성 됩니다. <br />
                                재생성하시겠습니까?</>
                                : <>산차 정보를 저장하시겠습니까?</>
                            }
                        </span>
                    </div>
                </div>
                <div className="popup_footer d-flex">
                    <a className="button cancel ml-auto mr-10" onClick={() => { setOpen(false) }}>No</a>
                    <a className="button mr-auto" onClick={() => { application(submitData) }}>Yes</a>
                </div>
            </div>
            <div className="popup_overlay"></div>
        </>
    )
}

function AccordionItem({ i, count, control, errors, handleClick, click, register, cowDetail, handleSubmit, setOpen, setSubmitData }) {
    const nodeRef = useRef(null);
    const [firstServiceDate, setFirstServiceDate] = useState("");
    const [secondServiceDate, setSecondServiceDate] = useState("");
    const [thirdServiceDate, setThirdServiceDate] = useState("");
    const [fourthServiceDate, setFourthServiceDate] = useState("");
    const [pregnantDate, setPregnantDate] = useState("");
    const [deliveryDate, setDeliveryDate] = useState("");
    const locale = localStorage.getItem("locale");

    useEffect(() => {
        if (count.dataList.length > 0) {
            let [first] = count.dataList.filter((list) => list.gubun === "TRY" && list.gubunPoint === "1");
            let [second] = count.dataList.filter((list) => list.gubun === "TRY" && list.gubunPoint === "2");
            let [third] = count.dataList.filter((list) => list.gubun === "TRY" && list.gubunPoint === "3");
            let [fourth] = count.dataList.filter((list) => list.gubun === "TRY" && list.gubunPoint === "4");
            let [pregnant] = count.dataList.filter((list) => list.gubun === "INPUT");
            let [delivery] = count.dataList.filter((list) => list.gubun === "DELIVERY");

            setFirstServiceDate(first.theDate);
            setSecondServiceDate(second.theDate);
            setThirdServiceDate(third.theDate);
            setFourthServiceDate(fourth.theDate);
            setPregnantDate(pregnant.theDate);
            setDeliveryDate(delivery.theDate);
        }
    }, []);

    return (
        <>
            <div 
                className={`accordionItem ${click === count.parity ? "on" : ""}`}
                onClick={()=> handleClick(count.parity)}
            >
                <div className="title gest_tit_wrap">
                    <span className="sub_tit w-35">
                        {
                            count.parity === 1
                            ? count.parity + "st"
                            : count.parity === 2
                                ? count.parity + "nd"
                                : count.parity === 3
                                    ? count.parity + "rd"
                                    : count.parity + "th"
                        }
                    </span>
                </div>
            </div>
            <CSSTransition
                in={click === count.parity}
                timeout={300}
                classNames="accordion"
                unmountOnExit
                nodeRef={nodeRef}
            >
                <div className="desc sub_con" ref={nodeRef}>
                    <div className="d-flex">
                        <span className="weight ml-auto" style={{ width: "75px" }}>Weight&#40;kg&#41;</span>
                        <span className="date" style={{ width: "110px" }}>Date</span>
                        <span className="ml-5" style={{ width: "20px" }}></span>
                    </div>
                    <div className="d-flex service_date">
                        <span className="tit">First Service Date</span>
                        <input 
                            type="hidden" 
                            name="parity"
                            value={i+1}
                            {...register(`gestationDate[${i}][0].parity`)}
                        />
                        <input 
                            type="hidden" 
                            name="cowNumber"
                            value={cowDetail?.cowNumber}
                            {...register(`gestationDate[${i}][0].cowNumber`)}
                        />
                        <input 
                            type="hidden" 
                            name="gubun"
                            value="TRY"
                            {...register(`gestationDate[${i}][0].gubun`)}
                        />
                        <input 
                            type="hidden" 
                            name="gubunPoint"
                            value="1"
                            {...register(`gestationDate[${i}][0].gubunPoint`)}
                        />
                        <input 
                            className="gInfo_weight"
                            type="text" 
                            name="weight"
                            {...register(`gestationDate[${i}][0].weight`)}
                        />
                        <div className="datepicker_wrap from_to solo yyyy gInfo">
                            <div>
                                <Controller
                                    name={`gestationDate[${i}][0].theDate`}
                                    control={control}
                                    // rules={{ required: true }}
                                    render={({ field:{ fieldState, onChange } }) => (
                                        <DatePicker
                                            selected={firstServiceDate !== "" && new Date(firstServiceDate)}
                                            onChange={(date) => {
                                                setFirstServiceDate(date);
                                                onChange(getDate(date));
                                            }}
                                            // locale={ko}
                                            dateFormat={locale === 'en' ? 'yyyy.MM.dd' : 'dd.MM.yyyy' }
                                        />
                                    )}
                                />
                            </div>
                            <span className="plus">
                                <img src="/assets/images/common/icon_calendar2.png" className="icon" alt="" />
                            </span>
                            {errors.selectDate && <span className="err_msg">This field is required</span>}
                        </div>
                        <a 
                            className="plus"
                            onClick={handleSubmit((data) => { 
                                setOpen(true);
                                setSubmitData(data.gestationDate[i][0]);
                            })}
                        >
                            <img className="icon" src="/assets/images/common/icon_pencil.png" alt="save" />
                        </a>
                    </div>
                    <div className="d-flex service_date">
                        <span className="tit">Second Service Date</span>
                        <input 
                            type="hidden" 
                            name="parity"
                            value={i+1}
                            {...register(`gestationDate[${i}][1].parity`)}
                        />
                        <input 
                            type="hidden" 
                            name="cowNumber"
                            value={cowDetail?.cowNumber}
                            {...register(`gestationDate[${i}][1].cowNumber`)}
                        />
                        <input 
                            type="hidden" 
                            name="gubun"
                            value="TRY"
                            {...register(`gestationDate[${i}][1].gubun`)}
                        />
                        <input 
                            type="hidden" 
                            name="gubunPoint"
                            value="2"
                            {...register(`gestationDate[${i}][1].gubunPoint`)}
                        />
                        <input 
                            className="gInfo_weight"
                            type="text" 
                            name="weight"
                            {...register(`gestationDate[${i}][1].weight`)}
                        />
                        <div className="datepicker_wrap from_to solo yyyy gInfo">
                            <div>
                                <Controller
                                    name={`gestationDate[${i}][1].theDate`}
                                    control={control}
                                    // rules={{ required: true }}
                                    render={({ field:{ fieldState, onChange } }) => (
                                        <DatePicker
                                            selected={secondServiceDate !== "" && new Date(secondServiceDate)}
                                            onChange={(date) => {
                                                setSecondServiceDate(date);
                                                onChange(getDate(date));
                                            }}
                                            // locale={ko}
                                            dateFormat={locale === 'en' ? 'yyyy.MM.dd' : 'dd.MM.yyyy' }
                                        />
                                    )}
                                />
                            </div>
                            <span className="plus">
                                <img src="/assets/images/common/icon_calendar2.png" className="icon" alt="" />
                            </span>
                            {errors.selectDate && <span className="err_msg">This field is required</span>}
                        </div>
                        <a 
                            className="plus"
                            onClick={handleSubmit((data) => { 
                                setOpen(true);
                                setSubmitData(data.gestationDate[i][1]);
                            })}
                        >
                            <img className="icon" src="/assets/images/common/icon_pencil.png" alt="save" />
                        </a>
                    </div>
                    <div className="d-flex service_date">
                        <span className="tit">Third Service Date</span>
                        <input 
                            type="hidden" 
                            name="parity"
                            value={i+1}
                            {...register(`gestationDate[${i}][2].parity`)}
                        />
                        <input 
                            type="hidden" 
                            name="cowNumber"
                            value={cowDetail?.cowNumber}
                            {...register(`gestationDate[${i}][2].cowNumber`)}
                        />
                        <input 
                            type="hidden" 
                            name="gubun"
                            value="TRY"
                            {...register(`gestationDate[${i}][2].gubun`)}
                        />
                        <input 
                            type="hidden" 
                            name="gubunPoint"
                            value="3"
                            {...register(`gestationDate[${i}][2].gubunPoint`)}
                        />
                        <input 
                            className="gInfo_weight"
                            type="text" 
                            name="weight"
                            {...register(`gestationDate[${i}][2].weight`)}
                        />
                        <div className="datepicker_wrap from_to solo yyyy gInfo">
                            <div>
                                <Controller
                                    name={`gestationDate[${i}][2].theDate`}
                                    control={control}
                                    // rules={{ required: true }}
                                    render={({ field:{ fieldState, onChange } }) => (
                                        <DatePicker
                                            selected={thirdServiceDate !== "" && new Date(thirdServiceDate)}
                                            onChange={(date) => {
                                                setThirdServiceDate(date);
                                                onChange(getDate(date));
                                            }}
                                            // locale={ko}
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    )}
                                />
                            </div>
                            <span className="plus">
                                <img src="/assets/images/common/icon_calendar2.png" className="icon" alt="" />
                            </span>
                            {errors.selectDate && <span className="err_msg">This field is required</span>}
                        </div>
                        <a 
                            className="plus"
                            onClick={handleSubmit((data) => { 
                                setOpen(true);
                                setSubmitData(data.gestationDate[i][2]);
                            })}
                        >
                            <img className="icon" src="/assets/images/common/icon_pencil.png" alt="save" />
                        </a>
                    </div>
                    <div className="d-flex service_date">
                        <span className="tit">Fourth Service Date</span>
                        <input 
                            type="hidden" 
                            name="parity"
                            value={i+1}
                            {...register(`gestationDate[${i}][3].parity`)}
                        />
                        <input 
                            type="hidden" 
                            name="cowNumber"
                            value={cowDetail?.cowNumber}
                            {...register(`gestationDate[${i}][3].cowNumber`)}
                        />
                        <input 
                            type="hidden" 
                            name="gubun"
                            value="TRY"
                            {...register(`gestationDate[${i}][3].gubun`)}
                        />
                        <input 
                            type="hidden" 
                            name="gubunPoint"
                            value="4"
                            {...register(`gestationDate[${i}][3].gubunPoint`)}
                        />
                        <input 
                            className="gInfo_weight"
                            type="text" 
                            name="weight"
                            {...register(`gestationDate[${i}][3].weight`)}
                        />
                        <div className="datepicker_wrap from_to solo yyyy gInfo">
                            <div>
                                <Controller
                                    name={`gestationDate[${i}][3].theDate`}
                                    control={control}
                                    // rules={{ required: true }}
                                    render={({ field:{ fieldState, onChange } }) => (
                                        <DatePicker
                                            selected={fourthServiceDate !== "" && new Date(firstServiceDate)}
                                            onChange={(date) => {
                                                setFourthServiceDate(date);
                                                onChange(getDate(date));
                                            }}
                                            // locale={ko}
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    )}
                                />
                            </div>
                            <span className="plus">
                                <img src="/assets/images/common/icon_calendar2.png" className="icon" alt="" />
                            </span>
                            {errors.selectDate && <span className="err_msg">This field is required</span>}
                        </div>
                        <a 
                            className="plus"
                            onClick={handleSubmit((data) => { 
                                setOpen(true);
                                setSubmitData(data.gestationDate[i][3]);
                            })}
                        >
                            <img className="icon" src="/assets/images/common/icon_pencil.png" alt="save" />
                        </a>
                    </div>
                    <div className="d-flex service_date">
                        <span className="tit">Pregnant Date</span>
                        <input 
                            type="hidden" 
                            name="parity"
                            value={i+1}
                            {...register(`gestationDate[${i}][4].parity`)}
                        />
                        <input 
                            type="hidden" 
                            name="cowNumber"
                            value={cowDetail?.cowNumber}
                            {...register(`gestationDate[${i}][4].cowNumber`)}
                        />
                        <input 
                            type="hidden" 
                            name="gubun"
                            value="INPUT"
                            {...register(`gestationDate[${i}][4].gubun`)}
                        />
                        <input 
                            type="hidden" 
                            name="gubunPoint"
                            value="1"
                            {...register(`gestationDate[${i}][4].gubunPoint`)}
                        />
                        <input 
                            className="gInfo_weight"
                            type="text" 
                            name="weight"
                            {...register(`gestationDate[${i}][4].weight`)}
                        />
                        <div className="datepicker_wrap from_to solo yyyy gInfo">
                            <div>
                                <Controller
                                    name={`gestationDate[${i}][4].theDate`}
                                    control={control}
                                    // rules={{ required: true }}
                                    render={({ field:{ fieldState, onChange } }) => (
                                        <DatePicker
                                            selected={pregnantDate !== "" && new Date(pregnantDate)}
                                            onChange={(date) => {
                                                setPregnantDate(date);
                                                onChange(getDate(date));
                                            }}
                                            // locale={ko}
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    )}
                                />
                            </div>
                            <span className="plus">
                                <img src="/assets/images/common/icon_calendar2.png" className="icon" alt="" />
                            </span>
                            {errors.selectDate && <span className="err_msg">This field is required</span>}
                        </div>
                        <a 
                            className="plus"
                            onClick={handleSubmit((data) => { 
                                setOpen(true);
                                setSubmitData(data.gestationDate[i][4]);
                            })}
                        >
                            <img className="icon" src="/assets/images/common/icon_pencil.png" alt="save" />
                        </a>
                    </div>
                    <div className="d-flex service_date">
                        <span className="tit">Delivery Date</span>
                        <input 
                            type="hidden" 
                            name="parity"
                            value={i+1}
                            {...register(`gestationDate[${i}][5].parity`)}
                        />
                        <input 
                            type="hidden" 
                            name="cowNumber"
                            value={cowDetail?.cowNumber}
                            {...register(`gestationDate[${i}][5].cowNumber`)}
                        />
                        <input 
                            type="hidden" 
                            name="gubun"
                            value="DELIVERY"
                            {...register(`gestationDate[${i}][5].gubun`)}
                        />
                        <input 
                            type="hidden" 
                            name="gubunPoint"
                            value="1"
                            {...register(`gestationDate[${i}][5].gubunPoint`)}
                        />
                        <input 
                            className="gInfo_weight"
                            type="text" 
                            name="weight"
                            {...register(`gestationDate[${i}][5].weight`)}
                        />
                        <div className="datepicker_wrap from_to solo yyyy gInfo">
                            <div>
                                <Controller
                                    name={`gestationDate[${i}][5].theDate`}
                                    control={control}
                                    // rules={{ required: true }}
                                    render={({ field:{ fieldState, onChange } }) => (
                                        <DatePicker
                                            selected={deliveryDate !== "" && new Date(deliveryDate)}
                                            onChange={(date) => {
                                                setDeliveryDate(date);
                                                onChange(getDate(date));
                                            }}
                                            // locale={ko}
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    )}
                                />
                            </div>
                            <span className="plus">
                                <img src="/assets/images/common/icon_calendar2.png" className="icon" alt="" />
                            </span>
                            {errors.selectDate && <span className="err_msg">This field is required</span>}
                        </div>
                        <a 
                            className="plus"
                            onClick={handleSubmit((data) => { 
                                setOpen(true);
                                setSubmitData(data.gestationDate[i][5]);
                            })}
                        >
                            <img className="icon" src="/assets/images/common/icon_pencil.png" alt="save" />
                        </a>
                    </div>
                    
                </div>
            </CSSTransition>
        </>
    )
}

function GestationInformation() {
    const [gestationInfo, setGestationInfo] = useState([]);
    const [cowDetail, setCowDetail] = useState({});
    const [birthDate, setBirthDate] = useState();
    const [open, setOpen] = useState(false);
    const [submitData, setSubmitData] = useState();

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;

    const urlParams = useParams();
    const history = useHistory();

    useEffect(() => {
        getCowDetail();
        getGestationInfo();
    }, [currentFarmCode]);

    // 소 상세
    async function getCowDetail() {
        const response = await api.get(`/farm/myCowDetail?cowNumber=${urlParams.code}`);
        
        // 성공
        if (response.data.code === "200") {
            setCowDetail(response.data.data);
            setBirthDate(new Date(response.data.data.cowBirth));
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    console.log('cowDetail', cowDetail);

    // 산차정보
    async function getGestationInfo() {
        const response = await api.get(`/farm/myCowLifeTime?cowNumber=${urlParams.code}`);
        console.log('response', response);
        
        // 성공
        if (response.data.code === "200") {
            // setGestationInfo(response.data.datas);
            setGestationInfo(response.data.datas.reverse());
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    console.log('gestationInfo', gestationInfo);
    
    // 기본값 세팅
    const params = {
        cowNumber: cowDetail?.cowNumber,
        cowBirth: birthDate,
        weight: cowDetail?.weight,
        gestationDate: 
            gestationInfo?.map((parity, i) => {
                return (
                    parity.dataList.map((list, i) => {
                        return (
                            {
                                cowNumber: cowDetail?.cowNumber,
                                gubun: parity.dataList[i]?.gubun,
                                gubunPoint: parity.dataList[i]?.gubunPoint,
                                parity: parity.parity,
                                theDate: parity.dataList[i]?.theDate,
                                weight: parity.dataList[i]?.weight === 0 ? "" : parity.dataList[i]?.weight
                            }
                        )
                    })
                )
            })
    }
    const { register, handleSubmit, reset, control, formState: { errors } } = useForm({
        defaultValues: useMemo(() => {
            return params;
        }, [gestationInfo])
    });
    useEffect(() => {
        setTimeout(() => {
            reset(params);
        }, 20);
    }, [cowDetail, gestationInfo]);

    // accordion
    const [click, setClick] = useState(null);
    function handleClick(id) {
        if (click === id) {
            setClick(null);
        } else {
            setClick(id);
        }
    }

    // 산차 재생성, 개별 수정 서버 전송
    async function application(data) {
        console.log('data', data);

        let url = "";
        let param = {};

        if (data.gubun === undefined) {
            // 산차 재생성
            data.cowBirth = getDate(params.cowBirth);
            url = "/farm/cowLifeTimeInsert";
            param = {
                birth: data.cowBirth,
                cowNumber: data.cowNumber,
                weight: data.weight
            }
            setAlertMsg("산차 생성이 완료되었습니다.");
        } else {
            // 산차 개별 수정
            url = "/farm/cowLifeTimeUpdate";
            param = data;
            setAlertMsg("저장되었습니다.");
        }
        console.log('url', url);
        console.log('param', param);
        
        const response = await api.post(url, param);

        // 성공
        if (response.data.code === "200") {
            setOpen(false);
            setAlertOpen(true);

            getCowDetail();
            getGestationInfo();
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    return (
        <div className="container">
            <h1 className="title">Cow management</h1>
            <div className="con">
                <form 
                    name="gestation_information" 
                    // onSubmit={handleSubmit((data) => application(data))}
                >
                    <div className="content_header d-flex">
                        <span className="icon_wrap not_bg">
                            <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                        </span>
                        <span className="div_title mr-auto">Reproduction Information</span>
                    </div>
                    <div className="content_body">
                        <div className="d-flex sub_tit gestation_info_tit">
                            <span className="">산차 생성</span>
                            <a 
                                className="button ml-auto underline" 
                                onClick={handleSubmit((data) => { setSubmitData(data); setOpen(true); })}
                            >Application</a>
                        </div>
                        <div className="d-flex">
                            <label className="tit" htmlFor="cowBirth">Date of Birth</label>
                            {errors.cowBirth && <span className="err_msg mr-5">This field is required</span>}
                            <div className="datepicker_wrap input_wrap date from_to solo yyyy">
                                <div>
                                    <Controller
                                        name="cowBirth"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field:{ fieldState, onChange } }) => (
                                            <DatePicker
                                                // selected={cowDetail?.cowBirth && new Date(cowDetail?.cowBirth)}
                                                selected={birthDate}
                                                placeholderText={"yyyy-MM-dd"}
                                                onChange={(date) => {
                                                    setBirthDate(date);
                                                    // onChange(date);
                                                    onChange(getDate(date));
                                                }}
                                                // locale={ko}
                                                dateFormat="yyyy-MM-dd"
                                            />
                                        )}
                                    />
                                </div>
                                <span className="plus">
                                    <img src="/assets/images/common/icon_calendar2.png" className="icon" alt="" />
                                </span>
                                {errors.selectDate && <span className="err_msg mr-5">This field is required</span>}
                            </div>
                        </div>
                        <div className="d-flex">
                            <label className="tit" htmlFor="weight">Body Weight</label>
                            {errors.weight && <span className="err_msg mr-5">This field is required</span>}
                            <div className="d-flex input_wrap">
                                <input 
                                    type="text" 
                                    id="weight" 
                                    name="weight" 
                                    {...register("weight", { required: true })}
                                />
                            </div>
                        </div>
                        <div className="d-flex sub_tit gestation_info_tit">
                            <span className="">Gestation Date</span>
                        </div>
                        {
                            gestationInfo &&
                            gestationInfo.map((count, i) => {
                                return (
                                    <AccordionItem
                                        i={i}
                                        key={count.parity}
                                        count={count}
                                        control={control}
                                        errors={errors}
                                        handleClick={handleClick}
                                        click={click}
                                        setClick={setClick}
                                        register={register}
                                        cowDetail={cowDetail}
                                        handleSubmit={handleSubmit}
                                        setOpen={setOpen}
                                        setSubmitData={setSubmitData}
                                    />
                                )
                            })
                        }
                    </div>
                    <div className="content_footer d-flex s-ard">
                        <Link className="button cancel" to={`/cow_mng/${urlParams.code}`}>Cancel</Link>
                    </div>
                </form>
            </div>
            {
                open && 
                <ConfirmPopup 
                    setOpen={setOpen} 
                    application={application} 
                    submitData={submitData}
                />
            }

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    );
}

export default GestationInformation;
  