/* eslint-disable */
import { useEffect, useMemo, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, Link } from 'react-router-dom';

import ReactDaumPost from 'react-daumpost-hook';

import api from '../../libs/api';

import { useRecoilState } from "recoil";
import { farmCode } from '../../store/store';
import { Alert, AlertBack } from './../../components/Alert';


function FarmDetailMng() {    
    const [farmDetail, setFarmDetail] = useState({});

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertBackOpen, setAlertBackOpen] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;
    
    const params = {
        farmCode: farmDetail?.farmCode,
        farmName: farmDetail?.farmName,
        farmAddr: farmDetail?.farmAddr,
        farmAddr1: farmDetail?.farmAddr1,
        farmAddr2: farmDetail?.farmAddr2,
        memberId: farmDetail?.memberId,
        mode: farmDetail && Object.keys(farmDetail).length > 0 ? "UPDATE" : "INSERT"
    }
    const { register, reset, handleSubmit, setValue, formState: { errors } } = useForm({
        defaultValues: useMemo(() => {
            return params;
        }, [farmDetail])
    });
    const urlParams = useParams();
    
    useEffect(() => {
        if (urlParams.code !== "reg") {
            getFarmDetail(); // 수정시 농장 정보
        }
    }, [current]);

    useEffect(() => {
        reset(params);
    }, [farmDetail]);

    // 수정시 농장 정보 불러오기
    async function getFarmDetail() {
        const response = await api.get(`/farm/info?farmCode=${current}`);

        // 성공
        if (response.data.code === "200") {
            setFarmDetail(response.data.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    // 수정/등록 서버 전송
    async function saveFarmDetail(params) {
        console.log(params);
        const response = await api.post("/farm/farmSave", params);

        // 성공
        if (response.data.code === "200") {
            console.log(response);
            setAlertMsg(urlParams.code === "reg" ? "등록이 완료되었습니다." : "수정이 완료되었습니다.");
            setAlertBackOpen(true);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    console.log('farmDetail', farmDetail);

    // 다음 우편번호 api
    const ref = useRef(null);
    const postConfig = {
        ref: ref,
        onComplete: (data) => {
            console.log(data);
            setValue("farmAddr1", data.address);
            popupClose();
        }
    }
    const postCode = ReactDaumPost(postConfig);
    const [daumOpen, setDaumOpen] = useState(false);
    function popupOpen() {
        setDaumOpen(true);
        setTimeout(() => {
            postCode();
        }, 0);
    }
    function popupClose() {
        setDaumOpen(false);
    }

    return (
        <div className="container">
            <form 
                name="farm_defail" 
                onSubmit={handleSubmit((data) => saveFarmDetail(data))}
            >
                <h1 className="title">Farm Management</h1>
                <div className="con">
                    <div className="content_header border-b mb-10 d-flex">
                        <span className="icon_wrap not_bg">
                            <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                        </span>
                        <span className="div_title mr-auto">
                            {
                                urlParams.code === "reg" 
                                ? "New farm registration" 
                                : "Change farm information"
                            }
                        </span>
                    </div>
                    <div className="content_body">
                        {/* <div className="">
                            <label htmlFor="farmName">User name</label> <br />
                            <input 
                                type="text" 
                                id="customerName" 
                                name="customerName" 
                                {...register("customerName", { required: true })}
                            />
                            {errors.customerName && <span className="err_msg">This field is required</span>}
                        </div> */}
                        {/* <div className="">
                            <label htmlFor="phone">Phone</label> <br />
                            <input 
                                type="text" 
                                id="phone" 
                                name="phone" 
                                {...register("phone", { required: true })}
                            />
                            {errors.phone && <span className="err_msg">This field is required</span>}
                        </div> */}
                        <div className=" mb-10">
                            <div className="d-flex">
                                <label htmlFor="farmName">Farm name</label>
                                {errors.farmName && <span className="err_msg">This field is required</span>}
                            </div>
                            <div className="input_wrap">
                                <input 
                                    type="text" 
                                    id="farmName" 
                                    name="farmName" 
                                    {...register("farmName", { required: true })}
                                />
                            </div>
                        </div>
                        <div className=" mb-10">
                            <div className="d-flex">
                                <label htmlFor="farmCode">Farm code</label>
                                {errors.farmCode && <span className="err_msg">This field is required</span>}
                            </div>
                            <div className="input_wrap">
                                <input 
                                    type="text" 
                                    id="farmCode" 
                                    name="farmCode" 
                                    {...register("farmCode", { required: true })}
                                    readOnly={urlParams.code !== "reg" && true}
                                />
                            </div>
                        </div>
                        <div className=" mb-10">
                            <div className="d-flex">
                                <label htmlFor="farmAddr1">Address</label>
                                {errors.farmAddr1 && <span className="err_msg">This field is required</span>}
                            </div>
                            <div className="d-flex mb-5">
                                <input 
                                    type="text" 
                                    id="farmAddr1" 
                                    name="farmAddr1" 
                                    className="w-70"
                                    {...register("farmAddr1", { required: true })}
                                />
                                <a className="button sm addr_search w-30" onClick={popupOpen}>Search</a> <br />
                            </div>
                            <div className="input_wrap">
                                <input 
                                    type="text" 
                                    id="farmAddr2" 
                                    name="farmAddr2" 
                                    {...register("farmAddr2")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="btn_wrap content_footer d-flex s-btw">
                        <Link to="/farm_mng" className="button cancel">Cancel</Link>
                        <button type="submit" className="button">
                            {urlParams.code === "reg" ? "Registration" : "Change information"}
                        </button>
                    </div>
                </div>
            </form>
            
            { 
                daumOpen && 
                <>
                    <div className="popup daum">
                        <a className="button close" onClick={popupClose}>
                            <img src="/assets/images/common/icon_close.png" alt="close" />
                        </a>
                        <div ref={ref}></div>
                    </div> 
                    <div className="popup_overlay"></div>
                </>
            }

            {
                alertBackOpen && <AlertBack alertMsg={alertMsg} setAlertOpen={setAlertBackOpen} />
            }
            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    );
}

export default FarmDetailMng;