/* eslint-disable */
import Chart from 'react-apexcharts'
import { useState, useEffect } from 'react';

function LineAreaChart({ data, title }) {
    console.log(data, title);
    const [state, setState] = useState({
        series: [
            {
                name: '',
                type: 'line',
                data: []
            }, 
            {
                name: '',
                type: 'line',
                data: []
            },
            {
                name: '',
                type: 'area',
                data: []
            }, 
        ],
        options: {
            chart: {
                height: 350,
                type: 'line',
            },
            stroke: {
                curve: 'straight',
                width: [2, 2, 0, 0, 1],
                // dashArray: [2, 0, 0, 0, 0],
                colors: ['#6bb6fc', '#526881', '#e7e6e6', '#5b9bd5', '#ffbeb4'],
            },
            fill: {
                type:['solid', 'solid', 'gradient', 'gradient', 'gradient'],
                colors: ['#6bb6fc', '#526881', '#e7e6e6', '#5b9bd5', '#ffbeb4'],
                opacity: [1, 1, 1, 0.7, 1],
                gradient: {
                    shade: "light",
                    type: "vertical",
                    shadeIntensity: 0,
                    opacityFrom: 0.7,
                    opacityTo: 0.2
                }
            },
            labels: [],
            colors: ['#6bb6fc', '#526881', '#e7e6e6', '#5b9bd5', '#ffbeb4'],
            legend: {
                markers: {
                    fillColors: ['#6bb6fc', '#526881', '#e7e6e6', '#5b9bd5', '#ffbeb4'],
                }
            },
            // yaxis: {
            //     min: 30,
            //     max: 100
            // },
        },
    });

    useEffect(() => {
        if (title === "profitability" || title === "profitability_detail") { 
            // 수익성(Profitability)
            const profitabilityScore = data[0]?.apiList.filter((list) => list.name.indexOf("ProfitabilityScore") > -1)[0];  
            const rDate = data[0]?.apiList.filter((list) => list.name.indexOf("Rdate") > -1)[0];   
            const standardScore = data[0]?.apiList.filter((list) => list.name === "StandardScore")[0];

            data.length > 0 &&
            setState({
                series: [
                    {
                        name: profitabilityScore?.title,
                        type: 'line',
                        data: profitabilityScore?.data
                    },
                    {
                        name: rDate?.title,
                        type: 'line',
                        data: rDate?.data
                    },
                    {
                        name: standardScore?.title,
                        type: 'line',
                        data: standardScore?.data
                    },
                ],
                options: {
                    chart: {
                        height: 350,
                        type: 'line',
                    },
                    stroke: {
                        curve: 'straight',
                        width: [2, 2, 2],
                        // dashArray: [2, 0, 0, 0, 0],
                        colors: ['#6bb6fc', '#526881', '#5b9bd5', '#ffbeb4'],
                    },
                    fill: {
                        type:['solid', 'solid', 'solid', 'solid', 'solid'],
                        colors: ['#6bb6fc', '#526881', '#e7e6e6', '#5b9bd5', '#ffbeb4'],
                        opacity: [1, 1, 1, 0.7, 1],
                        gradient: {
                            shade: "light",
                            type: "vertical",
                            shadeIntensity: 0,
                            opacityFrom: 0.7,
                            opacityTo: 0.2
                        }
                    },
                    labels: data[0]?.api?.data,
                    colors: ['#6bb6fc', '#526881', '#5b9bd5', '#ffbeb4'],
                    legend: {
                        markers: {
                            fillColors: ['#6bb6fc', '#526881', '#5b9bd5', '#ffbeb4'],
                        }
                    },
                    yaxis: {
                        min: 30,
                        max: 100
                    },
                    title: {
                        text: title === "profitability_detail" ? "Profitability for Cow" : ""
                    }
                },
            })

        } else{
            const topArea = data[0]?.apiList.filter((list) => list.name.indexOf("TopPer") > -1)[0];
            const normalArea = data[0]?.apiList.filter((list) => list.name.indexOf("Normal") > -1)[0];
            const bottomArea = data[0]?.apiList.filter((list) => list.name.indexOf("BomPer") > -1)[0];
            const currentScore = data[0]?.apiList.filter((list) => list.name === "ProductivityScore" || list.name === "HealthScore" || list.name === "CarbonScore")[0];
            const standardScore = data[0]?.apiList.filter((list) => list.name === "StandardScore")[0];

            data &&
            setState({
                series: [
                    {
                        name: standardScore?.title,
                        type: 'line',
                        data: standardScore?.data
                    },
                    {
                        name: currentScore?.title,
                        type: 'line',
                        data: currentScore?.data
                    },
                    {
                        name: topArea?.title,
                        type: 'area',
                        data: topArea?.data
                    },
                    {
                        name: normalArea?.title,
                        type: 'area',
                        data: normalArea?.data
                    },
                    {
                        name: bottomArea?.title,
                        type: 'area',
                        data: bottomArea?.data
                    },
                ],
                options: {
                    chart: {
                        height: 350,
                        type: 'line',
                    },
                    stroke: {
                        curve: 'straight',
                        width: [2, 2, 0, 0, 1],
                        dashArray: [2, 0, 0, 0, 0],
                        colors: ['#6bb6fc', '#526881', '#e7e6e6', '#5b9bd5', '#ffbeb4'],
                    },
                    fill: {
                        type:['solid', 'solid', 'gradient', 'gradient', 'gradient'],
                        colors: ['#6bb6fc', '#526881', '#e7e6e6', '#5b9bd5', '#ffbeb4'],
                        opacity: [1, 1, 1, 0.7, 1],
                        gradient: {
                            shade: "light",
                            type: "vertical",
                            shadeIntensity: 0,
                            opacityFrom: 0.7,
                            opacityTo: 0.2
                        }
                    },
                    labels: data[0]?.api?.data,
                    colors: ['#6bb6fc', '#526881', '#e7e6e6', '#5b9bd5', '#ffbeb4'],
                    legend: {
                        markers: {
                            fillColors: ['#6bb6fc', '#526881', '#e7e6e6', '#5b9bd5', '#ffbeb4'],
                        }
                    },
                    yaxis: {
                        min: 0,
                        max: 100
                    },
                },
            })
        }

        // else {
        //     const topArea = data[0]?.apiList.filter((list) => list.name.indexOf("TopPer") > -1)[0];
        //     const normalArea = data[0]?.apiList.filter((list) => list.name.indexOf("Normal") > -1)[0];
        //     const bottomArea = data[0]?.apiList.filter((list) => list.name.indexOf("BomPer") > -1)[0];
        //     const currentScore = data[0]?.apiList.filter((list) => list.name === "ProductivityScore" || list.name === "HealthScore" || list.name === "CarbonScore")[0];
        //     const standardScore = data[0]?.apiList.filter((list) => list.name === "StandardScore")[0];
        //
        //     data &&
        //     setState({
        //         series: [
        //             {
        //                 name: standardScore?.title,
        //                 type: 'line',
        //                 data: standardScore?.data
        //             },
        //             {
        //                 name: currentScore?.title,
        //                 type: 'line',
        //                 data: currentScore?.data
        //             },
        //             {
        //                 name: topArea?.title,
        //                 type: 'area',
        //                 data: topArea?.data
        //             },
        //             {
        //                 name: normalArea?.title,
        //                 type: 'area',
        //                 data: normalArea?.data
        //             },
        //             {
        //                 name: bottomArea?.title,
        //                 type: 'area',
        //                 data: bottomArea?.data
        //             },
        //         ],
        //         options: {
        //             chart: {
        //                 height: 350,
        //                 type: 'line',
        //             },
        //             stroke: {
        //                 curve: 'straight',
        //                 width: [2, 2, 0, 0, 1],
        //                 dashArray: [2, 0, 0, 0, 0],
        //                 colors: ['#6bb6fc', '#526881', '#e7e6e6', '#5b9bd5', '#ffbeb4'],
        //             },
        //             fill: {
        //                 type:['solid', 'solid', 'gradient', 'gradient', 'gradient'],
        //                 colors: ['#6bb6fc', '#526881', '#e7e6e6', '#5b9bd5', '#ffbeb4'],
        //                 opacity: [1, 1, 1, 0.7, 1],
        //                 gradient: {
        //                     shade: "light",
        //                     type: "vertical",
        //                     shadeIntensity: 0,
        //                     opacityFrom: 0.7,
        //                     opacityTo: 0.2
        //                 }
        //             },
        //             labels: data[0]?.api?.data,
        //             colors: ['#6bb6fc', '#526881', '#e7e6e6', '#5b9bd5', '#ffbeb4'],
        //             legend: {
        //                 markers: {
        //                     fillColors: ['#6bb6fc', '#526881', '#e7e6e6', '#5b9bd5', '#ffbeb4'],
        //                 }
        //             },
        //             yaxis: {
        //                 min: 30,
        //                 max: 100
        //             },
        //         },
        //     })
        // }
    }, [data]);

    return (
        <div className="chart line">
            <Chart options={state.options} series={state.series} type="line" width="100%"/>
        </div>
    );
}

export default LineAreaChart;