function numberPipe(number, locale) {
    if (number) {
        const options = {
            localeMatcher: 'best fit',
            style: 'decimal',
            currency: 'PHP',
            useGrouping: true,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        };
        if(locale === 'en'){
            return Number(number).toLocaleString('ko-KR');
        }else if (locale === 'ph'){
            number = number/0.0179;
            return Number(number).toLocaleString('ko-KR', options);
        }

        // return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
}

export { numberPipe };