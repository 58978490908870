/* eslint-disable */
import { useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import Dompurify from 'dompurify';
import getMessage from "../../libs/getMessage";

const terms = [{
    id: 1,
    // title: "terms and conditions",
    title: getMessage("Appinfo.terms.title"),
    content: `Milk-T policy<br />
    1 (Purpose) These Terms and Conditions are intended to stipulate the rights, obligations and responsibilities of the Company and its users regarding the use of Milk-T services provided by ThinkForBl (hereinafter referred to as "the Company") via mobile devices.<br /><br />
    
    2 (Definition of Terms) The definitions of terms used in these Terms and Conditions are as follows.<br />
    &nbsp;1. "Company" means a business operator that provides services through mobile devices.<br />
    &nbsp;2. "Member" means a person who enters into a contract of use in accordance with these terms and conditions and uses the services provided by the company.<br />
    &nbsp;3. Mobile devices are devices that can be used by downloading or installing Milk-T services provided by the company, such as mobile phones, smartphones, portable information terminals (PDAs), tablets, etc. <br />
    &nbsp;4. "Account Information" refers to basic information provided by members to the company, such as the name, farm name, farm address, etc., farm details (farm code, dairy information, etc.), and usage fee payment information.<br />
    &nbsp;5. "Application" means any program downloaded or installed through a mobile device to use the services provided by the company.<br />
    &nbsp;6. "Milk-T Service" is one of the services provided by the company and refers to the Milk-T service that a member runs on a mobile device and all accompanying services.<br />
    The definition of the terms used in these Terms and Conditions shall be as prescribed by relevant laws and policies for each service, except as prescribed in paragraph 1 of this Article, and it shall be in accordance with general commercial practice.`,
}];

const storage = [{
    id: 1,
    title: "App storage usage policy",
    content: 
    `1. Each user gets 300MB/cow of storage capacity free of charge each month.<br />
    - Example) If you register and manage 10 cows, we provide 3,000MB of storage capacity per month.<br /><br />
    2. 1 credit per 100MB will be charged after monthly free storage usage.<br />
    - Example) If you used 3,150MB of storage capacity this month, 1 credits will be deducted.`,
}];

// 약관 아코디언
function Term({ term, handleClick, click }) {
    const nodeRef = useRef(null);

    return (
        <li 
            className={`accordionItem faq_item ${click === term.id ? "on" : ""}`}
            onClick={()=> handleClick(term.id)}
        >
            <div className="title faq_q">
                <p className="text">{term.title}</p>
            </div>
            <CSSTransition
                in={click === term.id}
                timeout={300}
                classNames="accordion"
                unmountOnExit
                nodeRef={nodeRef}
            >
                <div className="desc termsConditions_wrap" ref={nodeRef}>
                    <div className="termsConditions" dangerouslySetInnerHTML={{__html: Dompurify.sanitize(term.content)}}></div>
                </div>
            </CSSTransition>
        </li>
    )
}

// 스토리지 아코디언
function Storage({ storage, handleStorageClick, storageClick }) {
    const nodeRef = useRef(null);

    return (
        <li 
            className={`accordionItem faq_item ${storageClick === storage.id ? "on" : ""}`}
            onClick={()=> handleStorageClick(storage.id)}
        >
            <div className="title faq_q">
                <p className="text">{storage.title}</p>
            </div>
            <CSSTransition
                in={storageClick === storage.id}
                timeout={300}
                classNames="accordion"
                unmountOnExit
                nodeRef={nodeRef}
            >
                <div className="desc termsConditions_wrap" ref={nodeRef}>
                    <div className="termsConditions" dangerouslySetInnerHTML={{__html: Dompurify.sanitize(storage.content)}}></div>
                </div>
            </CSSTransition>
        </li>
    )
}

// About the App 
function AppInfo() {
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);

    // term accordion
    const [click, setClick] = useState(null);
    function handleClick(id) {
        if (click === id) {
            setClick(null);
        } else {
            setClick(id);
        }
    }
    
    // storage accordion
    const [storageClick, setStorageClick] = useState(null);
    function handleStorageClick(id) {
        if (storageClick === id) {
            setStorageClick(null);
        } else {
            setStorageClick(id);
        }
    }

    return (
        <div className="container">
            {/* App usage policy */}
            <div className="con">
                <div className="content_header d-flex">
                    <span className="icon_wrap not_bg">
                        <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                    </span>
                    <span className="div_title mr-auto">
                        {getMessage("Appinfo.appusagepolicy")}
                    </span>
                </div>
                <div className="content_body">
                    <div className="d-flex con_tit_wrap">
                        <h4 className="con_tit">{getMessage("Appinfo.content")}</h4>
                        <h4 className="con_tit ml-auto">{getMessage("Appinfo.detail")}</h4>
                    </div>
                    <ul className="board_list">
                        {
                            terms.map((term) => {
                                return (
                                    <Term 
                                        term={term}
                                        key={term.id}
                                        handleClick={handleClick}
                                        click={click}
                                        setClick={setClick}
                                    />
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            
            {/* App storage usage policy */}
            <div className="con">
                <div className="content_header d-flex">
                    <span className="icon_wrap not_bg">
                        <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                    </span>
                    <span className="div_title mr-auto">
                        {getMessage("Appinfo.appstorageusagepolicy")}
                    </span>
                </div>
                <div className="content_body">
                    <div className="d-flex con_tit_wrap">
                        <h4 className="con_tit">{getMessage("Appinfo.content")}</h4>
                        <h4 className="con_tit ml-auto">{getMessage("Appinfo.detail")}</h4>
                    </div>
                    <ul className="board_list">
                        {
                            storage.map((storage) => {
                                return (
                                    <Storage 
                                        storage={storage}
                                        key={storage.id}
                                        handleStorageClick={handleStorageClick}
                                        storageClick={storageClick}
                                        setStorageClick={setStorageClick}
                                    />
                                )
                            })
                        }
                    </ul>
                </div>
            </div>

            {/* App information */}
            <div className="con">
                <div className="content_header d-flex">
                    <span className="icon_wrap not_bg">
                        <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                    </span>
                    <span className="div_title mr-auto">
                        App information
                    </span>
                </div>
                <div className="content_body">
                    <div className="d-flex con_tit_wrap">
                        <h4 className="con_tit">Subject</h4>
                        <h4 className="con_tit ml-auto">Current</h4>
                    </div>
                    <div className="d-flex">
                        <h4 className="tit">Version</h4>
                        v2.0
                    </div>
                    <div className="d-flex">
                        <h4 className="tit">Receive promotional information</h4>
                        <div className="switch">
                            <input type="checkbox" id="checkbox01" />
                            <label htmlFor="checkbox01">
                                <span className="rail"></span>
                                <span className="switch"></span>
                            </label>
                        </div>
                    </div>
                    <div className="d-flex">
                        <h4 className="tit">Optional</h4>
                        s
                    </div>
                </div>
            </div>

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    )
}

export default AppInfo;