/* eslint-disable */
import { useEffect, useState } from 'react';

function TopButton() {
    const [visible, setVisible] = useState(false);

    function scrollToTop(e) {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    // 스크롤 이벤트 
    useEffect(() => {
        function handleScroll() {
            if (window.scrollY > 100) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        }
        window.addEventListener("scroll", handleScroll);
        return () => { // 스크롤 이벤트 remove
            window.removeEventListener("scroll", handleScroll);
        }
    }, []);

    if (window.location.pathname === "/login") return null; // 로그인 페이지 제외
    return (
        <>
            {
                visible &&
                <div className="top_btn">
                    <a onClick={scrollToTop}>
                        <img src="/assets/images/common/top_btn.png" alt="" />
                    </a>
                </div>
            }
        </>
        
    );
}

export default TopButton;