/* eslint-disable */
import { useEffect, useState, useRef, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';

import api from './../../libs/api';
import { Link } from 'react-router-dom';

import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { calculateDate, getDate } from '../../libs/getDate';
import { useHistory } from 'react-router-dom';
import { Alert, AlertBack } from './../../components/Alert';

function ConsultationRequest() {
    const [startDate, setStartDate] = useState(calculateDate(0));
    const [qnaCodeList, setQnacodeList] = useState([]);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertBackOpen, setAlertBackOpen] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const locale = localStorage.getItem("locale");
    const history = useHistory();

    useEffect(() => {
        getQnaCodeList();
    }, []);

    // qna 상담 신청 구분 코드 ConsultingType
    async function getQnaCodeList() {
        const response = await api.get('/common/codeList?comKind=ConsultingType');

        // 성공
        if (response.data.code === "200") {
            setQnacodeList(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    // qna 카테고리
    let qnaCodeOptions = [];
    for (let list of qnaCodeList) {
        qnaCodeOptions.push({
            label: list.value,
            value: list.key,
        })
    }

    // qna 등록
    async function qnaRequest(data) {
        console.log('data', data);
        // const response = await api.post('/board/telConsultingSave', data);        
        // console.log(response);

        // // 성공
        // if (response.data.code === "200") {
        //     setAlertMsg("상담 신청이 완료되었습니다.");
        //     setAlertBackOpen(true);
        // } else { // 실패
        //  // setAlertMsg(response.data.msg);
        //  // setAlertOpen(true);
            // console.log('error msg: ', response.data.msg);
        // }
    }

    const hopeTimeOptions = [
        { label: "09:00", value: "am09" },
        { label: "10:00", value: "am10" },
        { label: "11:00", value: "am11" },
        { label: "12:00", value: "pm12" },
        { label: "13:00", value: "pm01" },
        { label: "14:00", value: "pm02" },
        { label: "15:00", value: "pm03" },
        { label: "16:00", value: "pm04" },
        { label: "17:00", value: "pm05" },
        { label: "18:00", value: "pm06" },
    ]
    
    const params = {
        gubun: "",
        title: "",
        contents: "",
        memberId: "",
        hopeDate: getDate(startDate),
        hopeTime: "",
    }

    const { register, handleSubmit, control, formState: { errors }, reset } = useForm({
        defaultValues: useMemo(() => {
            return params;
        }, [])
    });
    useEffect(() => {
        reset(params);
    }, []);

    const filterStyles = {
        control: base => ({
            ...base,
            height: 50,
            minHeight: 50
        })
    }

    return (
        <div className="container">
            <div className="con">
                <div className="content_header d-flex">
                    <span className="icon_wrap not_bg">
                        <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                    </span>
                    <span className="div_title mr-auto">
                        A consultation request
                        <span className="exp">If you fill out the inquiry details and desired time and apply, we will contact you.</span>
                    </span>
                </div>
                <div className="content_body">
                    <div>
                        <form 
                            name="qna_reg" 
                            onSubmit={handleSubmit((data) => qnaRequest(data))}
                        >
                            <div className="my-10">
                                <div className="d-flex">
                                    <label htmlFor="partner">Select your Partner / Head Office</label>
                                    {errors.gubun && <span className="err_msg">This field is required</span>}
                                </div>
                                <Controller
                                    name="gubun"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field:{ fieldState, onChange } }) => (
                                        <Select
                                            className="select_container toolbarSelect"
                                            options={qnaCodeOptions} // qna 카테고리
                                            onChange={(val) => { 
                                                onChange(val.value);
                                            }}
                                            styles={filterStyles}
                                            classNamePrefix="select"
                                        />
                                    )}
                                />
                            </div>
                            <div className="mb-10">
                                <div className="d-flex">
                                    <label htmlFor="title">Title</label> 
                                    {errors.title && <span className="err_msg">This field is required</span>}
                                </div>
                                <div className="input_wrap">
                                    <input 
                                        type="text" 
                                        id="title" 
                                        name="title" 
                                        placeholder="Enter a title"
                                        {...register("title", { required: true })}
                                    />
                                </div>
                            </div>
                            <div className="mb-10">
                                <div className="d-flex">
                                    <label htmlFor="content">Content</label>
                                    {errors.content && <span className="err_msg">This field is required</span>}
                                </div>
                                <div className="textarea_wrap">
                                    <textarea 
                                        id="content"
                                        name="content"
                                        {...register("content", { required: true })}
                                    />
                                </div>
                            </div>
                            <div className="mb-10">
                                <div className="d-flex">
                                    <label htmlFor="hopeDate">Select date</label>
                                    {(errors.hopeDate || errors.hopeTime) && <span className="err_msg">This field is required</span>}
                                </div>
                                <div className="d-flex">
                                    <div className="datepicker_wrap input_wrap date from_to solo yyyy mr-10">
                                        <div className="">
                                            <Controller
                                                name="hopeDate"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field:{ fieldState, onChange } }) => (
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={(date) => {
                                                            setStartDate(date);
                                                            onChange(getDate(date));
                                                        }}
                                                        // locale={ko}
                                                        dateFormat={locale === 'en' ? 'yyyy.MM.dd' : 'dd.MM.yyyy' }
                                                        minDate={new Date()}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <span className="plus">
                                            <img src="/assets/images/common/icon_calendar2.png" className="calendar icon" alt="" />
                                        </span>
                                    </div>
                                    <Controller
                                        name="hopeTime"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field:{ fieldState, onChange } }) => (
                                            <Select
                                                placeholder="Select time"
                                                className="select_container toolbarSelect"
                                                options={hopeTimeOptions}
                                                // defaultValue={hopeTimeOptions[0]}
                                                onChange={(val) => { 
                                                    onChange(val.value);
                                                }}
                                                styles={filterStyles}
                                                classNamePrefix="select"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="content_footer btn_wrap d-flex s-btw">
                                <Link to="/service_center" className="button cancel">Cancel</Link>
                                <button type="submit" className="button">
                                    Application
                                </button>
                            </div>    
                        </form> 
                    </div>
                </div>
            </div>

            {
                alertBackOpen && <AlertBack alertMsg={alertMsg} setAlertOpen={setAlertBackOpen} />
            }
            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    );
}

export default ConsultationRequest;