/* eslint-disable */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ko } from 'date-fns/esm/locale';

import 'react-datepicker/dist/react-datepicker.css'
import DateTermBtn from './../../../components/DateTermBtn';
import { getDate, calculateDate } from '../../../libs/getDate';
import api from './../../../libs/api';
import LineChart from './../../../components/charts/LineChart';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import LineAreaChart from './../../../components/charts/LineAreaChart';

import { useRecoilState } from "recoil";
import { farmCode } from './../../../store/store';
import { Alert } from '../../../components/Alert';
import getMessage from "../../../libs/getMessage";
import GetMessage from "../../../libs/getMessage";
import CustomDatePicker from "../../../libs/CustomDatePicker";

function FarmScore({ current, setAlertMsg, setAlertOpen }) {
    const [startDate, setStartDate] = useState(calculateDate(-30));
    const [endDate, setEndDate] = useState(calculateDate(-1));
    const [dateTerm, setDateTerm] = useState("day");
    const [farmScoreData, setFarmScoreData] = useState([]);
    const locale = localStorage.getItem("locale");

    useEffect(() => {
        getFarmScore();
    }, [startDate, endDate, current]);

    async function getFarmScore() {
        const params = {
            farmCode : current,
            startDate : getDate(startDate),
            endDate : getDate(endDate)
        }

        const response = await api.post('/health/healthScore', params);
        
        // 성공
        if (response.data.code === "200") {
            setFarmScoreData(response.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    } 

    console.log('farmScoreData', farmScoreData);

    return (
        <div className="farm_score_div content_wrap">
            <div className="score_wrap d-flex">
                <img src="/assets/images/common/icon_graph.png" alt="" />
                <div className="title">
                    <span className="sub">{getMessage("Common.farm")}</span>
                    <h2 className="score">{getMessage("Healthcondition.healthscore")}{farmScoreData.farmScore} &#40; Top {farmScoreData.topPer}% &#41;</h2>
                </div>
            </div>

            <div className="toolbar">
                <Link to="/health_condition/cow_list" className="round_btn">{getMessage("Common.viewcow")}</Link>
            </div>

            <div className="farm_score main_content">
                <div className="content_header d-flex">
                    <span className="icon_wrap">
                        <img src="/assets/images/common/icon_home.png" alt="" />
                    </span>
                    <span className="div_title">{getMessage("Common.farmscore")}</span>
                    <div className="ml-auto">
                        {/* <DateTermBtn dateTerm={dateTerm} setDateTerm={setDateTerm} /> */}
                    </div>
                    <div className="datepicker_wrap from_to">
                        <img src="/assets/images/common/icon_calendar.png" className="calendar" alt="" />
                        <CustomDatePicker
                            selectedDate={startDate}
                            endDate={endDate}
                            onStartDateChange={(date) => setStartDate(date)}
                            onEndDateChange={(date) => setEndDate(date)}
                            locale={locale}
                        />
                        <img src="/assets/images/common/icon_calendar_arrow.png" className="arrow" alt="" />
                    </div>
                </div>
                <div className="graph">
                    {
                        farmScoreData.datas &&
                        // <LineChart data={farmScoreData.datas} />
                        <LineAreaChart data={farmScoreData.datas} />
                    }
                </div>
            </div>
        </div>
    )
}

function FarmStatus({ current, setAlertMsg, setAlertOpen }) {
    const [startDate, setStartDate] = useState(calculateDate(-30));
    const [endDate, setEndDate] = useState(calculateDate(-1));
    const [dateTerm, setDateTerm] = useState("day");
    const [farmStatusData, setFarmStatusData] = useState([]);
    const [graphArray, setGraphArray] = useState(["Milk"]);
    const locale = localStorage.getItem("locale");

    useEffect(() => {
        getFarmStatus();
    }, [startDate, endDate, graphArray, current]);

    async function getFarmStatus() {
        const params = {
            farmCode : current,
            startDate : getDate(startDate),
            endDate : getDate(endDate),
            arrGraphType: graphArray,

        }
        const response = await api.post('/health/healthStatus', params);
        
        // 성공
        if (response.data.code === "200") {
            setFarmStatusData(response.data.datas);            
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    console.log('farmStatusData', farmStatusData);

    function checkboxChange(e) {
        if (e.target.checked) {
            if (graphArray.indexOf(e.target.name) < 0) {
                setGraphArray([...graphArray, e.target.name]);
            }
        } else {
            if (graphArray.indexOf(e.target.name) > -1 && e.target.name !== "Milk") {
                setGraphArray(graphArray.filter((arr) => arr !== e.target.name));
            }
        }
    }

    console.log('farmStatusData', farmStatusData);
    // console.log('farmStatusData', farmStatusData[0]?.apiList);

    return (
        <div className="main_content">
            <div className="content_header d-flex">
                <span className="icon_wrap">
                    <img src="/assets/images/common/icon_home.png" alt="" />
                </span>
                <span className="div_title">{getMessage("Common.farmstatus")}</span>
                <div className="ml-auto">
                    {/* <DateTermBtn dateTerm={dateTerm} setDateTerm={setDateTerm} /> */}
                </div>
                <div className="datepicker_wrap from_to">
                    <img src="/assets/images/common/icon_calendar.png" className="calendar" alt="" />
                    <CustomDatePicker
                        selectedDate={startDate}
                        endDate={endDate}
                        onStartDateChange={(date) => setStartDate(date)}
                        onEndDateChange={(date) => setEndDate(date)}
                        locale={locale}
                    />
                    <img src="/assets/images/common/icon_calendar_arrow.png" className="arrow" alt="" />
                </div>
            </div>
            <div className="graph">
                <div className="checkbox_wrap d-flex">
                    <div className="checkbox">
                        <input type="checkbox" id="active" name="ActivityH" onChange={checkboxChange} />
                        <label htmlFor="active">{getMessage("Healthcondition.active")}</label>
                    </div>

                    <div className="checkbox">
                        <input type="checkbox" id="sleep" name="SleepH" onChange={checkboxChange} />
                        <label htmlFor="sleep">{getMessage("Healthcondition.sleep")}</label>
                    </div>

                    <div className="checkbox">
                        <input type="checkbox" id="rumination" name="RuminantH" onChange={checkboxChange} />
                        <label htmlFor="rumination">{getMessage("Healthcondition.rumination")}</label>
                    </div>

                    <div className="checkbox">
                        <input type="checkbox" id="milk_production" name="Milk" onChange={checkboxChange} checked readOnly />
                        <label htmlFor="milk_production">{getMessage("Healthcondition.milkproduction")}</label>
                    </div>

                </div>
                <div className="checkbox_wrap d-flex">
                    <div className="checkbox">
                        <input type="checkbox" id="thi" name="THI" onChange={checkboxChange} />
                        <label htmlFor="thi">THI</label>
                    </div>

                    <div className="checkbox">
                        <input type="checkbox" id="humidity" name="Humidity" onChange={checkboxChange} />
                        <label htmlFor="humidity">{getMessage("Healthcondition.humidity")}</label>
                    </div>

                    <div className="checkbox">
                        <input type="checkbox" id="temperature" name="Temperature" onChange={checkboxChange} />
                        <label htmlFor="temperature">{getMessage("Healthcondition.temperature")}</label>
                    </div>
                </div>
                {
                    farmStatusData &&
                    <LineChart data={farmStatusData} title="farm_status" />
                }
            </div>
        </div>
    )
}

function FarmDetails({ current, setAlertMsg, setAlertOpen }) {
    const [startDate, setStartDate] = useState(calculateDate(-30));
    const [endDate, setEndDate] = useState(calculateDate(-1));
    const [dateTerm, setDateTerm] = useState("day");
    const [farmDetail, setFarmDetail] = useState([]);
    const locale = localStorage.getItem("locale");

    useEffect(() => {
        getFarmStatus();
    }, [startDate, endDate, current]);

    async function getFarmStatus() {
        const params = {
            farmCode : current,
            startDate : getDate(startDate),
            endDate : getDate(endDate)
        }
        const response = await api.post('/health/farmDetail', params);
        
        // 성공
        if (response.data.code === "200") {
            setFarmDetail(response.data.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    // console.log('farmDetail', farmDetail);
    
    return (
        <div className="main_content">
            <div className="content_header d-flex">
                <span className="icon_wrap">
                    <img src="/assets/images/common/icon_farm_detail.png" alt="" />
                </span>
                <span className="div_title">{getMessage("Healthcondition.farmdetails")}</span>
                <div className="ml-auto">
                    {/* <DateTermBtn dateTerm={dateTerm} setDateTerm={setDateTerm} /> */}
                </div>
                <div className="datepicker_wrap from_to">
                    <img src="/assets/images/common/icon_calendar.png" className="calendar" alt="" />
                    <CustomDatePicker
                        selectedDate={startDate}
                        endDate={endDate}
                        onStartDateChange={(date) => setStartDate(date)}
                        onEndDateChange={(date) => setEndDate(date)}
                        locale={locale}
                    />
                    <img src="/assets/images/common/icon_calendar_arrow.png" className="arrow" alt="" />
                </div>
            </div>
            
            
            <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
            >
                <SwiperSlide>
                    <LineChart title="farm_details" data={farmDetail.activityH} />
                </SwiperSlide>

                <SwiperSlide>
                    <LineChart title="farm_details" data={farmDetail.humidity} />
                </SwiperSlide>

                <SwiperSlide>
                    <LineChart title="farm_details" data={farmDetail.milk} />
                </SwiperSlide>

                <SwiperSlide>
                    <LineChart title="farm_details" data={farmDetail.ruminantH} />
                </SwiperSlide>

                <SwiperSlide>
                    <LineChart title="farm_details" data={farmDetail.sleepH} />
                </SwiperSlide>

                <SwiperSlide>
                    <LineChart title="farm_details" data={farmDetail.temperature} />
                </SwiperSlide>

                <SwiperSlide>
                    <LineChart title="farm_details" data={farmDetail.thi} />
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

function AbnormalDairyCow({ current, setAlertMsg, setAlertOpen }) {
    const [abnormalDairyCow, setAbnormalDairyCow] = useState([]);

    useEffect(() => {
        getAbnormalDairyCow();
    }, [current]);

    async function getAbnormalDairyCow() {
        const params = {
            farmCode : current,
            inputDate : getDate(calculateDate(-1))
        }
        const response = await api.post('/health/healthAbnormalCows', params);
        
        // 성공
        if (response.data.code === "200") {
            setAbnormalDairyCow(response.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    console.log('abnormalDairyCow', abnormalDairyCow);
    
    return (
        <div className="main_content abnormal_dairy_cow ">
            <div className="content_header d-flex">
                <span className="icon_wrap">
                    <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                </span>
                <span className="div_title">{getMessage("Healthcondition.abnormaldairycow")}</span>
            </div>
            <div className="score_wrap d-flex">
                <img src="/assets/images/common/cow_zoom.png" alt="" />
                <div className="title">
                    <span className="sub">{getMessage("Main.total")}{abnormalDairyCow.total} {getMessage("Main.cows")}</span>
                    <h2 className="score">{getMessage("Healthcondition.pleasecheckthestatusofthenext")}{abnormalDairyCow.count} {getMessage("Main.cows")}</h2>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th className="bg">{getMessage("Main.cowsno")}</th>
                        <th className="bg lh1">{getMessage("Healthcondition.health")} <br /><span>&#40;{getMessage("Healthcondition.score")}&#41;</span></th>
                        <th className="bg lh1">{getMessage("Healthcondition.active")} <br /><span>&#40;{getMessage("Healthcondition.hour")}&#41;</span></th>
                        <th className="bg lh1">{getMessage("Healthcondition.sleep")} <br /><span>&#40;{getMessage("Healthcondition.hour")}&#41;</span></th>
                        <th className="bg lh1">{GetMessage("Healthcondition.rumination")} <br /><span>&#40;{getMessage("Healthcondition.hour")}&#41;</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="small">
                        <td colSpan="5">&#40;{abnormalDairyCow.count}/{abnormalDairyCow.total}&#41; {getMessage("Main.cows")}</td>
                    </tr>
                    {
                        abnormalDairyCow.datas &&
                        abnormalDairyCow.datas.map((cow) => {
                            return (
                                <tr key={cow.cowNumber}>
                                    <td>
                                        <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className="">{cow.cowNumber}&#40;{cow.cowNumber2}&#41;</Link>
                                    </td>
                                    <td>
                                        <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className={cow.stateHealth}>{cow.healthScore}</Link>
                                    </td>
                                    <td>
                                        <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className={cow.stateActivity}>{cow.activeH}</Link>
                                    </td>
                                    <td>
                                        <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className={cow.stateSleep}>{cow.sleepH}</Link>
                                    </td>
                                    <td>
                                        <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className={cow.stateRuminant}>{cow.reminationH}</Link>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

function HealthyCow() {
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;

    return (
        <div className="content health_condition">

            <FarmScore current={current} setAlertMsg={setAlertMsg} setAlertOpen={setAlertOpen} />

            <FarmStatus current={current} setAlertMsg={setAlertMsg} setAlertOpen={setAlertOpen} />

            <FarmDetails current={current} setAlertMsg={setAlertMsg} setAlertOpen={setAlertOpen} />

            <AbnormalDairyCow current={current} setAlertMsg={setAlertMsg} setAlertOpen={setAlertOpen} />

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    )
}

export default HealthyCow;