/* eslint-disable */
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getDateTime } from '../libs/getDate';
import axios from 'axios';

function OverseasLoginBlocker() {    
    const [loginData, setLoginData] = useState({});
    const location = useLocation();
    
    useEffect(() => {
        // history push로 props 받은 경우
        if (!location.state) {
            getIP();
        } else { // history push로 props 못받은 경우
            setLoginData(location.state);
        }
    }, [location]);

    console.log('loginData', loginData);

    const isMobile = /Mobi/i.test(window.navigator.userAgent); // 접속기기 모바일인지 확인

    // 접속 IP 확인
    async function getIP() {
        const response = await axios.get('https://geolocation-db.com/json/');

        if (response.status === 200) {
            setLoginData({
                date: getDateTime(new Date()),
                connectionIp: response.data.IPv4,
                country: response.data.country_name + " (" + response.data.city + ")",
                connectionInfo: isMobile ? "Mobile" : "PC"
            });
        } else {
            console.log('error msg: ', response.data.msg);
        }
    }

    // 메인페이지로 이동
    function goMain() {
        window.location.href = "/main";
    }

    return (
        <form name="login" className="con p-10 pb-0 overseas">
            <div className="login_form_header t-center">
                <img src="/assets/images/common/thinkforbl_logo.png" alt="logo" />
            </div>

            <div className="content_header">
                <span className="icon_wrap not_bg">
                    <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                </span>
                <span className="div_title mr-auto">
                    The &#34;Overseas Login Blocker&#34; feature has been enabled.
                    <span className="exp">Your ID has been attempted to log in from a different overseas region.</span>
                </span>
                <h1 className="title mt-10 ">Your ID has been attempted to log in from a different overseas region.</h1>
            </div>
            
            <div className="content_body">
                <div className="con">
                    <div className="content_header d-flex">
                        <span className="icon_wrap not_bg">
                            <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                        </span>
                        <span className="div_title mr-auto">Login information</span>
                    </div>
                    <div className="content_body">
                        <div className="d-flex dashed_b">
                            <h4 className="tit">Date and time</h4>
                            <p className="">{loginData?.date}</p>
                        </div>
                        <div className="d-flex dashed_b">
                            <h4 className="tit">Connection IP</h4>
                            <p className="">{loginData?.connectionIp}</p>
                        </div>
                        <div className="d-flex dashed_b">
                            <h4 className="tit">Country of Access</h4>
                            <p className="">{loginData?.country}</p>
                        </div>
                        <div className="d-flex">
                            <h4 className="tit">Connection Information</h4>
                            <p className="">{loginData?.connectionInfo}</p>
                        </div>
                    </div>
                    <div className="content_footer">
                        <ul className="t-left">
                            <li>If your activity is correct, you can ignore this window.</li>
                            <li className="purple">If not an activity</li>
                            <li>1. Please check your email and proceed with &#34;Authentication&#34;.</li>
                            <li>2. If &#34;certification&#34; is difficult, please call <a href={`tel:02-0000-0000`} className="purple underline">&#34;02-0000-0000&#34;</a>.</li>
                            {/* TODO 전화번호 변경필요할듯(api로 받아올지?) */}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="content_footer">
                <a className="button mx-auto mt-10" onClick={goMain}>Okay</a>
            </div>
        </form>
    );
}

export default OverseasLoginBlocker;