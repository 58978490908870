/* eslint-disable */
import { useState, useEffect } from "react";
import Chart from 'react-apexcharts'

// 차트
function GroupedBarChart({ data, legend, categories }) {
    // console.log(data);
    
    const [state, setState] = useState({
        options: {
            chart: {
                type: 'bar',
                height: 100
            },
            legend: {
                show: legend,
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                }
            },
            xaxis: {
                categories: [],
            },
            yaxis: {
                labels: {
                    minWidth: 120
                },
            },
            colors: [],
        },
        series: [
            {
                data: []
            }
        ],
    });
    
    useEffect(() => {

        data?.data &&
        setState({
            options: {
                chart: {
                    type: 'bar',
                },
                legend: {
                    show: legend
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                xaxis: {
                    categories: [categories],
                },
                yaxis: {
                    labels: {
                        minWidth: 120
                    },
                },
                colors: (data?.status === "D" && "#ff6964") || (data?.status === "G" && "#ebf1ff") || (data?.status === "E" && "#e7e6e6"),
            },
            series: [
                {
                    data: [data?.data[0]],
                    name: "current",
                },
            ],
        })
    }, [data]);

    // danger #fff0ef
    // good #ebf1ff
    // excellent #f5f8ff

    return (
        <div className="chart groupbar">
            <Chart options={state.options} series={state.series} type="bar" width="100%" height="110px" />
            {/* <Chart options={state.options} series={state.series} type="bar" width="100%" height={last === true ? "140px" : "120px"} /> */}
        </div>
    );
}

export default GroupedBarChart;