import { components } from 'react-select';

function CustomOption({ children, ...props }) {
    return (
        <components.Option {...props}>
            {children} {props.data.state === "Y" ? "(use)" : ""}
        </components.Option>
    )
}

export default CustomOption;