/* eslint-disable */
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import Select from 'react-select';
import CustomOptionSensor from './../../components/CustomOptionSensor';
import api from '../../libs/api';

import { useRecoilState } from "recoil";
import { farmCode } from '../../store/store';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { getDate } from '../../libs/getDate';
import { useHistory } from 'react-router-dom';
import { Alert } from './../../components/Alert';

function CowDetailMng() {
    const urlParams = useParams();
    
    const [cowDetail, setCowDetail] = useState({});
    const [sensorList, setSensorList] = useState([]);
    const [birthDate, setBirthDate] = useState();
    const [expectedCalvingDate, setExpectedCalvingDate] = useState();

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const locale = localStorage.getItem("locale");
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;
    
    const params = {
        cowBirth: birthDate,
        cowBreed: cowDetail?.cowBreed,
        cowFarterGrade: cowDetail?.cowFarterGrade,
        cowFarterNumber: cowDetail?.cowFarterNumber,
        cowFarterSperm: cowDetail?.cowFarterSperm,
        cowHouseholdsNumber: cowDetail?.cowHouseholdsNumber ? cowDetail?.cowHouseholdsNumber : "",
        cowIdentifyNumber: cowDetail?.cowIdentifyNumber,
        cowMotherGrade: cowDetail?.cowMotherGrade,
        cowMotherNumber: cowDetail?.cowMotherNumber,
        cowNumber: cowDetail?.cowNumber,
        cowRegistNumber: cowDetail?.cowRegistNumber,
        currentWeight: cowDetail?.currentWeight,
        sensorNumber: cowDetail?.sensorNumber ? cowDetail?.sensorNumber : "",
        farmCode: cowDetail?.farmCode ? cowDetail?.farmCode : current,
        memberId: cowDetail?.memberId ? cowDetail?.memberId : "user@naver.com",
        partnerId: cowDetail?.partnerId ? cowDetail?.partnerId : "MasterPartner@naver.com",
        currentExpectedCalving: expectedCalvingDate,
    }
    const { register, reset, handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: useMemo(() => {
            return params;
        }, [cowDetail])
    });
    
    useEffect(() => {
        if (urlParams.code !== "reg") {            
            getCowDetail();
        }
    }, []);

    // 젖소 상세 조회
    async function getCowDetail() {
        const response = await api.get(`/farm/myCowDetail?cowNumber=${urlParams.code}`);
        
        // 성공
        if (response.data.code === "200") {
            setCowDetail(response.data.data);
            setBirthDate(new Date(response.data.data.cowBirth));
            setExpectedCalvingDate(new Date(response.data.data.currentExpectedCalving));
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    console.log('cowDetail', cowDetail);

    useEffect(() => {
        setTimeout(() => {
            reset(params);
        }, 20);
    }, [cowDetail]);

    // 수정/등록 서버 전송
    async function saveCowDetail(params) {
        console.log('params', params.cowBirth);
        if (params.cowBirth.toString().indexOf("한국 표준시") > -1) {
            params.cowBirth = getDate(params.cowBirth);
        }
        console.log('params', params);

        let url = "";

        if (urlParams.code === "reg") {
            // 등록
            url = "/farm/cowSensorInsertSave";
        } else {
            // 수정
            url = "/farm/cowSensorUpdateSave";
        }

        const response = await api.post(url, params);

        // 성공
        if (response.data.code === "200") {
            setAlertMsg(urlParams.code === "reg" ? "등록이 완료되었습니다." : "수정이 완료되었습니다.");
            setAlertOpen(true);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    useEffect(() => {
        getSensorList();
    }, []);

    // 센서 리스트 조회
    async function getSensorList() {
        const response = await api.get(`/farm/mySensorList?farmCode=${current}`);
        
        // 성공
        if (response.data.code === "200") {
            setSensorList(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    // console.log('sensorList', sensorList);

    let sensorOptions = [];
    for (let list of sensorList) {
        sensorOptions.push({
            label: list.sensorNumber,
            value: list.sensorNumber,
            state: list.state
        })
    }
    // console.log('sensorOptions', sensorOptions);

    const filterStyles = {
        control: base => ({
            ...base,
            height: 50,
            minHeight: 50
        })
    }

    return (
        <div className="container">
            <form 
                name="cow_detail" 
                onSubmit={handleSubmit((data) => saveCowDetail(data))}
            >
                <h1 className="title">Cow Management</h1>
                <div className="con">
                    <div className="content_header d-flex">
                        <span className="icon_wrap not_bg">
                            <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                        </span>
                        <span className="div_title mr-auto">
                            {
                                urlParams.code === "reg" 
                                ? "New cow registration" 
                                : "Change cow information"
                            }
                        </span>
                    </div>
                    <div className="content_body">
                        <div className="sub_con mb-0">
                            <div className="d-flex con_tit_wrap">
                                <h4 className="con_tit">Cow Information</h4>
                            </div>
                            <div className="d-flex">
                                <label className="tit" htmlFor="cowNumber">ID</label>
                                {errors.cowNumber && <span className="err_msg mr-5">This field is required</span>}
                                <div className="d-flex input_wrap">
                                    <input 
                                        type="text" 
                                        id="cowNumber" 
                                        name="cowNumber" 
                                        {...register("cowNumber", { required: true })}
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <label className="tit" htmlFor="cowIdentifyNumber">Identify number</label>
                                {errors.cowIdentifyNumber && <span className="err_msg mr-5">This field is required</span>}
                                <div className="d-flex input_wrap">
                                    <input 
                                        type="text" 
                                        id="cowIdentifyNumber" 
                                        name="cowIdentifyNumber" 
                                        {...register("cowIdentifyNumber", { required: true })}
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <label className="tit" htmlFor="cowRegistNumber">Regist number</label>
                                {errors.cowRegistNumber && <span className="err_msg mr-5">This field is required</span>}
                                <div className="d-flex input_wrap">
                                    <input 
                                        type="text" 
                                        id="cowRegistNumber" 
                                        name="cowRegistNumber" 
                                        {...register("cowRegistNumber", { required: true })}
                                    />
                                </div>
                            </div>
                            {
                                urlParams.code !== "reg" &&
                                <div className="d-flex">
                                    <label className="tit" htmlFor="sensorNumber">Sensor</label>
                                    <div className="d-flex input_wrap search">
                                        {
                                            (cowDetail?.sensorNumber !== "" && cowDetail?.sensorNumber !== null)
                                            ? <>
                                                <input 
                                                    type="text" 
                                                    id="sensorNumber" 
                                                    name="sensorNumber" 
                                                    readOnly
                                                    {...register("sensorNumber")}
                                                />
                                                <Link className="zoom" to={{
                                                    pathname: "/cow_mng/sensor_mapping",
                                                    state: cowDetail
                                                }}>
                                                    <img className="icon" src="/assets/images/common/icon_zoom.png" alt="search" />
                                                </Link>
                                            </>
                                            : <Link className="sensor_R ml-auto" to={{
                                                pathname: "/cow_mng/sensor_mapping",
                                                state: cowDetail
                                            }}>New Registration</Link>
                                        }
                                    </div>
                                </div>
                            }
                            
                            <div className="d-flex">
                                <label className="tit" htmlFor="cowBreed">Breed</label>
                                <div className="d-flex input_wrap">
                                    <input 
                                        type="text" 
                                        id="cowBreed" 
                                        name="cowBreed" 
                                        {...register("cowBreed")}
                                    />
                                    {/* <Controller
                                        name="cowBreed"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field:{ fieldState, onChange } }) => (
                                            <Select
                                                className="select_container toolbarSelect"
                                                // options={sensorOptions}
                                                defaultValue={params.cowBreed}
                                                onChange={(val) => { 
                                                    useYChange(val); 
                                                    onChange(val.value);
                                                }}
                                                styles={filterStyles}
                                                classNamePrefix="select"
                                                components={{ Option: CustomOptionSensor }}
                                            />
                                        )}
                                    /> */}
                                </div>
                            </div>
                            <div className="d-flex">
                                <label className="tit" htmlFor="cowBirth">Date of Birth</label>
                                {errors.cowBirth && <span className="err_msg mr-5">This field is required</span>}
                                <div 
                                    className={`datepicker_wrap input_wrap date from_to solo yyyy ${urlParams.code !== "reg" ? "readonly" : ""}`}
                                >
                                    <div>
                                        <Controller
                                            name="cowBirth"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field:{ fieldState, onChange } }) => (
                                                <DatePicker
                                                    selected={birthDate}
                                                    placeholderText={locale === 'en' ? 'yyyy.MM.dd' : 'dd.MM.yyyy' }
                                                    onChange={(date) => {
                                                        setBirthDate(date);
                                                        onChange(getDate(date));
                                                    }}
                                                    // locale={ko}
                                                    dateFormat={locale === 'en' ? 'yy.MM.dd' : 'dd.MM.yy' }
                                                    readOnly={urlParams.code !== "reg" ? true : false}
                                                />
                                            )}
                                        />
                                    </div>
                                    <span className="plus">
                                        <img src="/assets/images/common/icon_calendar2.png" className="icon" alt="" />
                                    </span>
                                    {errors.selectDate && <span className="err_msg mr-5">This field is required</span>}
                                </div>
                            </div>
                            <div className="d-flex">
                                <label className="tit" htmlFor="currentWeight">Body Weight</label>
                                {errors.currentWeight && <span className="err_msg mr-5">This field is required</span>}
                                <div className="d-flex input_wrap">
                                    <input 
                                        type="text" 
                                        id="currentWeight" 
                                        name="currentWeight" 
                                        {...register("currentWeight", { required: true })}
                                        readOnly={urlParams.code !== "reg" ? true : false}
                                    />
                                </div>
                            </div>
                        </div>

                        {
                            urlParams.code !== "reg" &&
                            <div className="sub_con mb-0">
                                <div className="d-flex con_tit_wrap">
                                    <h4 className="con_tit">Reproduction Information</h4>
                                    <Link to={`/cow_mng/${cowDetail?.cowNumber}/gestation_information`} className="button ml-auto">
                                        <img className="icon" src="/assets/images/common/icon_edit.png" alt="edit" />
                                    </Link>
                                </div>
                                <div className="d-flex sub_tit">
                                    <span className="">Gestation Date</span>
                                </div>
                                <div className="d-flex">
                                    <span className="tit">Number of Pregnancy</span>
                                    <p>{cowDetail?.currentPregnancy}</p>
                                </div>
                                <div className="d-flex">
                                    <span className="tit">Parity</span>
                                    <p>{cowDetail?.currentParity}</p>
                                </div>
                                <div className="d-flex">
                                    <span className="tit">Expected Calving Date</span>
                                    <div className="datepicker_wrap input_wrap date from_to solo yyyy readonly">
                                        <div>
                                            <Controller
                                                name="currentExpectedCalving"
                                                control={control}
                                                render={({ field:{ fieldState, onChange } }) => (
                                                    <DatePicker
                                                        selected={expectedCalvingDate}
                                                        placeholderText={locale === 'en' ? 'yyyy.MM.dd' : 'dd.MM.yyyy'}
                                                        onChange={(date) => {
                                                            setExpectedCalvingDate(date);
                                                            onChange(getDate(date));
                                                        }}
                                                        // locale={ko}
                                                        dateFormat={locale === 'en' ? 'yyyy.MM.dd' : 'dd.MM.yyyy' }
                                                        readOnly
                                                    />
                                                )}
                                            />
                                        </div>
                                        <span className="plus">
                                            <img src="/assets/images/common/icon_calendar2.png" className="icon" alt="" />
                                        </span>
                                        {errors.selectDate && <span className="err_msg mr-5">This field is required</span>}
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <span className="tit">Lactation Stage</span>
                                    <p>{cowDetail?.currentLactationStage}</p>
                                </div>
                                <div className="d-flex">
                                    <span className="tit">Age in Months</span>
                                    <p>{cowDetail?.currentAgeMonth}</p>
                                </div>
                            </div>
                        }

                        <div className="sub_con mb-0">
                            <div className="d-flex con_tit_wrap">
                                <h4 className="con_tit">Genetic information</h4>
                            </div>
                            <div className="d-flex sub_tit">
                                <span className="">Father</span>
                            </div>
                            <div className="d-flex">
                                <span className="tit">Grade</span>
                                <div className="d-flex input_wrap">
                                    <input 
                                        type="text" 
                                        id="cowFarterGrade" 
                                        name="cowFarterGrade" 
                                        {...register("cowFarterGrade")}
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <span className="tit">Sperm</span>
                                <div className="d-flex input_wrap">
                                    <input 
                                        type="text" 
                                        id="cowFarterSperm" 
                                        name="cowFarterSperm" 
                                        {...register("cowFarterSperm")}
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <span className="tit">Cow Number</span>
                                <div className="d-flex input_wrap">
                                    <input 
                                        type="text" 
                                        id="cowFarterNumber" 
                                        name="cowFarterNumber" 
                                        {...register("cowFarterNumber")}
                                />
                                </div>
                            </div>

                            <div className="d-flex sub_tit">
                                <span className="">Mother</span>
                            </div>
                            <div className="d-flex">
                                <span className="tit">Grade</span>
                                <div className="d-flex input_wrap">
                                    <input 
                                        type="text" 
                                        id="cowMotherGrade" 
                                        name="cowMotherGrade" 
                                        {...register("cowMotherGrade")}
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <span className="tit">Cow Number</span>
                                <div className="d-flex input_wrap">
                                    <input 
                                        type="text" 
                                        id="cowMotherNumber" 
                                        name="cowMotherNumber" 
                                        {...register("cowMotherNumber")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="content_footer d-flex s-btw">
                        <Link to="/cow_mng" className="button cancel">Cancel</Link>
                        <button type="submit" className="button">
                            { urlParams.code === "reg" ? "Registration" : "Application" }
                        </button>
                    </div>
                </div>
            </form>

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    );
}

export default CowDetailMng;