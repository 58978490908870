/* eslint-disable */
import api from '../../../libs/api';
import Radar from '../../../components/charts/RadarChart';
import isLogin from '../../../libs/isLogin';
import { getDate, calculateDate } from '../../../libs/getDate';
import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { farmCode } from '../../../store/store';
import { setCookie, getCookie } from '../../../libs/auth/Cookie';
import { useForm } from 'react-hook-form';
import { useLocation, Link } from 'react-router-dom';
import Dompurify from 'dompurify';
import getMessage from "../../../libs/getMessage";
// Notice
function Notice({ list, popupClose, register }) {

    return (
        <div className="popup notice container">
            <h1 className="title">
                {getMessage("Main.notice")}
            </h1>
            <div className="content_header d-flex">
                <span className="icon_wrap not_bg">
                    <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                </span>
                <span className="div_title mr-auto">
                    {list.title}
                </span>
            </div>
            <div className="content_body" dangerouslySetInnerHTML={{__html: Dompurify.sanitize(list.contents)}}></div>
            <div className="content_footer">
                <div className="dontshow t-right">
                    <input 
                        className="mr-5"
                        type="checkbox" 
                        id={`dontshow${list.seq}`}
                        {...register(`dontshow${list.seq}`)}
                    />
                    <label className="underline" htmlFor={`dontshow${list.seq}`}>Don't look at me anymore</label>
                </div>
                <a className="button mt-20" onClick={() => { popupClose(list.seq) }}>Okay</a>
            </div>
        </div>
    )
}

let dontshowArr = getCookie("dontshowArr");

function Main() {
    const [chartData, setChartData] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [cowLists, setCowLists] = useState([]);
    const [popupOpen, setPopupOpen] = useState(true);
    const [noticeList, setNoticeList] = useState([]);
    const [noticeArr, setNoticeArr] = useState([]);
    
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    
    const location = useLocation();

    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;

    const { register, handleSubmit, watch, formState: { errors } } = useForm({});

    useEffect(() => {
        !isLogin() && window.location.replace('/login');
        getChartData();
        getNoticeList();
        getSummaryData();
        getCowLists();
        
        // redirect시 헤더, 푸터 등 조건부 컴포넌트 렌더링을 위해 새로고침
        if (location.reload) {
            window.location.reload();
        }
    }, []);

    // radar차트 데이터
    async function getChartData() {
        const params = {
            farmCode : current,
            // inputDate : "2022-10-19", 
            inputDate : getDate(calculateDate(-1)), 
        }

        const response = await api.post('/totalFarmStatus', params);

        // 성공
        if (response.data.code === "200") {
            setChartData(response.data.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    console.log('chartData', chartData);

    // summary data
    async function getSummaryData() {
        const params = {
            farmCode : current,
            inputDate : getDate(calculateDate(-1)), 
        }

        const response = await api.post('/Summanry', params);

        // 성공
        if (response.data.code === "200") {
            setSummaryData(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    console.log('summaryData', summaryData);

    // cow list
    async function getCowLists() {
        const params = {
            farmCode : current,
            inputDate : getDate(calculateDate(-1)), 
        }

        const response = await api.post('/MainCowList', params);

        // 성공
        if (response.data.code === "200") {
            setCowLists(response.data.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    console.log('cowLists', cowLists);

    // notice list
    async function getNoticeList() {
        const response = await api.get('/board/boardList');

        // 성공
        if (response.data.code === "200") {
            setNoticeList(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }    
    console.log('noticeList', noticeList);

    useEffect(() => {
        if (dontshowArr) {
            // 오늘 하루 보지않기 체크한 공지사항 있을 경우
            let noticearr = noticeList;
            for (let seq of dontshowArr) {
                noticearr = noticearr.filter((list) => list.seq !== seq);
                setNoticeArr(noticearr);
            }
        } else {
            // 오늘 하루 보지않기 체크한 공지사항 없을 경우
            setNoticeArr(noticeList);
        }
    }, [noticeList, dontshowArr]);

    // 팝업 닫기
    function popupClose(seq) {
        if (watch(`dontshow${seq}`)) {
            // 하루 동안 보지않기 
            if (dontshowArr === undefined || dontshowArr === null) {
                dontshowArr = [];
            }

            dontshowArr.push(seq);
            dontshowArr = dontshowArr.filter((seq, i) => dontshowArr.indexOf(seq) === i);

            setCookie('dontshowArr', JSON.stringify(dontshowArr), { path: "/", maxAge: 86400 });
        }
        
        setNoticeList(noticeList.filter((list) => list.seq !== seq));
        setNoticeArr(noticeList.filter((list) => list.seq !== seq));
    }
    function tableHead(){
        return (
            <>
                <tr>
                    <th rowSpan={2} className="bg">
                        {getMessage("Main.cowsno")}
                    </th>
                    <th rowSpan={2} className="bg">{getMessage("Main.weight")}</th>
                    <th rowSpan={2} className="bg">{getMessage("Main.ageinmonths")} <span>&#40;Parity&#41;</span></th>
                    <th rowSpan={2} className="bg">{getMessage("Main.currentamount")}</th>
                    <th rowSpan={2} className="bg">{getMessage("Main.appropriatefeed")}</th>
                    <th colSpan={2} className="bg bb-none">{getMessage("Main.milkproduction")}</th>
                </tr>
                <tr>
                    <th className="bg">{getMessage("Main.current")}</th>
                    <th className="bg pr-10">{getMessage("Main.estimated")}</th>
                </tr>
            </>
        )
    }

    function tableBody(cow) {
        return (
            <tr key={cow.cowNumber}>
                <td>{cow.cowNumber}&#40;{cow.cowNumber2}&#41;</td>
                <td>{cow.weight}</td>
                <td>{cow.ageMonth} &#40;{cow.parity}&#41;</td>
                <td>{cow.cowFeedAmountTot}</td>
                <td className="text_sky">{cow.cowAppropriateFeed}</td>
                <td>{cow.cowMilkYield}</td>
                <td>{cow.cowMilkYieldEstimated}</td>
            </tr>
        )
    }


    return (
        <div className="main">
            <div className="container">
                <h1 className="title">
                    {getMessage("Main.totalfarmStatus")}
                </h1>
                <div className="con">
                    <div className="content_header">
                        <span className="icon_wrap not_bg">
                            <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                        </span>
                        <span className="div_title">
                            {getMessage("Main.smmary")}
                        </span>
                    </div>
                    <div className="content_body">
                        <Radar data={chartData} />
                    </div>
                </div>
                <div className="con">
                    <div className="content_body">
                        <table className="dashed">
                            <colgroup>
                                <col width="33%" />
                                <col width="33%" />
                                <col width="33%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th className="bg">
                                        {getMessage("Main.subject")}
                                    </th>
                                    <th className="bg t-center">
                                        {getMessage("Main.volatility")}
                                    </th>
                                    <th className="bg pr-10 t-right">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    summaryData?.map((data) => {
                                        return (
                                            <tr key={data.subject}>
                                                <td>{data.subject}</td>
                                                <td className={`t-center ${
                                                    Number(data.status) === 0 ? data.volatility
                                                    : Number(data.status) > 0
                                                        ? "up" : "down"
                                                }`}>
                                                    {
                                                        Number(data.status) > 0
                                                        ? data.status
                                                        : data.status
                                                    }
                                                    {
                                                        Number(data.status) === 0 ? ""
                                                        : Number(data.status) > 0
                                                            ? <span className="up">&uarr;</span>
                                                            : <span className="down">&darr;</span>
                                                    }
                                                </td>
                                                <td className={`pr-10 t-right ${
                                                    Number(data.status) === 0 ? data.volatility
                                                    : Number(data.status) > 0
                                                        ? "up" : "down"
                                                }`}>{data.volatility}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="content_footer">
                        <Link to="/main/customize" className="button mt-20">
                            {getMessage("Main.customize")}
                        </Link>
                    </div>
                </div>

                {/* TODO 신규 가입자의 경우 하단의 젖소리스트 안나옴 */}
                <div className="con">
                    <div className="content_header d-flex mb-10">
                        <span className="icon_wrap not_bg">
                            <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                        </span>
                        <span className="div_title">
                            {getMessage("Main.precisionspecifications")}
                            <span className="small"> &#40;{getMessage("Main.total")}{cowLists.cowCnt}{getMessage("Main.cows")}
                                &#41;
                            </span>
                            <span className="exp">
                                {getMessage("Main.goals")}
                            </span>
                        </span>
                    </div>
                    <div className="content_body">
                        {/* 과사료 */}
                        {
                            cowLists?.overfeedCows?.length > 0 &&
                            <div className="mb-10">
                                {/*TODO-JK  iterator*/}
                                <h3 className="table_title">{getMessage("Main.overfeedcows")} &#40;{cowLists.overfeedCowsCnt}/{cowLists.cowCnt}&#41;</h3>
                                <table className="sm t-center">
                                    <thead>
                                        <tr>
                                            <th rowSpan={2} className="bg">{getMessage("Main.cowsno")}</th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.weight")}</th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.ageinmonths")} <span>&#40;{getMessage("Main.parity")}&#41;</span></th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.currentamount")}</th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.appropriatefeed")}</th>
                                            <th colSpan={2} className="bg bb-none">{getMessage("Main.milkproduction")}</th>
                                        </tr>
                                        <tr>
                                            <th className="bg">{getMessage("Main.current")}</th>
                                            <th className="bg pr-10">{getMessage("Main.estimated")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cowLists?.overfeedCows?.length > 0 &&
                                            cowLists?.overfeedCows.map((cow) => {
                                                return (
                                                    <tr key={cow.cowNumber}>
                                                        <td>{cow.cowNumber}&#40;{cow.cowNumber2}&#41;</td>
                                                        <td>{cow.weight}</td>
                                                        <td>{cow.ageMonth} &#40;{cow.parity}&#41;</td>
                                                        <td>{cow.cowFeedAmountTot}</td>
                                                        <td className="text_sky">{cow.cowAppropriateFeed}</td>
                                                        <td>{cow.cowMilkYield}</td>
                                                        <td>{cow.cowMilkYieldEstimated}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                        
                        {/* 저사료 */}
                        {
                            cowLists?.underfeedCows?.length > 0 &&
                            <div className="mb-10">
                                <h3 className="table_title">{getMessage("Main.underfeedcows")} &#40;{cowLists.underfeedCowsCnt}/{cowLists.cowCnt}&#41;</h3>
                                <table className="sm t-center">
                                    <thead>
                                        <tr>
                                            <th rowSpan={2} className="bg">{getMessage("Main.cowsno")}</th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.weight")}</th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.ageinmonths")} <span>&#40;{getMessage("Main.parity")}&#41;</span></th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.currentamount")}</th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.appropriatefeed")}</th>
                                            <th colSpan={2} className="bg bb-none">{getMessage("Main.milkproduction")}</th>
                                        </tr>
                                        <tr>
                                            <th className="bg">{getMessage("Main.current")}</th>
                                            <th className="bg pr-10">{getMessage("Main.estimated")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cowLists?.underfeedCows?.length > 0 &&
                                            cowLists?.underfeedCows.map((cow) => {
                                                return (
                                                    <tr key={cow.cowNumber}>
                                                        <td>{cow.cowNumber}&#40;{cow.cowNumber2}&#41;</td>
                                                        <td>{cow.weight}</td>
                                                        <td>{cow.ageMonth} &#40;{cow.parity}&#41;</td>
                                                        <td>{cow.cowFeedAmountTot}</td>
                                                        <td className="text_sky">{cow.cowAppropriateFeed}</td>
                                                        <td>{cow.cowMilkYield}</td>
                                                        <td>{cow.cowMilkYieldEstimated}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                        
                        {/* 건유기 */}
                        {
                            cowLists?.dryingCows?.length > 0 &&
                            <div className="mb-10">
                                <h3 className="table_title">{getMessage("Main.cowsfordrying")} &#40;{cowLists.dryingCowsCnt}/{cowLists.cowCnt}&#41;</h3>
                                <table className="sm t-center">
                                    <thead>
                                        <tr>
                                            <th rowSpan={2} className="bg">{getMessage("Main.cowsno")}</th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.weight")}</th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.ageinmonths")} <span>&#40;{getMessage("Main.parity")}&#41;</span></th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.currentamount")}</th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.appropriatefeed")}</th>
                                            <th colSpan={2} className="bg bb-none">{getMessage("Main.milkproduction")}</th>
                                        </tr>
                                        <tr>
                                            <th className="bg">{getMessage("Main.current")}</th>
                                            <th className="bg pr-10">{getMessage("Main.estimated")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            {
                                                cowLists?.dryingCows?.length > 0 &&
                                                cowLists?.dryingCows.map((cow) => {
                                                    return (
                                                        <tr key={cow.cowNumber}>
                                                            <td>{cow.cowNumber}&#40;{cow.cowNumber2}&#41;</td>
                                                            <td>{cow.weight}</td>
                                                            <td>{cow.ageMonth} &#40;{cow.parity}&#41;</td>
                                                            <td>{cow.cowFeedAmountTot}</td>
                                                            <td className="text_sky">{cow.cowAppropriateFeed}</td>
                                                            <td>{cow.cowMilkYield}</td>
                                                            <td>{cow.cowMilkYieldEstimated}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                    </tbody>
                                </table>
                            </div>
                        }
                        
                        {/* 도태된 소 */}
                        {
                            cowLists?.cullingCows?.length > 0 &&
                            <div className="mb-10">
                                <h3 className="table_title">{getMessage("Main.cowsforculling")} &#40;{cowLists.cullingCowsCnt}/{cowLists.cowCnt}&#41;</h3>
                                <table className="sm t-center">
                                    <thead>
                                        <tr>
                                            <th rowSpan={2} className="bg">{getMessage("Main.cowsno")}</th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.weight")}</th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.ageinmonths")} <span>&#40;{getMessage("Main.parity")}&#41;</span></th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.currentamount")}</th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.appropriatefeed")}</th>
                                            <th colSpan={2} className="bg bb-none">{getMessage("Main.milkproduction")}</th>
                                        </tr>
                                        <tr>
                                            <th className="bg">{getMessage("Main.current")}</th>
                                            <th className="bg pr-10">{getMessage("Main.estimated")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cowLists?.cullingCows?.length > 0 &&
                                            cowLists?.cullingCows.map((cow) => {
                                                return (
                                                    <tr key={cow.cowNumber}>
                                                        <td>{cow.cowNumber}&#40;{cow.cowNumber2}&#41;</td>
                                                        <td>{cow.weight}</td>
                                                        <td>{cow.ageMonth} &#40;{cow.parity}&#41;</td>
                                                        <td>{cow.cowFeedAmountTot}</td>
                                                        <td className="text_sky">{cow.cowAppropriateFeed}</td>
                                                        <td>{cow.cowMilkYield}</td>
                                                        <td>{cow.cowMilkYieldEstimated}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }

                        {/* 사료량 유지해도 되는 소 */}
                        {
                            cowLists?.appropriateCows?.length > 0 &&
                            <div className="mb-10">
                                <h3 className="table_title">{getMessage("Main.maintain")} &#40;{cowLists.appropriateCowsCnt}/{cowLists.cowCnt}&#41;</h3>
                                <table className="sm t-center">
                                    <thead>
                                        <tr>
                                            <th rowSpan={2} className="bg">{getMessage("Main.cowsno")}</th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.weight")}</th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.ageinmonths")} <span>&#40;{getMessage("Main.parity")}&#41;</span></th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.currentamount")}</th>
                                            <th rowSpan={2} className="bg">{getMessage("Main.appropriatefeed")}</th>
                                            <th colSpan={2} className="bg bb-none">{getMessage("Main.milkproduction")}</th>
                                        </tr>
                                        <tr>
                                            <th className="bg">{getMessage("Main.current")}</th>
                                            <th className="bg pr-10">{getMessage("Main.estimated")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cowLists?.appropriateCows?.length > 0 &&
                                            cowLists?.appropriateCows.map((cow) => {
                                                return (
                                                    <tr key={cow.cowNumber}>
                                                        <td>{cow.cowNumber}&#40;{cow.cowNumber2}&#41;</td>
                                                        <td>{cow.weight}</td>
                                                        <td>{cow.ageMonth} &#40;{cow.parity}&#41;</td>
                                                        <td>{cow.cowFeedAmountTot}</td>
                                                        <td className="text_sky">{cow.cowAppropriateFeed}</td>
                                                        <td>{cow.cowMilkYield}</td>
                                                        <td>{cow.cowMilkYieldEstimated}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                        
                    </div>
                </div>
            </div>

            {
                // 공지사항 팝업
                popupOpen &&
                noticeArr?.map((list, i) => {
                    return (
                        <Notice 
                            key={list.seq}
                            list={list}
                            popupClose={popupClose}
                            register={register}
                        />
                    )
                })
            }

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    )
}

export default Main;