/* eslint-disable */
import { Range, getTrackBackground } from 'react-range';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { farmCode } from '../../../store/store';

import api from '../../../libs/api';
import { calculateDate, getDate } from '../../../libs/getDate';
import { AlertPath } from '../../../components/Alert';
import getMessage from "../../../libs/getMessage";

function Customize() {
    const [customizeData, setCustomizeData] = useState([]);

    const [milkRange, setMilkRange] = useState({ values: [0] });
    const [profitRange, setProfitRange] = useState({ values: [0] });
    const [carbonRange, setCarbonRange] = useState({ values: [0] });

    const [milkDisable, setMilkDisable] = useState(false);
    const [profitDisable, setProfitDisable] = useState(true);
    const [carbonDisable, setCarbonDisable] = useState(true);
    
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);

    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;

    useEffect(() => {
        getCustomizeData();
    }, [current]);

    async function getCustomizeData() {
        const params = {
            farmCode : current,
        }
        const response = await api.post('/getGoal', params);

        // 성공
        if (response.data.code === "200") {
            setCustomizeData(response.data.data);
            setMilkRange({ values: [response.data.data.milk] });
            setProfitRange({ values: [response.data.data.profit] });
            setCarbonRange({ values: [response.data.data.carbon] });
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    console.log('customizeData', customizeData);

    // customize 저장
    async function application() {
        const params = {
            farmCode: current,
            milk: milkRange.values[0],
            profit: profitRange.values[0],
            carbon: carbonRange.values[0],
        }

        const response = await api.post('/getGoalSave', params);

        // 성공
        if (response.data.code === "200") {
            setAlertMsg(getMessage("Main.save"));
            setAlertOpen(true);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    // set goal 수정
    const [tabIdx, setTabIdx] = useState("milk");
    
    // Milk Production, Profit, Carbon Emission Reduction 탭
    function setTab(idx) {
        setTabIdx(idx);
        
        if (idx === "milk") {
            setMilkDisable(false);
            setProfitDisable(true);
            setCarbonDisable(true);
        } else if (idx === "profit") {
            setMilkDisable(true);
            setProfitDisable(false);
            setCarbonDisable(true);
        } else if (idx === "carbon") {
            setMilkDisable(true);
            setProfitDisable(true);
            setCarbonDisable(false);
        }
    }

    // Milk Production range
    function setMilkProduction(values) {
        if (tabIdx === "milk") {
            setMilkRange({ values });
            realTimeRange(values);
        }
    }

    // Profit range
    function setProfit(values) {
        if (tabIdx === "profit") {
            setProfitRange({ values });
            realTimeRange(values);
        }
    }

    // Carbon Emission Reduction range
    function setCarbonEmission(values) {
        if (tabIdx === "carbon") {
            setCarbonRange({ values });
            realTimeRange(values);
        }
    }

    // range 변경시 api 호출 함수
    async function realTimeRange(values) {
        const params = {
            farmCode: current,
            type: tabIdx,
            value: values[0],
        }

        const response = await api.post('/getGoalMove', params);

        // 성공
        if (response.data.code === "200") {
            console.log('response', response);
            if (tabIdx === "milk") { // milk production
                setProfitRange({ values: [response.data.data.profit] });
                setCarbonRange({ values: [response.data.data.carbon] });

            } else if (tabIdx === "profit") { // profit
                setMilkRange({ values: [response.data.data.milk] });
                setCarbonRange({ values: [response.data.data.carbon] });

            } else if (tabIdx === "carbon") { // carbon emission reduction
                setMilkRange({ values: [response.data.data.milk] });
                setProfitRange({ values: [response.data.data.profit] });
            }

        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    return (
        <div className="container">
            <h1 className="title">{getMessage("Customize.totalfarmstatus")}&nbsp;&gt;&nbsp; {getMessage("Customize.customzie")}</h1>
            <div className="current con">
                <div className="content_header d-flex">
                    <span className="icon_wrap not_bg">
                        <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                    </span>
                    <span className="div_title mr-auto">
                        {getMessage("Customize.setgoal")}
                        <span className="exp">{getMessage("Customize.message")}</span>
                    </span>
                </div>

                <div className="tab mt-10 mb-20">                    
                    <ul className="service_tab tab_header d-flex s-btw goal">
                        <li className={ tabIdx === "milk" ? "active" : "" }>
                            <a onClick={() => { setTab("milk") }}>{getMessage("Customize.milkproduction")}</a>
                        </li>
                        <li className={ tabIdx === "profit" ? "active" : "" }>
                            <a onClick={() => { setTab("profit") }}>{getMessage("Customize.profit")}</a>
                        </li>
                        <li className={ tabIdx === "carbon" ? "active" : "" }>
                            <a onClick={() => { setTab("carbon") }}>{getMessage("Customize.carbonemissionreduction")}</a>
                        </li>
                    </ul>
                </div>

                <div className="content_body mx-10">
                    <div>
                        {/* Milk Production */}
                        <div className="range">
                            <div className="header d-flex">
                                <span className="title mr-auto">Milk Production</span>
                                <span className="percent">
                                    {milkRange.values[0]} %
                                </span>
                            </div>
                            <div className="body">
                                <span className="min">-20%</span>
                                <span className="zero">0</span>
                                <span className="max">20%</span>
                                <Range
                                    values={milkRange.values}
                                    step={1}
                                    min={-20}
                                    max={20}
                                    onChange={(values) => setMilkProduction(values)}
                                    disabled={milkDisable}
                                    renderTrack={({ props, children }) => (
                                        <div
                                            className="range_track"
                                            style={{
                                                ...props.style,
                                                height: "8px",
                                                display: "flex",
                                                width: "100%"
                                            }}
                                        >
                                            <div
                                                ref={props.ref}
                                                style={{
                                                    height: "5px",
                                                    width: "100%",
                                                    borderRadius: "4px",
                                                    alignSelf: "center"
                                                }}
                                            >
                                                {children}
                                            </div>
                                        </div>
                                    )}
                                    renderThumb={({ props }) => (
                                        <div
                                            {...props}
                                            className="range_pointer"
                                            style={{
                                                ...props.style,
                                                height: "16px",
                                                width: "16px",
                                                background: milkDisable ? "#a6a6a6" : "#bab9fc"
                                            }}
                                        ></div>
                                    )}
                                />
                                {
                                    milkRange.values > 0 &&
                                    <div className="range_mark plus" style={{ paddingRight: `calc(${[Math.abs(milkRange.values * 2.5)]}% - 5px)`, height: '8px', background: '#00b050' }}></div>
                                }
                                {
                                    milkRange.values < 0 &&
                                    <div className="range_mark minus" style={{ paddingLeft: `calc(${[Math.abs(milkRange.values * 2.5)]}% - 5px)`, height: '8px', background: '#ff0000' }}></div>
                                }
                            </div>
                        </div>
                        
                        {/* Profit */}
                        <div className="range">
                            <div className="header d-flex">
                                <span className="title mr-auto">Profit</span>
                                <span className="percent">
                                    {profitRange.values[0]} %
                                </span>
                            </div>
                            <div className="body">
                                <span className="min">-20%</span>
                                <span className="zero">0</span>
                                <span className="max">20%</span>
                                <Range
                                    values={profitRange.values}
                                    step={1}
                                    min={-20}
                                    max={20}
                                    onChange={(values) => setProfit(values)}
                                    disabled={profitDisable}
                                    renderTrack={({ props, children }) => (
                                        <div
                                            className="range_track"
                                            style={{
                                                ...props.style,
                                                height: "8px",
                                                display: "flex",
                                                width: "100%"
                                            }}
                                        >
                                            <div
                                                ref={props.ref}
                                                style={{
                                                    height: "5px",
                                                    width: "100%",
                                                    borderRadius: "4px",
                                                    alignSelf: "center"
                                                }}
                                            >
                                                {children}
                                            </div>
                                        </div>
                                    )}
                                    renderThumb={({ props }) => (
                                        <div
                                            {...props}
                                            className="range_pointer"
                                            style={{
                                                ...props.style,
                                                height: "16px",
                                                width: "16px",
                                                background: profitDisable ? "#a6a6a6" : "#bab9fc"
                                            }}
                                        ></div>
                                    )}
                                />
                                {
                                    profitRange.values > 0 &&
                                    <div className="range_mark plus" style={{ paddingRight: `calc(${[Math.abs(profitRange.values * 2.5)]}% - 5px)`, height: '8px', background: '#00b050' }}></div>
                                }
                                {
                                    profitRange.values < 0 &&
                                    <div className="range_mark minus" style={{ paddingLeft: `calc(${[Math.abs(profitRange.values * 2.5)]}% - 5px)`, height: '8px', background: '#ff0000' }}></div>
                                }
                            </div>
                        </div>
                        
                        {/* Carbon Emission Reduction */}
                        <div className="range">
                            <div className="header d-flex">
                                <span className="title mr-auto">Carbon Emission Reduction</span>
                                <span className="percent">
                                    {carbonRange.values[0]} %
                                </span>
                            </div>
                            <div className="body">
                                <span className="min">-20%</span>
                                <span className="zero">0</span>
                                <span className="max">20%</span>
                                <Range
                                    values={carbonRange.values}
                                    step={1}
                                    min={-20}
                                    max={20}
                                    onChange={(values) => setCarbonEmission(values)}
                                    disabled={carbonDisable}
                                    renderTrack={({ props, children }) => (
                                        <div
                                            className="range_track"
                                            style={{
                                                ...props.style,
                                                height: "8px",
                                                display: "flex",
                                                width: "100%"
                                            }}
                                        >
                                            <div
                                                ref={props.ref}
                                                style={{
                                                    height: "5px",
                                                    width: "100%",
                                                    borderRadius: "4px",
                                                    alignSelf: "center"
                                                }}
                                            >
                                                {children}
                                            </div>
                                        </div>
                                    )}
                                    renderThumb={({ props }) => (
                                        <div
                                            {...props}
                                            className="range_pointer"
                                            style={{
                                                ...props.style,
                                                height: "16px",
                                                width: "16px",
                                                background: carbonDisable ? "#a6a6a6" : "#bab9fc"
                                            }}
                                        ></div>
                                    )}
                                />
                                {
                                    carbonRange.values > 0 &&
                                    <div className="range_mark plus" style={{ paddingLeft: `calc(${[Math.abs(carbonRange.values * 2.5)]}% - 5px)`, height: '8px', background: '#ff0000' }}></div>
                                }
                                {
                                    carbonRange.values < 0 &&
                                    <div className="range_mark minus" style={{ paddingRight: `calc(${[Math.abs(carbonRange.values * 2.5)]}% - 5px)`, height: '8px', background: '#00b050' }}></div>
                                }
                            </div>
                        </div>
                    </div>   

                    <p className="customize_notice t-center">
                        {getMessage("Customize.thechangedsettingswillchange")} <br />
                        {getMessage("Customize.aftermidnightthenextday")}
                    </p>      
                </div>

                {/* 3개 합해서 100일때 */}
                {/* <div className="content_body mx-10">
                    <div className="range">
                        <div className="header d-flex">
                            <span className="title mr-auto">Milk Production</span>
                            <span className="percent">
                                {milkRange.values[0]} %
                            </span>
                            <a 
                                className="zoom" 
                                onClick={() => { 
                                    milkDisable ? setMilkDisable(false) : setMilkDisable(true)
                                }}
                            >
                                <img className="icon" src={milkDisable ? "/assets/images/common/icon_pincette.png" : "/assets/images/common/icon_pincette_none.png"} />
                            </a>
                        </div>
                        <div className="body">
                            <Range 
                                step={1}
                                min={0}
                                max={100}
                                values={milkRange.values}
                                onChange={(values) => {
                                    // milk production, profit, carbon status 3개 합해서 100%
                                    if (profitRange.values[0] + carbonRange.values[0] <= 100) {
                                        // 최대값 설정
                                        if (100 - (profitRange.values[0] + carbonRange.values[0]) >= values[0]) {
                                            setMilkRange({ values }); 
                                        }
                                    }
                                }}
                                renderTrack={({ props, children }) => (
                                    <div
                                        {...props}
                                        className="range_track"
                                        style={{
                                            ...props.style,
                                            height: '8px',
                                            width: '100%',
                                            background: '#fff'
                                        }}
                                    >
                                        {children}
                                    </div>
                                )}
                                renderThumb={({ props }) => (
                                    <div
                                        {...props}
                                        className="range_pointer"
                                        style={{
                                            ...props.style,
                                            height: '16px',
                                            width: '16px',
                                            backgroundColor: '#bab9fc'
                                        }}
                                    ></div>
                                )}
                                disabled={milkDisable}
                            />
                            <div className="range_mark" style={{ width: `calc(${[milkRange.values]}% - 5px)`, height: '8px', background: '#bab9fc' }}></div>
                        </div>
                    </div>
                    <div className="range">
                        <div className="header d-flex">
                            <span className="title mr-auto">Profit</span>
                            <span className="percent">
                                {profitRange.values[0]} %
                            </span>
                            <a 
                                className="zoom" 
                                onClick={() => { 
                                    profitDisable ? setProfitDisable(false) : setProfitDisable(true)
                                }}
                            >
                                <img className="icon" src={profitDisable ? "/assets/images/common/icon_pincette.png" : "/assets/images/common/icon_pincette_none.png"} />
                            </a>
                        </div>
                        <div className="body">
                            <Range 
                                step={1}
                                min={0} 
                                max={100}
                                values={profitRange.values}
                                onChange={(values) => { 
                                    // milk production, profit, carbon status 3개 합해서 100%
                                    if (milkRange.values[0] + carbonRange.values[0] <= 100) {
                                        // 최대값 설정
                                        if (100 - (milkRange.values[0] + carbonRange.values[0]) >= values[0]) {
                                            setProfitRange({ values }); 
                                        }
                                    }
                                }}
                                renderTrack={({ props, children }) => (
                                    <div
                                        {...props}
                                        className="range_track"
                                        style={{
                                            ...props.style,
                                            height: '8px',
                                            width: '100%',
                                            background: '#fff'
                                        }}
                                    >
                                        {children}
                                    </div>
                                )}
                                renderThumb={({ props }) => (
                                    <div
                                        {...props}
                                        className="range_pointer"
                                        style={{
                                            ...props.style,
                                            height: '16px',
                                            width: '16px',
                                            backgroundColor: '#bab9fc'
                                        }}
                                    ></div>
                                )}
                                disabled={profitDisable}
                            />
                            <div className="range_mark" style={{ width: `calc(${[profitRange.values]}% - 5px)`, height: '8px', background: '#bab9fc' }}></div>
                        </div>
                    </div>
                    <div className="range">
                        <div className="header d-flex">
                            <span className="title mr-auto">Carbon Status</span>
                            <span className="percent">
                                {carbonRange.values[0]} %
                            </span>
                            <a 
                                className="zoom" 
                                onClick={() => { 
                                    carbonDisable ? setCarbonDisable(false) : setCarbonDisable(true)
                                }}
                            >
                                <img className="icon" src={carbonDisable ? "/assets/images/common/icon_pincette.png" : "/assets/images/common/icon_pincette_none.png"} />
                            </a>
                        </div>
                        <div className="body">
                            <Range 
                                step={1}
                                min={0}
                                max={100}
                                values={carbonRange.values}
                                onChange={(values) => { 
                                    // milk production, profit, carbon status 3개 합해서 100%
                                    if (milkRange.values[0] + profitRange.values[0] <= 100) {
                                        // 최대값 설정
                                        if (100 - (milkRange.values[0] + profitRange.values[0]) >= values[0]) {
                                            setCarbonRange({ values }); 
                                        }
                                    }
                                }}
                                renderTrack={({ props, children }) => (
                                    <div
                                        {...props}
                                        className="range_track"
                                        style={{
                                            ...props.style,
                                            height: '8px',
                                            width: '100%',
                                            background: '#fff'
                                        }}
                                    >
                                        {children}
                                    </div>
                                )}
                                renderThumb={({ props }) => (
                                    <div
                                        {...props}
                                        className="range_pointer"
                                        style={{
                                            ...props.style,
                                            height: '16px',
                                            width: '16px',
                                            backgroundColor: '#bab9fc'
                                        }}
                                    ></div>
                                )}
                                disabled={carbonDisable}
                            />
                            <div className="range_mark" style={{ width: `calc(${[carbonRange.values]}% - 5px)`, height: '8px', background: '#bab9fc' }}></div>
                        </div>
                    </div>

                    <div className="t-center customize_notice">
                        The changed settings will change <br />
                        after midnight the next day.
                    </div>
                </div> */}
                <div className="content_footer btn_wrap d-flex s-btn">
                    <Link className="button cancel" to="/">{getMessage("Customize.cancel")}</Link>
                    <a onClick={application} className="button ml-auto">{getMessage("Customize.application")}</a>
                </div>
            </div>

            {
                alertOpen && <AlertPath alertMsg={alertMsg} setAlertOpen={setAlertOpen} path="/" />
            }
        </div>
    )
}

export default Customize;