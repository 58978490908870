/* eslint-disable */
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import Select from "react-select";
import { useState, useEffect } from 'react';

import { useRecoilState } from "recoil";
import { farmCode } from './../../../store/store';

import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';

import CustomOption from './../../../components/CustomOption';
import DateTermBtn from './../../../components/DateTermBtn';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import api from './../../../libs/api';
import { calculateDate, getDate } from '../../../libs/getDate';
import LineChart from './../../../components/charts/LineChart';
import GroupedBarChart from './../../../components/charts/GroupedBarChart';
import LineAreaChart from './../../../components/charts/LineAreaChart';
import { Alert } from '../../../components/Alert';
import CustomDatePicker from "../../../libs/CustomDatePicker";

function FarmDetail({ cowNumber, groupType, groupKey, current, setAlertMsg, setAlertOpen }) {
    const [startDate, setStartDate] = useState(calculateDate(-1));
    const [dateTerm, setDateTerm] = useState("day");
    const [farmDetailGraph, setFarmDetailGraph] = useState([]);
    const [cowDetail, setCowDetail] = useState([]);

    // 그래프
    useEffect(() => {
        getFarmDetailGraph();
    }, [cowNumber, groupType, groupKey, startDate, current]);
    async function getFarmDetailGraph() {
        const params = {
            cowNumber: cowNumber,
            inputDate : getDate(startDate),

            seachInputDate: getDate(startDate),
            farmCode: current,
            groupType: groupType,
            groupKey: groupKey,
        }
        const response = await api.post('/productivity/cowPerData', params);
        
        // 성공
        if (response.data.code === "200") {
            setFarmDetailGraph(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    console.log('farmDetailGraph', farmDetailGraph);
    
    // 소 상세 유전정보 등
    useEffect(() => {
        getCowDetail();
    }, [cowNumber, groupType, groupKey, current]);
    async function getCowDetail() {
        const params = {
            farmCode: current,
            inputDate : getDate(startDate),
            groupType: groupType,
            groupKey: groupKey,
            cowNumber: cowNumber
        }
        
        const response = await api.post('/productivity/cowPerDetail', params);
        
        // 성공
        if (response.data.code === "200") {
            setCowDetail(response.data.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    console.log('cowDetail', cowDetail);
    const locale = localStorage.getItem("locale");
    return (
        <div className="farm_details main_content">
            <div className="content_header d-flex">
                <span className="icon_wrap">
                    <img src="/assets/images/common/icon_farm_detail.png" alt="" />
                </span>
                <span className="div_title">Cow Details</span>
                <div className="date_btn_wrap ml-auto">
                    {/* <DateTermBtn dateTerm={dateTerm} setDateTerm={setDateTerm} /> */}
                </div>
                <div className="datepicker_wrap from_to solo">
                    <img src="/assets/images/common/icon_calendar.png" className="calendar" alt="" />
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        // locale={ko}
                        dateFormat={locale === 'en' ? 'yy.MM.dd' : 'dd.MM.yy' }
                    />
                    <img src="/assets/images/common/icon_calendar_arrow.png" className="arrow" alt="" />
                </div>
            </div>
            
            <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
            >
                <SwiperSlide>
                    <div className="status_guide">
                        <span className="danger"></span>danger
                        <span className="good"></span>good
                        <span className="excellent"></span>excellent
                    </div>
                    <GroupedBarChart 
                        data={farmDetailGraph[3]} 
                        legend={false} 
                        categories={"Productivity Score"} 
                    />

                    <GroupedBarChart 
                        data={farmDetailGraph[2]} 
                        legend={false} 
                        categories={"Milk yield"} 
                    />

                    <GroupedBarChart 
                        data={farmDetailGraph[0]} 
                        legend={false} 
                        categories={"FCE"} 
                    />

                    <GroupedBarChart 
                        data={farmDetailGraph[1]} 
                        legend={true} 
                        categories={"HC"} 
                    />
                </SwiperSlide>

                <SwiperSlide>
                    <div className="div_table">
                        <h4 className="title">Cow Information</h4>
                        {
                            cowDetail &&
                            <CowInformation cowDetail={cowDetail} />
                        }
                    </div>
                </SwiperSlide>
                
                <SwiperSlide>
                    <div className="div_table">
                        <h4 className="title">Reproduction Information</h4>
                        {
                            cowDetail &&
                            <ReproductionInformation cowDetail={cowDetail} />
                        }
                        <h4 className="title mt-10">Genetic Information</h4>
                        {
                            cowDetail &&
                            <GeneticInformation cowDetail={cowDetail} />
                        }
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

function CowInformation({ cowDetail }) {
    return (
        <div className="striped">
            <div className="tr th d-flex">
                <h5 className="tit">Breed</h5>
                <p className="ml-auto">{cowDetail.cowBreed !== "" ? cowDetail.cowBreed : "-"}</p>
            </div>
            <div className="tr d-flex">
                <h5 className="tit">ID</h5>
                <p className="ml-auto">{cowDetail.cowIdentifyNumber !== "" ? cowDetail.cowIdentifyNumber : "-"}</p>
            </div>
            <div className="tr d-flex">
                <h5 className="tit">Date of Birth</h5>
                <p className="ml-auto">{cowDetail.cowBirth !== "" ? cowDetail.cowBirth : "-"}</p>
            </div>
            <div className="tr d-flex">
                <h5 className="tit">Number of Pregnancy</h5>
                <p className="ml-auto">{cowDetail.currentPregnancy !== "" ? cowDetail.currentPregnancy : "-"}</p>
            </div>
            <div className="tr d-flex">
                <h5 className="tit">Parity</h5>
                <p className="ml-auto">{cowDetail.currentParity !== "" ? cowDetail.currentParity : "-"}</p>
            </div>
            <div className="tr d-flex">
                <h5 className="tit">Expected Calving Date</h5>
                <p className="ml-auto">{cowDetail.currentExpectedCalving !== "" ? cowDetail.currentExpectedCalving : "-"}</p>
            </div>
            <div className="tr d-flex">
                <h5 className="tit">Lactation Stage</h5>
                <p className="ml-auto">{cowDetail.currentLactationStage !== "" ? cowDetail.currentLactationStage : "-"}</p>
            </div>
            <div className="tr d-flex">
                <h5 className="tit">Age in Months</h5>
                <p className="ml-auto">{cowDetail.currentAgeMonth !== "" ? cowDetail.currentAgeMonth : "-"}</p>
            </div>
            <div className="tr d-flex">
                <h5 className="tit">Body Weight</h5>
                <p className="ml-auto">{cowDetail.currentWeight !== "" ? cowDetail.currentWeight : "-"}kg</p>
            </div>
        </div>
    )
}

function ReproductionInformation({ cowDetail }) {
    return (
        <div className="">
            <div className="tr th d-flex">
                <h5 className="tit">&#40;Recently&#41;</h5>
            </div>
            <div className="tr d-flex">
                <h5 className="tit">Gestation Date &#40;{cowDetail.recentlyCnt}&#41;</h5>
                <p className="ml-auto">{cowDetail.recentlyDate !== "" ? cowDetail.recentlyDate : "-"}</p>
            </div>
            <div className="tr d-flex">
                <h5 className="tit">- First Service Date</h5>
                <p className="ml-auto">{cowDetail.recentlyFirstDate !== "" ? cowDetail.recentlyFirstDate : "-"}</p>
            </div>
            <div className="tr d-flex">
                <h5 className="tit">- Second Service Date</h5>
                <p className="ml-auto">{cowDetail.recentlySecondDate !== "" ? cowDetail.currentPregnancy : "-"}</p>
            </div>
            
            <div className="tr th d-flex">
                <h5 className="tit">&#40;Previous&#41;</h5>
            </div>
            <div className="tr d-flex">
                <h5 className="tit">
                    Gestation Date 
                    {
                        cowDetail.previousCnt !== "" &&
                        <>&#40;{cowDetail.previousCnt}&#41;</>
                    } 
                </h5>
                <p className="ml-auto">{cowDetail.previousDate !== "" ? cowDetail.previousDate : "-"}</p>
            </div>
            <div className="tr d-flex">
                <h5 className="tit">- First Service Date</h5>
                <p className="ml-auto">{cowDetail.previousFirstDate !== "" ? cowDetail.previousFirstDate : "-"}</p>
            </div>
            <div className="tr d-flex">
                <h5 className="tit">- Second Service Date</h5>
                <p className="ml-auto">{cowDetail.previousSecondDate !== "" ? cowDetail.previousSecondDate : "-"}</p>
            </div>
        </div>
    )
}

function GeneticInformation({ cowDetail }) {
    return (
        <div className="">
            <table>
                <thead>
                    <tr className="border-b">
                        <th className="bg">Blood</th>
                        <th className="bg t-center">Grade</th>
                        <th className="bg t-center">Sperm</th>
                        <th className="bg t-right pr-10">Cow's No.</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="">Father</td>
                        <td className="t-center">{cowDetail.cowFarterGrade !== "" ? cowDetail.cowFarterGrade : "-"}</td>
                        <td className="t-center">{cowDetail.cowFarterSperm !== "" ? cowDetail.cowFarterSperm : "-"}</td>
                        <td className="t-right pr-10">{cowDetail.cowFarterNumber}</td>
                    </tr>
                    <tr>
                        <td className="">Mother</td>
                        <td className="t-center">{cowDetail.cowMotherGrade !== "" ? cowDetail.cowMotherGrade : "-"}</td>
                        <td className="t-center">{cowDetail.cowMotherSperm !== "" ? cowDetail.cowMotherSperm : "-"}</td>
                        <td className="t-right pr-10">{cowDetail.cowMotherNumber}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

function ProductivityCowDetail() {
    const [startDate, setStartDate] = useState(calculateDate(-30));
    const [endDate, setEndDate] = useState(calculateDate(14));
    const [dateTerm, setDateTerm] = useState("day");
    const [groupCowList, setGroupCowList] = useState([]);
    const [productivityCowDetail, setProductivityCowDetail] = useState([]);
    const [titleScore, setTitleScore] = useState([]);
    
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const locale = localStorage.getItem("locale");

    const urlParams = useParams();
    const history = useHistory();
    const location = useLocation();
    
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;

    let groupKey = "";
    let groupType = "";
    let cowNumber = "";

    if (urlParams.id.indexOf("_") > -1) {
        let keyType = urlParams.id.split("_");
        groupKey = keyType[0];

        switch (keyType[1]) {
            case "danger": groupType = "D"; break;
            case "good": groupType = "G"; break;
            case "excellent": groupType = "E"; break;
        }
    } else {
        cowNumber = urlParams.id;
    }

    useEffect(() => {
        getGroupCowList();
    }, [cowNumber, groupType, groupKey, currentFarmCode]);
    async function getGroupCowList() {
        const params = {
            farmCode : current,
            inputDate : getDate(calculateDate(-1)),
            groupKey : groupKey,
            groupType: groupType,
            cowNumber: cowNumber 
        }
        const response = await api.post('/productivity/farmCowSearchList', params);
        
        // 성공
        if (response.data.code === "200") {
            setGroupCowList(response.data.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    console.log('groupCowList', groupCowList);

    useEffect(() => {
        getPerDairyCow();
    }, [startDate, endDate, cowNumber, groupType, groupKey, currentFarmCode]);
    async function getPerDairyCow() {
        const params = {
            cowNumber: cowNumber,
            startDate : getDate(startDate),
            endDate : getDate(endDate),

            inputDate: getDate(calculateDate(-1)),
            farmCode: current,
            groupKey : groupKey,
            groupType: groupType,
        }
        const response = await api.post('/productivity/farmCowMilkGraph', params);
        
        // 성공
        if (response.data.code === "200") {
            setProductivityCowDetail(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    console.log('productivityCowDetail', productivityCowDetail);

    useEffect(() => {
        getTitleScore();
    }, [currentFarmCode]);
    
    async function getTitleScore() {
        const params = {
            farmCode : current,
            inputDate : getDate(calculateDate(-1))
        }
        const response = await api.post('/productivity/titleScore', params);
        
        // 성공
        if (response.data.code === "200") {
            setTitleScore(response.data.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    // 이전, 다음 젖소번호
    let prevCowNo = 0;
    let nextCowNo = 0;
    if (groupCowList.cowList !== undefined) {
        const currentCowNo = groupCowList.cowList.findIndex(cow => cow.number === groupCowList.cowNumber);

        prevCowNo = groupCowList.cowList[currentCowNo - 1] === undefined ? groupCowList.cowList[groupCowList.cowList.length - 1] : groupCowList.cowList[currentCowNo - 1];

        nextCowNo = groupCowList.cowList[currentCowNo + 1] === undefined ? groupCowList.cowList[0] : groupCowList.cowList[currentCowNo + 1];
    }

    const groupOptions = [
        { value: "all", label: "ALL" },
        { value: "Score", label: "Score" },
        { value: "Score_danger", label: "danger" },
        { value: "Score_good", label: "good" },
        { value: "Score_excellent", label: "excellent" },
        { value: "Milk", label: "Milk yield" },
        { value: "Milk_danger", label: "danger" },
        { value: "Milk_good", label: "good" },
        { value: "Milk_excellent", label: "excellent" },
        { value: "FCE", label: "FCE" },
        { value: "FCE_danger", label: "danger" },
        { value: "FCE_good", label: "good" },
        { value: "FCE_excellent", label: "excellent" },
        { value: "HC", label: "HC" },
        { value: "HC_danger", label: "danger" },
        { value: "HC_good", label: "good" },
        { value: "HC_excellent", label: "excellent" },
    ];
    const [selectOption, setSelectOption] = useState(groupOptions[0]);

    function groupChange(option) {
        if (option.value !== "all") {
            // 그룹으로 이동
            if (option.value === "Score" || option.value === "Milk" || option.value === "FCE" || option.value === "HC") {
                history.push(`/productivity/cow_group/${option.value}`);
            } else {
                // cow no 없이 상세로 이동
                history.push(`/productivity/cow_list/${option.value}`);
            }
        }
    }

    function selectCow(option) {
        if (option.value !== "danger" && option.value !== "good" && option.value !== "excellent") {
            history.push(`/productivity/cow_list/${option.value}`);
        }
    }

    let cowOptions = [];
    if (groupCowList.cowList !== undefined) {
        for (let cow of groupCowList?.cowList) {
            cowOptions.push({
                value: cow.number,
                label: cow.number,
                status: cow.status
            });
        }
    }
    // console.log('cowOptions', cowOptions);

    const selectDefault = { 
        value: groupCowList.cowNumber, 
        label: groupCowList.cowNumber,
    };

    const filterStyles = {
        control: base => ({
            ...base,
            height: 50,
            minHeight: 50
        })
    }
        
    let danger = document.createElement("span");
    danger.setAttribute("class", "danger");
    let good = document.createElement("span");
    good.setAttribute("class", "good");
    let excellent = document.createElement("span");
    excellent.setAttribute("class", "excellent");
    setTimeout(() => {
        const toolbarSelect = document.getElementsByClassName("toolbarSelect");
        const select1 = toolbarSelect[0].getElementsByClassName("select__single-value")[0];
        const select1Value = select1?.innerHTML;
        if (select1Value === "danger") {
            select1.prepend(danger);
        }
        if (select1Value === "good") {
            select1.prepend(good);
        }
        if (select1Value === "excellent") {
            select1.prepend(excellent);
        }
    }, 0);
    setTimeout(() => {
        const toolbarSelect = document.getElementsByClassName("toolbarSelect");
        const select2 = toolbarSelect[1].getElementsByClassName("select__single-value")[0];
        const select2Value = select2.innerHTML;

        const status = cowOptions.filter((option) => option.value === groupCowList.cowNumber)[0]?.status;

        if (status === "D") {
            select2.prepend(danger);
        }
        if (status === "G") {
            select2.prepend(good);
        }
        if (status === "E") {
            select2.prepend(excellent);
        }
    }, 0);

    return (
        <div className="content healthy_cow_detail">
            <div className="farm_score_div content_wrap">
                <div className="score_wrap d-flex">
                    <img src="/assets/images/common/icon_productivity.png" alt="" />
                    <div className="title">
                        <span className="sub">Farm</span>
                        <h2 className="score">Productivity Score {titleScore.score} &#40; Top {titleScore.topPer} % &#41;</h2>
                    </div>
                </div>

                <div className="toolbar d-flex">
                    <Link to="/productivity" className="round_btn">View Farm</Link>
                    <Select
                        className="select_container toolbarSelect ml-auto"
                        options={groupOptions}
                        onChange={groupChange}
                        defaultValue={location?.state?.defaultValue}
                        styles={filterStyles}
                        classNamePrefix="select"
                        components={{ Option: CustomOption }}
                    />
                </div>

                <div className="cow_detail main_content">
                    <div className="cow_selector d-flex j-center">
                        {
                            prevCowNo &&
                            <Link to={`/productivity/cow_list/${prevCowNo.number}`} className="arrow_prev">
                                <img src="/assets/images/common/arrow_prev.png" alt="" />
                            </Link>
                        }
                        <Select
                            className="select_container ml-auto mr-auto toolbarSelect"
                            options={cowOptions}
                            onChange={selectCow}
                            value={selectDefault}
                            styles={filterStyles}
                            classNamePrefix="select"
                            components={{ Option: CustomOption }}
                        />
                        {
                            nextCowNo &&
                            <Link to={`/productivity/cow_list/${nextCowNo.number}`} className="arrow_next">
                                <img src="/assets/images/common/arrow_next.png" alt="" />
                            </Link>
                        }
                    </div>
                    <div className="content_header d-flex">
                        <span className="icon_wrap not_bg">
                            <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                        </span>
                        <span className="div_title">#{groupCowList?.cowNumber}</span>
                        <div className="ml-auto">
                            {/* <DateTermBtn dateTerm={dateTerm} setDateTerm={setDateTerm} /> */}
                        </div>
                        <div className="datepicker_wrap from_to">
                            <img src="/assets/images/common/icon_calendar.png" className="calendar" alt="" />
                            <CustomDatePicker
                                selectedDate={startDate}
                                endDate={endDate}
                                onStartDateChange={(date) => setStartDate(date)}
                                onEndDateChange={(date) => setEndDate(date)}
                                locale={locale}
                            />
                            <img src="/assets/images/common/icon_calendar_arrow.png" className="arrow" alt="" />
                        </div>
                    </div>
                    <div className="graph">
                        {
                            productivityCowDetail && 
                            <LineChart data={productivityCowDetail} title="milk" />
                        }
                    </div>
                </div>
            </div>      

            <FarmDetail 
                cowNumber={cowNumber} 
                groupType={groupType} 
                groupKey={groupKey} 
                current={current} 
                setAlertMsg={setAlertMsg} 
                setAlertOpen={setAlertOpen} 
            />

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    )
}

export default ProductivityCowDetail;