/* eslint-disable */
import { useEffect, useState } from 'react';

import api from './../../libs/api';

import { useRecoilState } from "recoil";
import { farmCode } from '../../store/store';
import { Link } from 'react-router-dom';
import { numberPipe } from '../../libs/pipe';
import { Alert } from '../../components/Alert';
import getMessage from "../../libs/getMessage";

function ServiceMng() {
    const [myFarmService, setMyFarmService] = useState({});
    
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;

    useEffect(() => {
        getMyFarmService();
    }, [currentFarmCode]);
    
    //내 농장 서비스
    async function getMyFarmService() {
        const response = await api.get(`/farm/info?farmCode=${current}`);
        console.log('response', response);

        // 성공
        if (response.data.code === "200") {
            setMyFarmService(response.data.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    console.log('myFarmService', myFarmService);

    return (
        <div className="container">
            <h1 className="title">{getMessage("Servicemng.servicesinuse")}</h1>
            <div className="con">
                <div className="content_header d-flex">
                    <span className="icon_wrap not_bg">
                        <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                    </span>
                    <span className="div_title mr-auto">
                        {getMessage("Servicemng.storage")}
                    </span>
                </div>
                <div className="content_body">
                    <div className="gauge_point">
                        {
                            (myFarmService?.storage/(myFarmService?.cowCount * 300)) * 100 > 10
                            && <span className="min">0</span>
                        }

                        <span 
                            className="cur"
                            style={
                                (myFarmService?.storage/(myFarmService?.cowCount * 300)) * 100 >= 95
                                ? { right: 0, left: "auto", paddingLeft: "10px" }
                                : { left: (myFarmService?.storage/(myFarmService?.cowCount * 300)) * 100 + "%" }
                            }
                        >
                            {numberPipe(myFarmService?.storage)}MB
                        </span>

                        <span className="max">{numberPipe(myFarmService?.cowCount * 300)}MB</span>
                    </div>
                    <div className="storage gauge">
                        <span style={{ width: (myFarmService?.storage/(myFarmService?.cowCount * 300)) * 100 + "%" }}></span>
                    </div>
                    <ul className="exp mt-10">
                        <li>{getMessage("Servicemng.message1")}</li>
                        <li>{getMessage("Servicemng.message2")}</li>
                        <li className="mt-10 underline">
                            {getMessage("Servicemng.storageusage")}{numberPipe(myFarmService?.storage)}MB {getMessage("Servicemng.used")}
                            {
                                myFarmService?.storage < myFarmService?.cowCount * 5
                                ? ` (free: ${numberPipe(myFarmService?.cowCount * 300 - myFarmService?.storage)}MB)`
                                : ` (over: ${numberPipe(myFarmService?.storage - myFarmService?.cowCount * 300)}MB)`
                            }
                        </li>
                    </ul>
                </div>
            </div>
            
            <div className="con">
                <div className="content_header d-flex">
                    <span className="icon_wrap not_bg">
                        <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                    </span>
                    <span className="div_title mr-auto">
                        {getMessage("Servicemng.servicesinuse")}
                    </span>
                </div>
                <div className="content_body">
                    <div className="sub_con mt-10">
                        {/* daily billing services */}
                        {
                            myFarmService?.itemList?.length > 0 &&
                            <div>
                                <h5 className="sub_tit">{getMessage("Servicemng.billingservices")}</h5>
                                <ul>
                                    { 
                                        myFarmService?.itemList.map((list, i) => {
                                            return (
                                                // TODO 겹치는 서비스 사라지면 + i 빼야함
                                                <li className="border_round" key={list.itemCode + i}>
                                                    <span className="mr-5 number title_round">{i + 1}</span>
                                                    {list.itemName}
                                                </li>
                                            )
                                        }) 
                                    }
                                </ul>
                            </div>
                        }

                        {/* optional service */}
                        {
                            myFarmService?.itemOneTimeList?.length > 0 &&
                            <div>
                                <h5 className="sub_tit">{getMessage("Servicemng.optionalservices")}</h5>
                                <ul>
                                    { 
                                        myFarmService?.itemOneTimeList.map((list, i) => {
                                            return (
                                                <li className="border_round" key={list.itemCode}>
                                                    <span className="mr-5 number title_round">{i + 1}</span>
                                                    {list.itemName}
                                                </li>
                                            )
                                        }) 
                                    }
                                </ul>
                            </div>
                        }
                        <div>
                            <h5 className="sub_tit">
                                {getMessage("Servicemng.creditusedtoday")}
                                {/* - {numberPipe(myFarmService?.dailyPoint)} */}
                            </h5>
                            <ul>
                                <li className=" border_round t-right">
                                    <span className="mr-5 text title_round">{getMessage("Servicemng.chargingday")}</span>
                                    {myFarmService?.currentPointDate}
                                </li>
                                <li className=" border_round t-right">
                                    <span className="mr-5 text title_round">{getMessage("Servicemng.totalcredit")}</span>
                                    {numberPipe(myFarmService?.totalPoint)}
                                </li>
                                <li className=" border_round t-right">
                                    <span className="mr-5 text title_round">{getMessage("Servicemng.remainingcredit")}</span>
                                    <p className={
                                        numberPipe(myFarmService?.restPoint) < 300000
                                        ? "t_danger"
                                        : ""
                                    }>
                                        {numberPipe(myFarmService?.restPoint)}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="content_footer">
                    <Link className="button" to={{
                        pathname: "/service_mng/purchasing_product",
                        state: myFarmService
                    }}>Purchasing a product</Link>
                </div>
            </div>

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    );
}

export default ServiceMng;