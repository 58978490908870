/* eslint-disable */
import moment from "moment";
import "moment/locale/ko";
import { getCookie, setCookie, removeCookie } from "./Cookie";
import axios from 'axios';

const refresh = async (config) => {
    const refreshToken = getCookie("refreshToken");
    const expireAt = localStorage.getItem("expiresAt");

    // 토큰이 만료되었다면
    if (moment(expireAt).diff(moment()) < 1000 && refreshToken) {
        const params = {
            refreshToken: refreshToken,
        };

        //재발급 요청
        const response = await axios.post(`${process.env.REACT_APP_API_URL}newToken`, params);
        
        removeCookie("accessToken");
        setCookie("accessToken", response.data.data.token, { path: "/", maxAge: 86400 }); // 만료 기간 1일
        localStorage.setItem("expiresAt", response.data.data.tokenExpired);
        config.headers["Authorization"] = `Bearer ${response.data.data.token}`; // 토큰 교체
    }

    return config;
};

const refreshErrorHandle = () => {
    localStorage.removeItem("refreshToken");
};

export { refresh, refreshErrorHandle };
