/* eslint-disable */

import Chart from 'react-apexcharts'
import { useState, useEffect } from 'react';

function LineChart({ title, data }) {
    const [state, setState] = useState({
        type: "line",
        options: {
            stroke: {
                width: 2
            },
            colors: ['#526881', '#5f82c1'],
            xaxis: {categories: []},
            dataLabels: {
                enabled: false,
                style: {
                    fontSize: '10px',
                    fontWeight: 'normal',
                },
                background: {
                    enabled: true,
                    foreColor: "#fff"
                }
            },
        },
        series : [],
    });
    
    
    useEffect(() => {
        if (title === "farm_details") {
            // 건강 farm details 일때
            data &&
            setState({
                type: "line",
                options: {
                    stroke: {
                        width: 2
                    },
                    colors: ['#beaad2', '#ffbe82', '#ffbeb4', '#c8e6e6', '#c5e0b4', '#526881', '#5b9bd5'],
                    xaxis: {
                        categories: data[0].data
                    },
                    yaxis: {
                        decimalsInFloat: 0,
                    },
                    dataLabels: {
                        enabled: false,
                        style: {
                            fontSize: '10px'
                        },
                        background: {
                            enabled: true,
                            foreColor: "#fff"
                        }
                    },
                    title: {
                        text: data[1].title
                    }
                },
                series : [data[1]]
            })
        } else if (title === "carbon_detail") {
            // 탄소 carbon detail 일때
            data &&
            setState({
                type: "line",
                options: {
                    stroke: {
                        width: 2
                    },
                    colors: ['#beaad2', '#ffbe82', '#ffbeb4', '#c8e6e6', '#c5e0b4', '#526881', '#5b9bd5'],
                    xaxis: {
                        categories: data[0]?.api.data
                    },
                    yaxis: {
                        decimalsInFloat: 0,
                    },
                    zoom: {
                        enabled: true,
                        type: "x",
                        resetIcon: {
                            offsetX: -10,
                            offsetY: 0,
                            fillColor: "#fff",
                            strokeColor: "#37474F",
                        },
                        selection: {
                            background: "#90CAF9",
                            border: "#0D47A1",
                        },
                    },
                    dataLabels: {
                        enabled: false,
                        style: {
                            fontSize: '10px'
                        },
                        background: {
                            enabled: true,
                            foreColor: "#fff"
                        }
                    },
                    title: {
                        text: "Cow Carbon Emission Status"
                    }
                },
                series : data[0]?.apiList,
            })
        } else if (title === "farm_status") {
            let chartArr = [];
            let milk = data[0]?.apiList.filter((list) => list.name === "Milk")[0];
            let temperature = data[0]?.apiList.filter((list) => list.name === "Temperature")[0];
            let ruminantH = data[0]?.apiList.filter((list) => list.name === "RuminantH")[0];
            let sleepH = data[0]?.apiList.filter((list) => list.name === "SleepH")[0];
            let humidity = data[0]?.apiList.filter((list) => list.name === "Humidity")[0];
            let thi = data[0]?.apiList.filter((list) => list.name === "THI")[0];
            let activityH = data[0]?.apiList.filter((list) => list.name === "ActivityH")[0];

            chartArr.push({
                name: milk?.title,
                data: milk?.data
            });
            chartArr.push({
                name: temperature?.title,
                data: temperature?.data
            });
            chartArr.push({
                name: ruminantH?.title,
                data: ruminantH?.data
            });
            chartArr.push({
                name: sleepH?.title,
                data: sleepH?.data
            });
            chartArr.push({
                name: humidity?.title,
                data: humidity?.data
            });
            chartArr.push({
                name: thi?.title,
                data: thi?.data
            });
            chartArr.push({
                name: activityH?.title,
                data: activityH?.data
            });

            const filterArr = chartArr.filter((list) => list.name && list.data);

            data &&
            setState({
                type: "line",
                options: {
                    stroke: {
                        width: 2
                    },
                    colors: ['#beaad2', '#ffbe82', '#ffbeb4', '#c8e6e6', '#c5e0b4', '#526881', '#5b9bd5'],
                    xaxis: {
                        categories: data[0]?.api.data
                    },
                    yaxis: {
                        decimalsInFloat: 0,
                    },
                    dataLabels: {
                        enabled: false,
                        style: {
                            fontSize: '10px'
                        },
                        background: {
                            enabled: true,
                            foreColor: "#fff"
                        }
                    },
                },
                series : filterArr,
            })
        } else if (title === "profitability") {
            console.log('data', data);
            let chartArr = [];
            let milkPrice = data[0]?.apiList.filter((list) => list.name === "milkPrice")[0];
            let milkSalesPrice = data[0]?.apiList.filter((list) => list.name === "milkSalesPrice")[0];
            let realFeed = data[0]?.apiList.filter((list) => list.name === "realFeed")[0];
            let feedCost = data[0]?.apiList.filter((list) => list.name === "feedCost")[0];

            chartArr.push({
                name: milkPrice?.title,
                data: milkPrice?.data
            });
            chartArr.push({
                name: milkSalesPrice?.title,
                data: milkSalesPrice?.data
            });
            chartArr.push({
                name: realFeed?.title,
                data: realFeed?.data
            });
            chartArr.push({
                name: feedCost?.title,
                data: feedCost?.data
            });

            const filterArr = chartArr.filter((list) => list.name && list.data);

            data &&
            setState({
                type: "line",
                options: {
                    stroke: {
                        width: 2
                    },
                    colors: ['#beaad2', '#ffbe82', '#ffbeb4', '#c8e6e6', '#c5e0b4', '#526881', '#5b9bd5'],
                    xaxis: {
                        categories: data[0]?.api.data
                    },
                    yaxis: {
                        decimalsInFloat: 0,
                    },
                    dataLabels: {
                        enabled: false,
                        style: {
                            fontSize: '10px'
                        },
                        background: {
                            enabled: true,
                            foreColor: "#fff"
                        }
                    },
                },
                series : filterArr,
            })
        } else {
            let chartArr = [];
            let milk = data[0]?.apiList.filter((list) => list.name === "Milk")[0];
            let feed = data[0]?.apiList.filter((list) => list.name === "Feed")[0];
            let standardFeed = data[0]?.apiList.filter((list) => list.name === "standardFeed")[0];
            let realFeed = data[0]?.apiList.filter((list) => list.name === "realFeed")[0];
            let carbon = data[0]?.apiList.filter((list) => list.name === "carbon")[0];

            chartArr.push({
                name: milk?.title ? milk?.title : "Milk-Production(L)",
                data: milk?.data
            });
            chartArr.push({
                name: feed?.title ? feed?.title : "Feed",
                data: feed?.data
            });
            chartArr.push({
                name: standardFeed?.title,
                data: standardFeed?.data
            });
            chartArr.push({
                name: realFeed?.title,
                data: realFeed?.data
            });
            chartArr.push({
                name: carbon?.title,
                data: carbon?.data
            });

            const filterArr = chartArr.filter((list) => list.name && list.data);

            // 나머지 다
            data &&
            setState({
                type: "line",
                options: {
                    stroke: {
                        width: 2
                    },
                    colors: ['#beaad2', '#ffbe82', '#ffbeb4', '#c8e6e6', '#c5e0b4', '#526881', '#5b9bd5'],
                    xaxis: {
                        categories: data[0]?.api.data
                    },
                    yaxis: {
                        decimalsInFloat: 0,
                    },
                    dataLabels: {
                        enabled: false,
                        style: {
                            fontSize: '10px'
                        },
                        background: {
                            enabled: true,
                            foreColor: "#fff"
                        }
                    },
                },
                series : filterArr,
            })   
        }
    }, [data]);


    return (
        <div className="chart line">
            {
                state.series &&
                <Chart options={state.options} series={state.series} width="100%"/>   
            }
        </div>
    );
}

export default LineChart;