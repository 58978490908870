/* eslint-disable */
import { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// 레이아웃
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import FarmSelect from './components/layout/FarmSelect';
import ScrollToTop from './libs/ScrollToTop';
import TopButton from './components/layout/TopBtn';

// 로그인
import Login from './pages/Login';
// 회원가입
import Join from './pages/Join';
// 대시보드 - 메인
import Main from './pages/dashboard/main/Main';
import Customize from './pages/dashboard/main/Customize';
// 대시보드 - 경영상태
import Profitability from './pages/dashboard/profitability/Profitability';
import ProfitabilityGroup from './pages/dashboard/profitability/ProfitabilityGroup';
import ProfitabilityCowDetail from './pages/dashboard/profitability/ProfitabilityCowDetail';
import ProfitabilityCowList from './pages/dashboard/profitability/ProfitabilityCowList';
// 대시보드 - 생산성
import Productivity from './pages/dashboard/productivity/Productivity';
import ProductivityCowList from './pages/dashboard/productivity/ProductivityCowList';
import ProductivityCowDetail from './pages/dashboard/productivity/ProductivityCowDetail';
import ProductivityCowGroup from './pages/dashboard/productivity/ProductivityCowGroup';
// 대시보드 - 소의 건강
import HealthyCow from './pages/dashboard/healthy_cow/HealthyCow';
import HealthyCowList from './pages/dashboard/healthy_cow/HealthyCowList';
import HealthyCowGroup from './pages/dashboard/healthy_cow/HealthyCowGroup';
import HealthyCowDetail from './pages/dashboard/healthy_cow/HealthyCowDetail';
// 대시보드 - 탄소 현황
import CarbonStatus from './pages/dashboard/carbon_status/CarbonStatus';
import CarbonStatusCowList from './pages/dashboard/carbon_status/CarbonStatusCowList';
import CarbonStatusGroup from './pages/dashboard/carbon_status/CarbonStatusGroup';
import CarbonStatusDetail from './pages/dashboard/carbon_status/CarbonStatusCowDetail';

// 회원정보
import UserInfo from './pages/user_info/UserInfo';
import UserInfoEdit from './pages/user_info/UserInfoEdit';
// 농장관리
import FarmMng from './pages/farm_mng/FarmMng';
import FarmDetailMng from './pages/farm_mng/FarmDetailMng';
// 젖소관리
import CowMng from './pages/cow_mng/CowMng';
import CowDetailMng from './pages/cow_mng/CowDetailMng';
import SensorMapping from './pages/cow_mng/SensorMapping';
import GestationInformation from './pages/cow_mng/GestationInformation';
// 센서관리
import SensorMng from './pages/sensor_mng/SensorMng';
// 사료관리
import FeedMng from './pages/feed_mng/FeedMng';
import FeedDetailMng from './pages/feed_mng/FeedDetailMng';
// 서비스관리
import ServiceMng from './pages/service_mng/ServiceMng';
import PurchasingProduct from './pages/service_mng/PurchasingProduct';
// 포인트사용내역
import CreditUsageHistory from './pages/credit_usage_history/CreditUsageHistory';
// 고객센터/이용가이드
import ServiceCenter from './pages/service_center/ServiceCenter';
import ConsultationRequest from './pages/service_center/ConsultationRequest';
import MembershipWithdrawal from './pages/service_center/MembershipWithdrawal';
// 앱정보
import AppInfo from './pages/app_info/AppInfo';
// 약관
import Term from './pages/term/Term';
// 해외 로그인 차단
import OverseasLoginBlocker from './pages/OverseasLoginBlocker';
// 404 not found
import NotFound from './pages/NotFound';

// utils
import PrivateRoute from './libs/PrivateRoute';
import isLogin from './libs/isLogin';

// css
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './styles/custom.css';
import {IntlProvider} from "react-intl";
import en from "./libs/languages/en.json"
import ph from "./libs/languages/ph.json"



// const locale  = localStorage.getItem("locale") ?? "ko";

const locale = localStorage.getItem("locale") ?? "en";
const messages = {ph: ph, en: en }[locale];
function App() {
    const [tab, setTab] = useState("main");

    useEffect(() => {
        setFooterActive();
    }, []);

    // footer active
    function setFooterActive() {
		const pathname = window.location.pathname;
		
        if (pathname.indexOf("main") > -1) {
            setTab("main");
            return;
        } else if (pathname.indexOf("profitability") > -1) {
            setTab("profitability");
            return;
        } else if (pathname.indexOf("productivity") > -1) {
            setTab("productivity");
            return;
        } else if (pathname.indexOf("health_condition") > -1) {
            setTab("health_condition");
            return;
        } else if (pathname.indexOf("carbon_status") > -1) {
            setTab("carbon_status");
            return;
        } else {
            setTab("");
        }
    }

    return (
		<IntlProvider locale={locale} messages={messages}>
		<div className="app">
			<Header setFooterActive={setFooterActive} setTab={setTab} tab={tab} />

			<FarmSelect />

			<ScrollToTop />

			<Switch>
				<Route path="/login" component={Login} exact>
					{ isLogin() && <Redirect to={{ pathname: "/main", reload: true }} /> }
				</Route>
				<Route path="/join" component={Join} exact>
					{ isLogin() && <Redirect to={{ pathname: "/main", reload: true }} /> }
				</Route>
				<Route path="/term" component={Term} exact />

				<PrivateRoute path="/" component={Main} exact>
					<Redirect to="/main" />
				</PrivateRoute>
				<PrivateRoute path="/main" component={Main} exact />
				<PrivateRoute path="/main/customize" component={Customize} exact />
				
				<PrivateRoute path="/profitability" exact component={Profitability} />
				<PrivateRoute path="/profitability/cow_list" exact component={ProfitabilityCowList} />
				<PrivateRoute path="/profitability/cow_list/:id" component={ProfitabilityCowDetail} />
				<PrivateRoute path={["/profitability/cow_group", "/profitability/cow_group/:key"]} exact component={ProfitabilityGroup}/>

				<PrivateRoute path="/productivity" exact component={Productivity} />
				<PrivateRoute path="/productivity/cow_list" exact component={ProductivityCowList} />
				<PrivateRoute path="/productivity/cow_list/:id" component={ProductivityCowDetail} />
				<PrivateRoute path={["/productivity/cow_group", "/productivity/cow_group/:key"]} exact component={ProductivityCowGroup}/>

				<PrivateRoute path="/health_condition" exact component={HealthyCow} />
				<PrivateRoute path="/health_condition/cow_list" exact component={HealthyCowList} />
				<PrivateRoute path="/health_condition/cow_list/:id" component={HealthyCowDetail} />
				<PrivateRoute path={["/health_condition/cow_group", "/health_condition/cow_group/:key"]} exact component={HealthyCowGroup}/>

				<PrivateRoute path="/carbon_status" exact component={CarbonStatus} />
				<PrivateRoute path="/carbon_status/cow_list" exact component={CarbonStatusCowList} />
				<PrivateRoute path="/carbon_status/cow_list/:id" exact component={CarbonStatusDetail} />
				<PrivateRoute path={["/carbon_status/cow_group", "/carbon_status/cow_group/:key"]} exact component={CarbonStatusGroup}/>
				
				<PrivateRoute path="/user_info" exact component={UserInfo} />
				<PrivateRoute path="/user_info/edit" exact component={UserInfoEdit} />
				<PrivateRoute path="/farm_mng" exact component={FarmMng} />
				<PrivateRoute path="/farm_mng/:code" exact component={FarmDetailMng} />
				<PrivateRoute path="/cow_mng" exact component={CowMng} />
				<PrivateRoute path="/cow_mng/sensor_mapping" exact component={SensorMapping} />
				<PrivateRoute path="/cow_mng/:code" exact component={CowDetailMng} />
				<PrivateRoute path="/cow_mng/:code/gestation_information" exact component={GestationInformation} />
				<PrivateRoute path="/sensor_mng" exact component={SensorMng} />
				<PrivateRoute path="/feed_mng" exact component={FeedMng} />
				<PrivateRoute path="/feed_mng/detail" exact component={FeedDetailMng} />
				<PrivateRoute path="/service_mng" exact component={ServiceMng} />
				<PrivateRoute path="/service_mng/purchasing_product" exact component={PurchasingProduct} />
				<PrivateRoute path="/credit_usage_history" exact component={CreditUsageHistory} />
				<PrivateRoute path="/service_center" exact component={ServiceCenter} />
				<PrivateRoute path="/service_center/consultation_request" exact component={ConsultationRequest} />
				<PrivateRoute path="/service_center/membership_withdrawal" exact component={MembershipWithdrawal} />
				<PrivateRoute path="/app_info" exact component={AppInfo} />
				
				{/* 해외로그인 */}
				<Route path="/overseas_login_blocker" exact component={OverseasLoginBlocker} />

				{/* 404 */}
				<Route path="/*" exact component={NotFound} />
			</Switch>

			<TopButton />

			<Footer setFooterActive={setFooterActive} setTab={setTab} tab={tab} />
		</div>
		</IntlProvider>
    );
}

export default App;
