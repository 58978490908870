/* eslint-disable */
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Alert } from '../../components/Alert';
import { removeCookie } from '../../libs/auth/Cookie';
import Dompurify from 'dompurify';
import api from './../../libs/api';
// TODO-JK : join 번역 및 버튼 구상 의견 수렴
function Term() {
    const [checkedList, setCheckedList] = useState([]);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const location = useLocation();
    console.log('location', location.state);

    const termList2 = [
        {
            id: 1,
            title: "밀크티 이용약관 동의(필수)",
            content: `Milk-T 표준약관<br/><br/>

            제1장 총칙<br/><br/>
            
            제1조 (목적) 이 약관은 씽크포비엘(이하 “회사”라 합니다)이 모바일 기기를 통해 제공하는 Milk-T 서비스 및 이에 부수하는 네트워크, 웹사이트, 기타 서비스(이하 “서비스”라 합니다)의 이용에 대한 회사와 서비스 이용자의 권리ㆍ의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.<br/><br/>
            
            제2조 (용어의 정의) ① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br/>
             1. “회사”라 함은 모바일 기기를 통하여 서비스를 제공하는 사업자를 의미합니다.<br/>
             2. “회원”이란 이 약관에 따라 이용계약을 체결하고, 회사가 제공하는 서비스를 이용하는 자를 의미합니다.<br/>
             3. “모바일 기기”란 회사가 제공하는 Milk-T 서비스를 다운로드 받거나 설치하여 사용할 수 있는 기기로서, 휴대폰, 스마트폰, 휴대정보단말기(PDA), 태블릿 등을 의미합니다. <br/>
             4. “계정정보”란 회원의 이름, 농장 이름, 농장 주소 등 회원이 회사에 제공한 기본 정보와 농장 상세 정보(농장코드, 젖소 정보 등), 이용요금 결제 정보 등을 통칭합니다.<br/>
             5. “애플리케이션”이란 회사가 제공하는 서비스를 이용하기 위하여 모바일 기기를 통해 다운로드 받거나 설치하여 사용하는 프로그램 일체를 의미합니다.<br/>
             6. “Milk-T 서비스”라 함은 회사가 제공하는 서비스의 하나로서 회원이 모바일 기기에서 실행하는 Milk-T 서비스 및 이에 부수하는 모든 서비스를 의미합니다.<br/>
            ② 이 약관에서 사용하는 용어의 정의는 본 조 제1항에서 정하는 것을 제외하고는 관계법령 및 서비스별 정책에서 정하는 바에 의하며, 이에 정하지 아니한 것은 일반적인 상 관례에 따릅니다.<br/><br/>
            
            제3조 (회사정보 등의 제공) 회사는 다음 각 호의 사항을 회원이 알아보기 쉽도록 게임 서비스 내에 표시합니다. 다만, 개인정보처리방침과 약관은 회원이 연결화면을 통하여 볼 수 있도록 할 수 있습니다.<br/>
             1. 상호 및 회사명 <br/>
             2. 영업소 소재지 주소(회원의 불만을 처리할 수 있는 곳의 주소를 포함한다) <br/>
             3. 전화번호<br/>
             4. 개인정보처리방침 <br/>
             5. 서비스 이용약관 <br/>
            
            제4조 (약관의 효력 및 변경) ① 회사는 이 약관의 내용을 회원이 알 수 있도록 서비스 내 또는 그 연결화면에 게시합니다. 이 경우 이 약관의 내용 중 서비스 중단, 회원 탈퇴, 환급, 계약 해제․해지, 회사의 면책사항 등과 같은 중요한 내용은 굵은 글씨, 색채, 부호 등으로 명확하게 표시하거나 별도의 연결화면 등을 통하여 회원이 알아보기 쉽게 처리합니다.<br/>
            ② 회사가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정사유 등을 명시하여 최소한 그 적용일 7일 이전부터 서비스 내 또는 그 연결화면에 게시하여 회원에게 공지합니다. 다만, 변경된 내용이 회원에게 불리하거나 중대한 사항의 변경인 경우에는 그 적용일 30일 이전까지 본문과 같은 방법으로 공지하고 제27조 제1항의 방법으로 회원에게 통지합니다. 이 경우 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다.<br/>
            ③ 회사가 약관을 개정할 경우 개정약관 공지 후 개정약관의 적용에 대한 회원의 동의 여부를 확인합니다. 회사는 제2항의 공지 또는 통지를 할 경우 회원이 개정약관에 대해 동의 또는 거부의 의사표시를 하지 않으면 동의한 것으로 볼 수 있다는 내용도 함께 공지 또는 통지를 하며, 회원이 이 약관 시행일까지 거부의 의사표시를 하지 않는다면 개정약관에 동의한 것으로 볼 수 있습니다. 회원이 개정약관에 대해 동의하지 않는 경우 회사 또는 회원은 서비스 이용계약을 해지할 수 있습니다. <br/>
            ④ 회사는 회원이 회사와 이 약관의 내용에 관하여 질의 및 응답을 할 수 있도록 조치를 취합니다.<br/>
            ⑤ 회사는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」 등 관련 법령에 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.<br/><br/>
            
            제5조 (이용계약의 체결 및 적용) ① 이용계약은 회원이 되고자 하는 자(이하 “가입신청자”라 합니다.)가 이 약관의 내용에 대하여 동의를 한 다음 서비스 이용 신청을 하고, 회사가 그 신청에 대해서 승낙함으로써 체결됩니다. <br/>
            ② 회사는 가입신청자의 신청에 대하여 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호의 어느 하나에 해당하는 이용 신청에 대해서는 승낙을 거절할 수 있습니다.<br/>
             1. 이용신청서 내용을 허위로 기재하거나 이용신청 요건을 충족하지 못한 경우 <br/>
             2. 회사가 서비스를 제공하지 않은 국가에서 비정상적이거나 우회적인 방법을 통해 서비스를 이용하는 경우 <br/>
             3. 사회의 안녕과 질서 또는 미풍양속을 저해할 목적으로 신청한 경우 <br/>
             4. 부정한 용도로 서비스를 이용하고자 하는 경우 <br/>
             5. 영리를 추구할 목적으로 서비스를 이용하고자 하는 경우 <br/>
             6. 그 밖에 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우 <br/>
            ③ 회사는 다음 각 호의 어느 하나에 해당하는 경우 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다. <br/>
             1. 회사의 설비에 여유가 없거나, 특정 모바일 기기의 지원이 어렵거나, 기술적 장애가 있는 경우 <br/>
             2. 서비스 상의 장애 또는 서비스 이용요금, 결제수단의 장애가 발생한 경우 <br/>
             3. 그 밖의 각 호에 준하는 사유로서 이용신청의 승낙이 어렵다고 판단되는 경우<br/>
             
            제6조 (약관 외 준칙) 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「전자상거래 등에서의 소비자보호에 관한 법률」,「약관의 규제에 관한 법률」,「정보통신망이용촉진 및 정보보호 등에 관한 법률」 등 관련 법령 또는 상 관례에 따릅니다.<br/><br/>
            
            제7조 (운영정책) ① 약관을 적용하기 위하여 필요한 사항과 약관에서 구체적 범위를 정하여 위임한 사항을 서비스 운영정책(이하 “운영정책”이라 합니다)으로 정할 수 있습니다. <br/>
            ② 회사는 운영정책의 내용을 회원이 알 수 있도록 서비스 내 또는 그 연결화면에 게시합니다. <br/>
            ③ 운영정책을 개정하는 경우에는 제4조 제2항의 절차에 따릅니다. 다만, 운영정책 개정내용이 다음 각 호의 어느 하나에 해당하는 경우에는 제2항의 방법으로 사전에 공지합니다. <br/>
             1. 약관에서 구체적으로 범위를 정하여 위임한 사항을 개정하는 경우 <br/>
             2. 회원의 권리·의무와 관련 없는 사항을 개정하는 경우 <br/>
             3. 운영정책의 내용이 약관에서 정한 내용과 근본적으로 다르지 않고 회원이 예측할 수 있는 범위 내에서 운영정책을 개정하는 경우<br/><br/>
            
            제2장 개인정보 관리<br/><br/>
            
            제8조 (개인정보의 보호 및 사용) ① 회사는 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력하며, 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의 개인정보처리방침에 따릅니다. 다만, 회사가 제공하는 서비스 이외의 링크된 서비스에서는 회사의 개인정보처리방침이 적용되지 않습니다.<br/>
            ② 회사는 관련 법령에 의해 관련 국가기관 등의 요청이 있는 경우를 제외하고는 회원의 개인정보를 본인의 동의 없이 타인에게 제공하지 않습니다.<br/>
            ③ 회사는 회원의 귀책사유로 개인정보가 유출되어 발생한 피해에 대하여 책임을 지지 않습니다.<br/><br/><br/><br/>
            
            
            
            제3장 이용계약 당사자의 의무<br/><br/>
            
            제9조 (회사의 의무) ① 회사는 관련 법령, 이 약관에서 정하는 권리의 행사 및 의무의 이행을 신의에 따라 성실하게 준수합니다.<br/>
            ② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다. 회사는 이 약관 및 개인정보처리방침에서 정한 경우를 제외하고는 회원의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다. <br/>
            ③ 회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이 멸실․훼손된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 장애나 결함 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.<br/><br/>
            
            제10조 (회원의 의무) ① 회원은 회사에서 제공하는 서비스의 이용과 관련하여 다음 각 호에 해당하는 행위를 해서는 안 됩니다.<br/>
             1. 이용신청 또는 회원 정보 변경 시 허위사실을 기재하는 행위<br/>
             2. 회사가 제공하지 않는 서비스나 비정상적인 방법을 통해 사이버 자산(ID, 구매한 상품, 포인트 등)을 매매 또는 증여하거나, 이를 취득하여 이용하는 행위<br/>
             3. 회사의 직원이나 운영자를 가장하거나 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위, 타인으로 가장하거나 타인과의 관계를 허위로 명시하는 행위<br/>
             4. 타인의 신용카드⋅유/무선 전화⋅은행 계좌 등을 도용하여 유료 결제 서비스를 구매하는 행위, 다른 회원의 ID 및 비밀번호를 부정사용하는 행위<br/>
             5. 다른 회원의 개인정보를 무단으로 수집⋅저장⋅게시 또는 유포하는 행위<br/>
             6. 서비스를 무단으로 영리, 영업, 광고, 홍보, 정치활동, 선거운동 등 본래의 용도 이외의 용도로 이용하는 행위<br/>
             7. 회사의 서비스를 이용하여 얻은 정보를 무단으로 복제․유통․조장하거나 상업적으로 이용하는 행위, 알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위<br/>
             8. 타인을 기망하여 이득을 취하는 행위, 회사의 서비스의 이용과 관련하여 타인에게 피해를 입히는 행위<br/>
             9. 회사나 타인의 지적재산권 또는 초상권을 침해하는 행위, 타인의 명예를 훼손하거나 손해를 가하는 행위<br/>
             10. 법령에 의하여 전송 또는 게시가 금지된 정보(컴퓨터 프로그램)나 컴퓨터 소프트웨어⋅하드웨어 또는 전기통신장비의 정상적인 작동을 방해⋅파괴할 목적으로 고안된 바이러스⋅컴퓨터 코드⋅파일⋅프로그램 등을 고의로 전송⋅게시⋅유포 또는 사용하는 행위<br/>
             11. 회사로부터 특별한 권리를 부여 받지 않고 애플리케이션을 변경하거나, 애플리케이션에 다른 프로그램을 추가⋅삽입하거나, 서버를 해킹⋅역설계하거나, 소스 코드나 애플리케이션 데이터를 유출⋅변경하거나, 별도의 서버를 구축하거나, 웹사이트의 일부분을 임의로 변경⋅도용하여 회사를 사칭하는 행위<br/>
             12. 그 밖에 관련 법령에 위반되거나 선량한 풍속 기타 사회통념에 반하는 행위 <br/>
            ② 회원의 계정에 관한 관리 책임은 회원에게 있으며, 이를 타인이 이용하도록 하게 하여서는 안 됩니다.  <br/>
            ③ 회원의 부주의로 인하여 발생하는 손해에 대해 회사는 책임지지 않습니다<br/><br/><br/>
            
            
            
            제4장 서비스 이용 및 이용제한<br/><br/>
            
            제11조 (서비스의 제공) ① 회사는 제5조의 규정에 따라 이용계약이 완료된 회원에게 그 즉시 서비스를 이용할 수 있도록 합니다. 다만, 일부 서비스의 경우 회사의 필요에 따라 지정된 일자부터 서비스를 개시할 수 있습니다.<br/>
            ② 회사는 회원에게 서비스를 제공할 때 이 약관에 정하고 있는 서비스를 포함하여 기타 부가적인 서비스를 함께 제공할 수 있습니다.<br/>
            ③ 회사는 회원이 구매한 서비스의 범위에 따라 이용 가능한 서비스에 차등을 둘 수 있습니다.<br/><br/>
            
            제12조 (서비스의 이용) ① 회사는 다음 각 호의 경우에는 서비스의 전부 또는 일부를 일시 정지할 수 있습니다. 이 경우 회사는 사전에 그 정지의 사유와 기간을 애플리케이션 초기화면이나 서비스 공지사항 등에 공지합니다. 다만, 사전에 공지할 수 없는 부득이한 사정이 있는 경우 사후에 공지할 수 있습니다.<br/>
             1. 시스템 정기점검, 서버의 증설 및 교체, 네트워크의 불안정 등의 시스템 운영상 필요한 경우<br/>
             2. 정전, 서비스 설비의 장애, 서비스 이용폭주, 기간통신사업자의 설비 보수 또는 점검 등으로 인하여 정상적인 서비스 제공이 불가능한 경우<br/>
             3. 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태 등 회사가 통제할 수 없는 상황이 발생한 경우<br/>
            ③ 회사는 모바일 기기를 위한 전용 애플리케이션 또는 네트워크를 이용하여 서비스를 제공합니다. 회원은 애플리케이션을 다운로드하여 설치하거나 네트워크를 이용하여 무료 또는 유료로 서비스를 이용할 수 있습니다.<br/>
            ④ 유료 서비스의 경우에는 어플리케이션 또는 네트워크를 이용하는 서비스는 해당 페이지 상에 명시된 요금을 지급하여야 이용할 수 있습니다. 네트워크를 통해 애플리케이션을 다운로드하거나 서비스를 이용하는 경우에는 가입한 이동통신사에서 정한 별도의 요금이 발생할 수 있습니다.<br/>
            ⑤ 다운로드하여 설치한 애플리케이션 또는 네트워크를 통해 이용하는 서비스의 경우에는 모바일 기기 또는 이동통신사의 특성에 맞도록 제공됩니다. 모바일 기기의 변경․번호 변경 또는 해외 로밍의 경우에는 콘텐츠의 전부 또는 일부의 이용이 불가능할 수 있으며, 이 경우 회사는 책임을 지지 않습니다.<br/>
            ⑥ 다운로드하여 설치한 애플리케이션 또는 네트워크를 통해 이용하는 서비스의 경우에는 백그라운드 작업이 진행될 수 있습니다. 이 경우 모바일 기기 또는 이동통신사의 특성에 맞도록 추가요금이 발생할 수 있으며 이와 관련하여 회사는 책임을 지지 않습니다.<br/><br/>
            
            제13조 (서비스의 변경 및 중단) ① 회사는 원활한 서비스 제공을 위해 운영상 또는 기술상의 필요에 따라 서비스를 변경할 수 있으며, 변경 전에 해당 내용을 서비스 내에 공지합니다. <br/>다만, 버그․오류 등의 수정이나 긴급 업데이트 등 부득이하게 변경할 필요가 있는 경우 또는 중대한 변경에 해당하지 않는 경우에는 사후에 공지할 수 있습니다.<br/>
            ② 회사는 영업양도․분할․합병 등에 따른 영업의 폐지, 게임제공의 계약만료, 당해 서비스의 현저한 수익 악화 등 경영상의 중대한 사유로 인해 서비스를 지속하기 어려운 경우에는 서비스 전부를 중단할 수 있습니다. 이 경우 중단일자 30일 이전까지 중단일자․중단사유․보상조건 등을 애플리케이션 초기화면 또는 그 연결화면을 통해 공지하고 제27조 제1항의 방법으로 회원에게 통지합니다.<br/>
            ③ 제2항의 경우 회사는 사용하지 않았거나 사용기간이 남아 있는 포인트에 대해  제24조 제3항에 따라 환급합니다. <br/><br/>
            
            제14조 (정보의 수집 등) ① 회사는 CS 상담을 통해 회사와 회원간에 이루어지는 채팅 내용을 저장․보관할 수 있으며 이 정보는 회사만이 보유합니다. <br/>
            ② 회사 또는 제3자가 제1항에 따라 채팅 정보를 열람할 경우 회사는 사전에 열람의 사유 및 범위를 해당 회원에게 고지합니다. 다만, 제10조 제1항에 따른 금지행위의 조사․처리․확인 또는 그 행위로 인한 피해 구제와 관련하여 이 정보를 열람해야 할 경우에는 사후에 고지할 수 있습니다.<br/>
            ③ 회사는 서비스의 원활하고 안정적인 운영 및 서비스 품질의 개선을 위하여 회원의 개인정보를 제외한 회원의 모바일 기기 정보(설정, 농장 데이터, 사양, 운영체제, 버전 등)를 수집 ‧ 활용할 수 있습니다.<br/>
            ④ 회사는 서비스 개선 및 회원 대상 서비스 소개 등을 위한 목적으로 회원에게 추가정보를 요청할 수 있습니다. 이 요청에 대해 회원은 승낙하거나 거절할 수 있으며, 회사가 이 요청을 할 경우에는 회원이 이 요청을 거절할 수 있다는 뜻을 함께 고지합니다.<br/><br/>
            
            제15조 (광고의 제공) ① 회사는 서비스의 운영과 관련하여 서비스 내에 광고를 게재할 수 있습니다. 또한 수신에 동의한 회원에 한하여 전자우편, 문자서비스(LMS/SMS), 푸시메시지(Push Notification) 등의 방법으로 광고성 정보를 전송할 수 있습니다. 이 경우 회원은 언제든지 수신을 거절할 수 있으며, 회사는 회원의 수신 거절 시 광고성 정보를 발송하지 아니합니다. <br/>
            ② 회사가 제공하는 서비스 중의 배너 또는 링크 등을 통해 타인이 제공하는 광고나 서비스에 연결될 수 있습니다. <br/>
            ③ 제2항에 따라 타인이 제공하는 광고나 서비스에 연결될 경우 해당 영역에서 제공하는 서비스는 회사의 서비스 영역이 아니므로 회사가 신뢰성, 안정성 등을 보장하지 않으며, 그로 인한 회원의 손해에 대하여도 회사는 책임을 지지 않습니다. 다만, 회사가 고의 또는 중과실로 손해의 발생을 용이하게 하거나 손해 방지를 위한 조치를 취하지 아니한 경우에는 그러하지 아니합니다.<br/><br/>
            
            제16조 (저작권 등의 귀속) ① 회사가 제작한 서비스 내의 콘텐츠에 대한 저작권과 기타 지적재산권은 회사에 귀속합니다.<br/>
            ② 회원은 회사가 제공하는 서비스를 이용하여 얻은 정보 중에서 회사 또는 제공업체에 지적재산권이 귀속된 정보를 회사 또는 제공업체의 사전 동의 없이 복제⋅전송 등의 방법(편집, 공표, 공연, 배포, 방송, 2차적 저작물 작성 등을 포함합니다. 이하 같습니다)에 의하여 영리목적으로 이용하거나 타인에게 이용하게 하여서는 안 됩니다.<br/>
            ③ 회원은 어플리케이션 내에서 보여지거나 서비스와 관련하여 회원 또는 다른 이용자가 게임서비스를 통해 업로드 또는 전송하는 데이터 및 텍스트를 포함한 모든 자료 및 정보(이하 “이용자 콘텐츠”라 합니다.)에 대하여 회사가 다음과 같은 방법과 조건으로 이용하는 것을 허락합니다.<br/>
            1. 해당 이용자 콘텐츠를 이용, 편집 형식의 변경 및 기타 변형하는 것(공표, 복제, 공연, 전송, 배포, 방송, 2차적 저작물 작성 등 어떠한 형태로든 이용 가능하며, 이용기간과 지역에는 제한이 없음)<br/>
            2. 이용자 콘텐츠를 제작한 이용자의 사전 동의 없이 거래를 목적으로 이용자 콘텐츠를 판매, 대여, 양도 행위를 하지 않음<br/>
            ④ 서비스 내에서 보여지지 않고 서비스와 일체화되지 않은 회원의 이용자 콘텐츠(예컨대, 일반게시판 등에서의 게시물)에 대하여 회사는 회원의 명시적인 동의가 없이 이용하지 않으며, 회원은 언제든지 이러한 이용자 콘텐츠를 삭제할 수 있습니다.<br/>
            ⑤ 회사는 회원이 게시하거나 등록하는 서비스 내의 게시물에 대해 제10조 제1항에 따른 금지행위에 해당된다고 판단되는 경우에는 사전 통지 없이 이를 삭제 또는 이동하거나 그 등록을 거절할 수 있습니다. <br/>
            ⑥ 회사가 운영하는 게시판 등에 게시된 정보로 인하여 법률상 이익이 침해된 회원은 회사에 해당 정보의 삭제 또는 반박 내용의 게재를 요청할 수 있습니다. 이 경우 회사는 신속하게 필요한 조치를 취하고 이를 신청인에게 통지합니다.<br/>
            ⑦ 이 조는 회사가 서비스를 운영하는 동안 유효하며, 회원 탈퇴 후에도 지속적으로 적용됩니다.<br/><br/>
            
            제17조 (유료 서비스의 구매, 사용기간 및 이용) ① 서비스 내에서 회원이 구매한 유료 서비스는 해당 애플리케이션을 다운로드 받거나 설치한 모바일 기기에서만 이용할 수 있습니다.
            ② 회원이 구매한 유료 서비스의 이용기간은 구매 시 명시된 기간에 따릅니다. 다만, 제13조 제2항에 따라 서비스 중단이 이루어지는 경우 기간의 정함이 없는 유료 콘텐츠의 이용기간은 서비스 중단 공지 시 공지된 서비스의 중단일자까지로 합니다. 
            
            제18조 (회원에 대한 서비스 이용제한) ① 회원은 제10조에 따른 회원의 의무를 위반하는 행위를 하여서는 안 되며, 해당 행위를 하는 경우에 회사는 다음 각 호의 구분에 따른 회원의 서비스 이용제한, 관련 정보(글, 사진, 영상 등) 삭제 및 기타의 조치를 포함한 이용제한 조치를 할 수 있습니다. 이용제한 조치가 이루어지는 구체적인 사유 및 절차는 제19조 제1항에 따라 개별 운영정책에서 정합니다.<br/>
             1. 일부 권한 제한 : 일정 기간 서비스 이용 제한<br/>
             2. 계정 이용제한 : 일정기간 또는 영구히 회원 계정의 이용을 제한<br/>
             3. 회원 이용제한 : 일정기간 또는 영구히 회원의 서비스 이용을 제한<br/>
            ② 제1항의 이용제한이 정당한 경우에 회사는 이용제한으로 인하여 회원이 입은 손해를 배상하지 않습니다.<br/>
            ③ 회사는 다음 각 호의 사유에 대한 조사가 완료될 때까지 해당 계정의 서비스 이용을 정지할 수 있습니다.<br/>
             1. 계정이 해킹 또는 도용당했다는 정당한 신고가 접수된 경우<br/>
             2. 불법프로그램 사용자 또는 작업장 등 위법행위자로 의심되는 경우<br/>
             3. 그 밖에 각 호에 준하는 사유로 서비스 이용의 잠정조치가 필요한 경우<br/>
            ④ 제3항의 조사가 완료된 후, 유료 서비스의 경우에는 정지된 시간만큼 회원의 이용시간을 연장하거나 그에 상당하는 유료서비스 또는 포인트 등으로 보상합니다. 다만, 회원이 제3항 각 호의 사유에 해당하는 경우에는 그러하지 아니합니다.<br/><br/>
            
            제19조 (이용제한 조치의 사유와 절차) ① 회사는 제18조 제1항에 따른 이용제한 조치의 구체적인 사유 및 절차를 제10조 제1항에 따른 금지행위의 내용․정도․횟수․결과 등을 고려하여 운영정책으로 정합니다.<br/>
            ② 회사가 제18조 제1항에서 정한 이용제한 조치를 하는 경우에는 다음 각 호의 사항을 회원에게 사전 통지합니다. 다만, 긴급히 조치할 필요가 있는 경우에는 사후에 통지할 수 있습니다.<br/>
             1. 이용제한 조치의 사유<br/>
             2. 이용제한 조치의 유형 및 기간<br/>
             3. 이용제한 조치에 대한 이의신청 방법<br/><br/>
            
            제20조 (이용제한 조치에 대한 이의신청 절차) ① 회원이 회사의 이용제한 조치에 불복하고자 할 때에는 이 조치의 통지를 받은 날부터 14일 이내에 불복 이유를 기재한 이의 신청서를 서면, 전자우편 또는 이에 준하는 방법으로 회사에 제출하여야 합니다.<br/>
            ② 회사는 제1항의 이의신청서를 접수한 날부터 15일 이내에 불복 이유에 대하여 서면, 전자우편 또는 이에 준하는 방법으로 답변합니다. 다만, 회사는 이 기간 내에 답변이 어려운 경우에는 그 사유와 처리일정을 통지합니다.<br/>
            ③ 회사는 불복 이유가 타당한 경우에는 이에 따른 조치를 취합니다.<br/><br/>
            
            제5장 청약철회, 과오납금의 환급 및 이용계약의 해지<br/><br/>
            
            제21조 (대금결제) ① 유료 서비스에 대한 구매 대금의 부과와 납부는 원칙적으로 회사에서 정하는 정책이나 방법에 따릅니다. 또한 각 결제수단별 한도가 회사가 정하는 정책 또는 정부의 방침에 따라 부여되거나 조정될 수 있습니다.<br/>
            ② 유료 서비스의 구매대금을 외화로 결제하는 경우에는 환율‧수수료 등으로 인하여 실제 청구금액이 서비스의 상점 등에서 표시된 가격과 달라질 수 있습니다. <br/><br/>
            
            제22조 (청약 철회 등) ① 회사와 유료 서비스의 구매에 관한 계약을 체결한 회원은 구매계약일과 서비스 이용 가능일 중 늦은 날부터 7일 이내에 별도의 수수료‧위약금 등의 부담 없이 청약철회를 할 수 있습니다. <br/>
            ② 회원은 다음 각 호에 해당하는 경우에는 회사의 의사에 반하여 제1항에 따른 청약철회를 할 수 없습니다. 다만, 가분적 서비스로 구성된 구매계약의 경우에는 가분적 서비스 중 다음 각 호에 해당하지 아니하는 나머지 부분에 대하여는 그러하지 아니합니다.<br/>
             1. 구매 즉시 사용되거나 적용되는 유료 서비스<br/>
             2. 추가혜택이 제공되는 경우에 그 추가 혜택이 사용된 서비스<br/><br/>
            
            ③ 회사는 제2항 각 호의 규정에 따라 청약철회가 불가능한 서비스의 경우에는 그 사실을 회원이 쉽게 알 수 있는 곳에 명확하게 표시하고, 해당 서비스의 시험사용 상품을 제공(한시적 이용의 허용, 체험용 제공 등)하거나 이에 대한 제공이 곤란한 경우에는 서비스에 관한 정보 제공함으로써 회원의 청약철회의 권리행사가 방해받지 아니하도록 조치합니다. 만약 회사가 이러한 조치를 하지 아니한 경우에는 제2항 각 호의 청약철회 제한사유에도 불구하고 회원은 청약철회를 할 수 있습니다.<br/>
            ④ 회원은 제1항 및 제2항에도 불구하고 구매한 유료 서비스의 내용이 표시․광고의 내용과 다르거나 구매계약의 내용과 다르게 이행된 경우에 해당 서비스가 이용 가능하게 된 날부터 1개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회를 할 수 있습니다.<br/>
            ⑤ 회원이 청약철회를 할 경우 회사는 관리자 페이지를 통해 구매내역을 확인합니다. 또한 회사는 회원의 정당한 철회 사유를 확인하기 위해 회원에게서 제공받은 정보를 통해 회원에게 연락할 수 있으며, 추가적인 증빙을 요구할 수 있습니다.<br/>
            ⑥ 제1항부터 제4항까지의 규정에 따라 청약철회가 이루어질 경우 회사는 지체 없이 회원의 유료 콘텐츠를 회수하고 3영업일 이내에 대금을 환급합니다. 이 경우 회사가 환급을 지연한 때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률」 및 같은 법 시행령 제21조의3에서 정하는 이율을 곱하여 산정한 지연이자를 지급합니다.<br/>
            ⑦ 미성년자가 서비스 구매계약을 체결하는 경우, 회사는 법정대리인의 동의가 없으면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있다는 내용을 고지하며, 미성년자가 법정대리인의 동의 없이 구매계약을 체결한 때에는 미성년자 본인 또는 법정대리인은 회사에 그 계약을 취소할 수 있습니다. 다만, 미성년자가 법정대리인이 범위를 정하여 처분을 허락한 재산으로 서비스를 구매한 경우 또는 미성년자가 속임수로써 자기를 성년자로 믿게 하거나 법정대리인의 동의가 있는 것으로 믿게 한 경우에는 취소할 수 없습니다. <br/>
            ⑧ 구매계약의 당사자가 미성년자인지 여부는 결제가 진행된 모바일 기기, 결제 실행자 정보, 결제 수단 명의자 등을 근거로 판단합니다. 또한 회사는 정당한 취소인지를 확인하기 위해 미성년자 및 법정대리인임을 증명할 수 있는 서류의 제출을 요청할 수 있습니다.<br/><br/>
            
            제23조 (과오납금의 환급) ① 회사는 과오납금이 발생하는 경우 과오납금을 회원에게 환급합니다. 다만, 과오납금이 회사의 고의 또는 과실 없이 회원의 과실로 인하여 발생한 경우에는 그 환급에 소요되는 실제 비용은 합리적인 범위 내에서 회원이 부담합니다.<br/>
            ② 애플리케이션을 통한 결제는 회사가 제공하는 결제방식에 따르며, 결제 과정에서  과오납금이 발생하는 경우, 회사에게 환급을 요청하여야 합니다.<br/>
            ③ 애플리케이션의 다운로드 또는 네트워크 서비스의 이용으로 인해 발생한 통신요금(통화료, 데이터 통화료 등)은 환급 대상에서 제외될 수 있습니다.<br/>
            ④ 환급은 서비스를 이용하고 있는 모바일 기기의 운영체제 종류에 따라 회사의 환급정책에 따라 진행됩니다.<br/>
            ⑤ 회사는 과오납금의 환급을 처리하기 위해 회원에게서 제공받은 정보를 통해 회원에게 연락할 수 있으며, 필요한 정보의 제공을 요청할 수 있습니다. 회사는 회원으로부터 환급에 필요한 정보를 받은 날부터 3영업일 이내에 환급합니다.<br/><br/>
            
            제24조 (계약 해지 등) ① 회원은 언제든지 서비스 이용을 원하지 않는 경우 회원 탈퇴를 통해 이용계약을 해지할 수 있습니다. 회원 탈퇴를 할 경우, 회원의 젖소 및 농장 데이터 등 서비스 이용 정보는 회사에서 일정 기간 보관 후, 삭제 안내 후 삭제합니다. <br/>
            ② 회사는 회원이 이 약관 및 그에 따른 운영정책, 서비스 정책에서 금지하는 행위를 하는 등 본 계약을 유지할 수 없는 중대한 사유가 있는 경우에는 상당한 기간 전에 최고하고 기간을 정하여 서비스 이용을 중지하거나 이용계약을 해지할 수 있습니다.<br/>
            ③ 회사는 최근의 서비스 이용일부터 연속하여 1년 동안 회사의 서비스를 이용하지 않은 회원(이하 “휴면계정”이라 합니다)의 개인정보를 보호하기 위해 이용계약을 해지하고 회원의 개인정보 파기 등의 조치를 취할 수 있습니다. 회사는 회원이 1년간 잔여 포인트가 30만 포인트 이하 이며, 7일 이내 재충전 및 환불 의사가 없을 경우 휴면 계정으로 전환 할 수 있습니다. 또한, 회사는 휴면 계정 회원의 계약 해지, 개인정보 파기 등의 조치가 취해진다는 사실 및 파기될 개인정보 등을 회원에게 통지합니다.<br/><br/>
            
            제6장 손해배상 및 면책조항 등<br/><br/>
            
            제25조 (손해배상) ① 회사 또는 회원은 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해를 배상할 책임이 있습니다. 다만, 고의 또는 과실이 없는 경우에는 그러하지 아니 합니다<br/>
            ② 회사가 개별서비스 제공자와 제휴 계약을 맺고 회원에게 개별서비스를 제공하는 경우에 회원이 이 개별서비스 이용약관에 동의를 한 뒤 개별서비스 제공자의 고의 또는 과실로 인해 회원에게 손해가 발생한 경우에 그 손해에 대해서는 개별서비스 제공자가 책임을 집니다.<br/><br/>
            
            제26조 (회사의 면책) ① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관하여 책임을 지지 않습니다.<br/>
            ② 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 기타 이에 준하는 사유로 발생한 손해에 대하여 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.<br/>
            ③ 회사는 회원의 고의 또는 과실로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다. 다만, 회원에게 부득이하거나 정당한 사유가 있는 경우에는 그러하지 아니합니다.<br/>
            ④ 회원이 서비스와 관련하여 게재한 정보나 자료 등의 신뢰성, 정확성 등에 대하여 회사는 고의 또는 중대한 과실이 없는 한 책임을 지지 않습니다.<br/>
            ⑤ 회사는 회원이 다른 회원 또는 타인과 서비스를 매개로 발생한 거래나 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해에 대해 책임을 지지 않습니다.<br/>
            ⑥ 회사는 무료로 제공되는 서비스 이용과 관련하여 회원에게 발생한 손해에 대해서는 책임을 지지 않습니다. 그러나 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.<br/>
            ⑦ 회사는 회원이 서비스를 이용하여 기대하는 이익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다.<br/>
            ⑧ 회사는 회원의 유료 서비스, 포인트 등의 손실에 대하여 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.<br/>
            ⑨ 회사는 회원이 ID 및 비밀번호를 관리하지 않아 발생하는 제3자 결제에 대해 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.<br/>
            ⑩ 회원이 모바일 기기의 변경, 모바일 기기의 번호 변경, 운영체제(OS) 버전의 변경, 해외 로밍, 통신사 변경 등으로 인해 서비스 전부나 일부의 기능을 이용할 수 없는 경우 회사는 이에 대해 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.<br/><br/><br/>
            
            
            제27조 (회원에 대한 통지) ① 회사가 회원에게 통지를 하는 경우 회원의 전자우편주소, 문자메시지(LMS/SMS) 등으로 할 수 있습니다.<br/>
            ② 회사는 회원 전체에게 통지를 하는 경우 7일 이상 서비스 내 공지사항에 게시하거나 알림화면, 팝업화면 등을 제시함으로써 제1항의 통지에 갈음할 수 있습니다.<br/><br/>
            
            제28조 (재판권 및 준거법) 이 약관은 대한민국 법률에 따라 규율되고 해석됩니다. 회사와 회원 간에 발생한 분쟁으로 소송이 제기되는 경우에는 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.<br/><br/>
            
            제29조 (회원의 고충처리 및 분쟁해결)<br/>
            ① 회사는 회원의 편의를 고려하여 회원의 의견이나 불만을 제시하는 방법을 서비스 내 또는 그 연결화면에 안내합니다. 회사는 이러한 회원의 의견이나 불만을 처리하기 위한 전담인력을 운영합니다.<br/>
            ② 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내에 이를 신속하게 처리합니다. 다만, 처리에 장기간이 소요되는 경우에는 회원에게 장기간이 소요되는 사유와 처리일정을 서비스 내 공지하거나 제27조 제1항에 따라 통지합니다.<br/>
            ③ 회사와 회원 간에 분쟁이 발생하여 제3의 분쟁조정기관이 조정할 경우 회사는 이용제한 등 회원에게 조치한 사항을 성실히 증명하고, 조정기관의 조정에 따를 수 있습니다.`
        },
        {
            id: 2,
            title: "개인정보 수집 및 이용(필수)",
            content: `유첨2. ㈜씽크포비엘 <MILK-T 서비스> 개인정보 처리방침<br/><br/>

            ㈜씽크포비엘 MILK-T 서비스는 정보주체의 자유와 권리 보호를 위해 ｢개인정보 보호법｣ 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다.<br/><br/>
            
            이에 ｢개인정보 보호법｣ 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.<br/><br/>
            
            1. 개인정보의 처리 목적<br/>
            2. 개인정보의 처리 및 보유기간<br/>
            3. 개인정보파일 등록 현황 및 처리하는 개인정보의 항목<br/>
            4. 개인정보의 제3자 제공에 관한 사항<br/>
            5. 개인정보의 위탁에 관한 사항<br/>
            6. 개인정보의 파기 절차 및 파기방법<br/>
            7. 정보주체와 법정대리인의 권리/의무 및 그 행사방법에 관한 사항<br/>
            8. 개인정보의 안전성 확보 조치에 관한 사항<br/>
            9. 개인정보를 자동으로 수집하는 장치의 설치/운영 및 거부에 관한 사항<br/>
            10. 개인정보 보호책임자에 관한 사항<br/>
            11. 개인정보의 열람청구를 접수/처리하는 부서<br/>
            12. 정보주체의 권익침해에 대한 구제방법<br/>
            13. 개인정보 처리방침의 변경에 관한 사항<br/><br/><br/>
            
            
            1. 개인정보의 처리 목적<br/><br/>
            
            ① ㈜씽크포비엘 MILK-T 서비스는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.<br/><br/>
            
            가. 서비스 제공<br/>
            밀크티 서비스 이용을 위한 본인인증과 관련한 목적으로 개인정보를 처리합니다.<br/><br/>
            
            나. 민원처리<br/>
            개인정보 열람, 개인정보 정정·삭제, 개인정보 처리정지 요구, 개인정보 유출사고 신고 등 개인정보와 관련된 민원처리를 목적으로 개인정보를 처리합니다.<br/><br/>
            
            ② ㈜씽크포비엘 MILK-T 서비스는 개인정보 보호법 제32조에 따라 등록·공개하는 개인정보파일의 처리목적은 다음과 같습니다.<br/>
            1. 서비스 사용자 정보 : 운영근거 - 정보주체 동의 / 처리목적 - 서비스 이용에 대한 본인인증<br/>
            2. 개인정보 열람등요구 처리 사용자 정보: 운영근거 - 개인정보보호법 제35조-제39조 / 처리목적 - 개인정보 열람등요구 처리 행정업무의 참고 또는 사실 증명<br/>
            3. 유출사고 신고 처리 사용자 정보: 운영근거 - 개인정보보호법 제34조 신용정보의 이용 및 보호에 관한 법률 제39조 / 처리목적 - 유출사고 신고 처리 행정업무의 참고 또는 사실 증명<br/><br/><br/><br/>
            
            
            
            
            2. 개인정보의 처리 및 보유기간<br/><br/>
            
            ① ㈜씽크포비엘 MILK-T 서비스는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.<br/><br/>
            
            ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br/>
            1.  서비스 사용자 정보 : 운영근거 - 정보주체 동의 / 보유기한: 2년<br/>
            2. 개인정보 열람 등 요구 처리 사용자 정보: 운영 근거 - 개인정보보호법 제35조-제39조/ 보유기한: 3년<br/>
            3. 유출사고 신고 처리 사용자 정보 : 운영근거 - 개인정보보호법 제34조, 신용정보의 이용 및 보호에 관한 법률 제39조 / 보유기한: 3년<br/><br/><br/>
            
            
            3. 개인정보파일 등록 현황 및 처리하는 개인정보의 항목<br/><br/>
            
            ㈜씽크포비엘 MILK-T 서비스는 다음의 개인정보 항목을 처리하고 있습니다.<br/>
            1. 서비스 사용자 정보: 성명, 전화번호, 휴대폰번호, 업체명, 주소, 이메일, 아이디, 비번<br/>
            2. 개인정보 열람등 요구 처리 사용자 정보: 성명, 휴대폰번호(선택), 이메일(선택), 아이디, 가입확인정보<br/>
            3. 유출사고 신고 처리 사용자 정보: 성명, 기관명, 부서, 직위, 전화번호, 이메일<br/><br/><br/>
            
            
            4. 개인정보의 제3자 제공에 관한 사항<br/><br/>
            
            ① ㈜씽크포비엘 MILK-T 서비스는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.<br/><br/>
            
            ② ㈜씽크포비엘 MILK-T 서비스는 민원 신청인이 공공기관에 대하여 신청한 개인정보 열람, 정정·삭제, 처리정지민원을 처리하기 위하여 민원 신청인의 개인정보를 개인정보파일 보유기관에게 이송(제공)하고 있으며, <민원사무 처리에 관한 법률>에서 정하는 기간 동안 해당 기관에서 보유 및 이용합니다.<br/>
            - 이송(제공)하는 개인정보 항목 : 신청인 성명,휴대폰번호,이메일,아이디,가입확인정보<br/><br/><br/><br/>
            
            
            
            5. 개인정보의 위탁에 관한 사항<br/><br/>
            
            ㈜씽크포비엘 MILK-T 서비스는 개인정보 항목을 위탁처리하지 않습니다.<br/><br/><br/><br/>
            
            
            
            6. 개인정보의 파기 절차 및 파기방법<br/><br/>
            
            ① ㈜씽크포비엘 MILK-T 서비스는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 다만, 다른 법률에 따라 보존하여야하는 경우에는 그러하지 않습니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.<br/><br/>
            
            가. 파기절차<br/>
            불필요한 개인정보 및 개인정보파일은 개인정보책임자의 책임 하에 내부방침 절차에 따라 다음과 같이 처리하고 있습니다.<br/>
            - 개인정보의 파기<br/>
            보유기간이 경과한 개인정보는 종료일로부터 지체 없이 파기합니다.<br/>
            - 개인정보파일의 파기<br/>
            개인정보파일의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보파일이  불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 지체 없이 그 개인정보파일을 파기합니다.<br/><br/>
            
            나. 파기방법<br/>
            1) 전자적 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.<br/>
            2) 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br/><br/><br/><br/><br/>
            
            
            
            7. 정보주체와 법정대리인의 권리/의무 및 그 행사방법에 관한 사항<br/><br/>
            
            ① 정보주체(만 14세 미만인 경우에는 법정대리인을 말함)는 언제든지 ㈜씽크포비엘 MILK-T 서비스가 보유하고 있는 개인정보에 대하여 개인정보 열람․정정․삭제․처리정지 요구 등의 권리를 행사할 수 있습니다.<br/>
            ② 제1항에 따른 권리 행사는 <밀크티 (www.밀크티.co.kr)>의 “개인정보 열람․정정․삭제․처리정지 요구”를 통하여 하실 수 있으며, 이에 대해 지체 없이 조치하겠습니다.
            ☞ 개인정보 열람․정정․삭제․처리정지 요구 바로가기 (하이퍼링크)<br/>
            ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.<br/>
            ④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.<br/>
            ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br/>
            ⑥ 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.<br/><br/><br/><br/>
            
            
            
            8. 개인정보의 안전성 확보 조치에 관한 사항<br/><br/>
            
            ㈜씽크포비엘 MILK-T 서비스는 「개인정보보호법」 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다.<br/><br/>
            
            1. 내부관리계획의 수립 및 시행<br/>
            ㈜씽크포비엘 MILK-T 서비스의 내부관리계획 수립 및 시행은 ㈜씽크포비엘 MILK-T 서비스의 내부관리 지침을 준수하여 시행합니다.<br/>
            2. 개인정보 취급 담당자의 최소화 및 교육<br/>
            개인정보를 취급하는 담당자를 지정하고 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.<br/>
            3. 개인정보에 대한 접근 제한<br/>
            개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br/>
            4. 접속기록의 보관 및 위변조 방지<br/>
            개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 1년 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.<br/>
            5. 개인정보의 암호화<br/>
            이용자의 개인정보는 암호화 되어 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.<br/>
            6. 해킹 등에 대비한 기술적 대책<br/>
            ㈜씽크포비엘 MILK-T 서비스는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다. 또한 네트워크 트래픽의 통제(Monitoring)는 물론 불법적으로 정보를 변경하는 등의 시도를 탐지하고 있습니다.<br/>
            7. 비인가자에 대한 출입 통제<br/>
            개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.<br/><br/><br/><br/>
            
            
            
            9. 개인정보를 자동으로 수집하는 장치의 설치/운영 및 거부에 관한 사항<br/><br/>
            
            ① ㈜씽크포비엘 MILK-T 서비스는 정보주체의 이용정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.<br/>
            ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들이 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.<br/>
              가. 쿠키의 사용목적 : 이용자의 접속빈도나 방문시간 등을 파악하여 이용자에게 보다 편리한 서비스를 제공하기 위해 사용됩니다.<br/>
              나. 쿠키의 설치·운영 및 거부: 브라우저 옵션 설정을 통해 쿠키 허용, 쿠키 차단 등의 설정을 할 수 있습니다.<br/>
                - Internet Explorer : 웹브라우저 우측 상단의 도구 메뉴 > 인터넷 옵션 > 개인정보 > 설정 > 고급<br/>
                - Edge: 웹브라우저 우측 상단의 설정 메뉴 > 쿠키 및 사이트 권한 > 쿠키 및 사이트 데이터 관리 및 삭제<br/>
                - Chrome: 웹브라우저 우측 상단의 설정 메뉴 > 개인정보 및 보안 > 쿠키 및 기타 사이트 데이터<br/>
              다. 쿠키 저장을 거부 또는 차단할 경우 서비스 이용에 어려움이 발생할 수 있습니다.<br/><br/><br/>
            
            
            
            10. 개인정보 보호책임자에 관한 사항<br/><br/>
            
            ㈜씽크포비엘 MILK-T 서비스는 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호책임자 및 실무담당자를 지정하고 있습니다. (개인정보보호법 제31조 제1항에 따른 개인정보 보호책임자)<br/>
            1. 개인정보보호 책임자 SAQ/품질관리부 천선일(02-562-6545)<br/>
            2. 개인정보보호 담당자 SAQ/품질관리부 이진희(02-562-6545)<br/><br/>
            
            11. 개인정보의 열람청구를 접수/처리하는 부서<br/><br/>
            
            ① 정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. ㈜씽크포비엘 MILK-T 서비스는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.<br/>
            ▶ 개인정보 열람청구 접수·처리 부서<br/>
            부서명 : SAQ/품질관리부<br/>
            담당자 : 이진희<br/>
            연락처 : <전화번호 02-562-6545>, < 팩스번호 02-562-6549><br/><br/>
            
            ② 정보주체께서는 제1항의 열람청구 접수·처리부서 이외에, ☞ 개인정보 <br/>열람․정정․삭제․처리정지 요구 바로가기 (하이퍼링크)를 통하여서도 개인정보 열람청구를 하실 수 있습니다.<br/><br/><br/><br/>
            
            
            
            12. 정보주체의 권익침해에 대한 구제방법<br/><br/>
            
            정보주체는 아래의 기관에 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. 
            <아래의 기관은 ㈜씽크포비엘 MILK-T 서비스와는 별개의 기관으로서, MILK-T 서비스의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다><br/><br/>
            
            ▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)<br/>
            - 소관업무 : 개인정보 침해사실 신고, 상담 신청<br/>
            - 홈페이지 : privacy.kisa.or.kr<br/>
            - 전화 : (국번없이) 118<br/>
            - 주소 : 전라남도 나주시 진흥길 9 한국인터넷진흥원<br/><br/>
            
            ▶ 개인정보 분쟁조정위원회<br/>
            - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)<br/>
            - 홈페이지 : www.kopico.go.kr<br/>
            - 전화 : 1833-6972<br/>
            - 주소 : 서울특별시 종로구 세종대로 209 정부서울청사 12층<br/><br/>
            
            ▶ 대검찰청 사이버수사과:<br/>
            - (국번없이) 1301, privacy@spo.go.kr (www.spo.go.kr)<br/><br/>
            
            ▶ 경찰청 사이버수사국<br/>
            - (국번없이) 182 (ecrm.cyber.go.kr)<br/><br/>
            
            또한, 개인정보의 열람, 정정·삭제, 처리정지 등에 대한 정보주체자의 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익을 침해 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.<br/>
            ※ 중앙행정심판위원회: (국번없이) 110 (www.simpan.go.kr)<br/><br/>
            
            13. 개인정보 처리방침의 변경에 관한 사항<br/><br/>
            
            ① 본 방침은 2023년 01월 01일부터 시행됩니다.  끝.`,
        },
        {
            id: 3,
            title: "프로모션 정보 수신 동의(선택)",
            content: "밀크티에서 제공하는 이벤트/혜택 등 다양한 정보를 휴대전화(밀크티앱 알림 또는 문자), 이메일로 받아보실 수 있습니다.",
        },
    ];

    const termList = [
        {
            id: 1,
            title: `Collection and use of personal information <span class="purple">&#40;Required&#41;</>`,
            content: `Milk-T Standard Terms and Conditions <br /><br />

            Chapter I General ProvisionsArticle 1 (Purpose) The purpose of this Agreement is to 
            prescribe the rights, obligations, responsibilities, and other necessary matters of the 
            company and service users for the use of Milk-T services provided by Thinkforbl 
            (hereinafter referred to as "Company") and networks, websites, and other services 
            (hereinafter referred to as "Services")…`
        },
        {
            id: 2,
            title: `Collection and use of personal information <span class="purple">&#40;Required&#41;</>`,
            content: `&lt;MILK-T Service&gt; Personal Information Processing PolicyThinkforbl MILK-T Service 
            Co., Ltd. handles personal information legally and safely in compliance with the 
            provisions of the ｢Personal Information Protection Act and related laws to protect the 
            freedom and rights of information subjects.to protect the freedom and rights of 
            information subjects.Accordingly, in accordance with Article 30 of the ｢Personal 
            Information Protection Act, we establish and disclose personal information processing 
            policies as follows to guide information subjects on procedures and standards for 
            personal information processing and to handle related grievances quickly.`
        },
        {
            id: 3,
            title: `Agree to receive promotional information <span class="purple">&#40;Optional&#41;</>`,
            content: `You can receive various information such as events/benefits provided by 
            Milk-T by mobile phone (Milk-T app notification or text message), and email.
            (Milk-T app notification or text message), and email.`
        }
    ]

    // 전체 체크
    function checkedAll(checked) {
        if (checked) {
            const checkedIdArray = [];

            termList.forEach((list) => checkedIdArray.push(list.id));
            setCheckedList(checkedIdArray);
        } else {
            setCheckedList([]);
        }
    }

    // checked idx 배열 추가/삭제
    function checkedInd(checked, id) {
        if (checked) {
            setCheckedList(prev => [...prev, id]);
        } else {
            setCheckedList(checkedList.filter((el) => el !== id));
        }
    }

    console.log('checkedList', checkedList);

    // 약관 동의 TODO 회원가입 기능 사용여부 확인 후 수정 필요할듯
    async function confirm() {
        if (checkedList.indexOf(1) > -1 && checkedList.indexOf(2) > -1) {

            if (location.state === "reg") {
                // 회원가입시
                // localStorage.setItem("termAgree", true);
                window.location.href = "/join";
                
            } else {
                const response = await api.get('/users/agreeCheck');

                // 약관동의 성공
                if (response.data.code === "200") {
                    // 로그인 후 약관 동의
                    window.location.href = "/carbon_status";
                } else { // 실패
                    console.log('error msg: ', response.data.msg);
                }
            }
        } else {
            setAlertMsg('필수 항목에 동의하셔야 서비스 이용 가능합니다.');
            setAlertOpen(true);
        }
    }

    // 취소
    function cancel() {
        removeCookie("accessToken");
        removeCookie("refreshToken");
        window.location.href = "/login";
    }

    return (
        <div className=" terms">
            <h1 className="title mt-20 mx-10">CS/ Usage guide</h1>
            <div className="popup_header">
                <div className="d-flex">
                    <input 
                        type="checkbox" 
                        id="agree_all" 
                        onChange={(e) => { checkedAll(e.target.checked) }} 
                        checked={
                            checkedList.length === 0 
                            ? false 
                            : checkedList.length === termList.length ? true : false
                        }
                    />
                    <label htmlFor="agree_all" className="term_header">
                        {/* 밀크티 이용약관, 개인정보 수집 및 이용, <br />프로모션 정보 수신&#40;선택&#41;에 모두 동의합니다. */}
                        I agree to the terms and conditions of <br />
                        Milk-T, the collection and use of personal information, <br />
                        and the receipt of promotional information <span className="purple">&#40;Optional&#41;</span>
                    </label>
                </div>
            </div>
            <div className="popup_body">
                {
                    termList.map((list, i) => {
                        return (
                            <div key={list.id} className={`term0${list.id}`}>
                                <input 
                                    type="checkbox" 
                                    id={`term0${list.id}`} 
                                    onChange={(e) => { checkedInd(e.target.checked, list.id) }}
                                    checked={checkedList.includes(list.id) ? true : false}
                                />
                                <label 
                                    htmlFor={`term0${list.id}`} 
                                    dangerouslySetInnerHTML={ {__html: Dompurify.sanitize(list.title)}}
                                    className="term_header"
                                >
                                </label>
                                <div 
                                    className={`term_wrap ${list.id === 3 ? "last" : ""}`} 
                                    dangerouslySetInnerHTML={ {__html: Dompurify.sanitize(list.content)}}
                                ></div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="popup_footer content_footer d-flex">
                <a className="button cancel" onClick={cancel}>Cancel</a>
                <a className="button ml-auto" onClick={confirm}>Okay</a>
            </div>

            <ul className="terms_list t-center">
                {/* TODO 약관 페이지 나오면 링크 걸어야함 */}
                <li>
                    <Link to="">Terms and Conditions of Service</Link>
                </li>
                <li>
                    <Link to="">Personal information processing policy</Link>
                </li>
                <li>
                    <Link to="">Limitations of Liability and Legal Notice</Link>
                </li>
                <li>
                    <Link to="">Member information Customer Service</Link>
                </li>
            </ul>

            <div className="login_footer">
                <p className="footer_copy">
                    <span>
                        <img src="/assets/images/common/thinkfarm_logo.png" alt="thinkfarm logo" />
                        ThinkFarm Copyright &copy; ThinkforBL. Inc. All rights Reserved. Provacy Policy
                    </span>
                </p>
            </div>

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    )
}

export default Term;