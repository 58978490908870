/* eslint-disable */
import { useEffect, useState, useMemo } from 'react';

import api from './../libs/api';
import axios from 'axios';

import { setCookie } from '../libs/auth/Cookie';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Alert } from '../components/Alert';
import { getDateTime } from '../libs/getDate';
import getMessage from "../libs/getMessage";
// TODO-JK : 의견 확인 후 번역 작업
function Login() {
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [overseasLoginInfo, setOverseasLoginInfo] = useState({});

    const history = useHistory();
    const location = useLocation();

    // email 저장시 기본 값 세팅
    const params = {
        email: localStorage.getItem("email"),
        saveAccount: localStorage.getItem("email") ? true : false,
    }
    const { register, reset, handleSubmit, watch, formState: { errors }, setFocus } = useForm({
        defaultValues: useMemo(() => {
            return params;
        }, [])
    });

    const isMobile = /Mobi/i.test(window.navigator.userAgent); // 접속기기 모바일인지 확인

    // input focus
    useEffect(() => {
        if (!watch("email")) {
            setFocus("email");
        } else {
            setFocus("password");
        }
    }, [setFocus]);
    
    useEffect(() => {
        reset(params);
        getIP();
    }, []);

    // 접속 IP 확인
    async function getIP() {
        const response = await axios.get('https://geolocation-db.com/json/');
        // console.log(response);

        if (response.status === 200) {
            setOverseasLoginInfo(response.data);
        } else {
            console.log('error msg: ', response.data.msg);
        }
    }

    // console.log('overseasLoginInfo', overseasLoginInfo);

    // 로그인
    async function login(data) {
        console.log(data);
        let params = {
            email: data.email,
            password: data.password,
        }
        const response = await api.post('/login', params);

        // 로그인 성공
        if (response.data.code === "200") {
            // 계정정보 저장
            if (data.saveAccount) { 
                localStorage.setItem("email", data.email);
            } else {
                localStorage.removeItem("email");
            }

            if (watch("autoLogin")) { 
                // 자동로그인 (쿠키 만료 기간 30일 뒤)
                setCookie("refreshToken", response.data.data.refreshToken, { path: "/", maxAge: 2592000 });
            } else { 
                // 일반로그인 (쿠키 만료 기간 1일 뒤)
                setCookie("refreshToken", response.data.data.refreshToken, { path: "/", maxAge: 86400 }); 
            }

            // accessToken 만료 기간 1일 뒤
            setCookie("accessToken", response.data.data.token, { path: "/", maxAge: 86400 });
            localStorage.setItem("expiresAt", response.data.data.tokenExpired);
            localStorage.setItem("farmCode", response.data.data.loginUser.lastFarmCode);
            localStorage.setItem("userInfo", JSON.stringify(response.data.data.loginUser));

            // 접속 국가가 한국이 아닐 경우
            if (overseasLoginInfo.country_code !== "KR") {
                let overseasInfo = {
                    date: getDateTime(new Date()),
                    connectionIp: overseasLoginInfo.IPv4,
                    country: overseasLoginInfo.country_name + " (" + overseasLoginInfo.city + ")",
                    connectionInfo: isMobile ? "Mobile" : "PC"
                };

                history.push({
                    pathname: "/overseas_login_blocker",
                    state: overseasInfo,
                });

            } else { // 접속 국가가 한국일 경우

                // 로그인시 약관동의 Y면 메인, 아닐시 약관동의 페이지
                if (response.data.data.loginUser.agreeYn === "Y") {
                    // window.location.href="/carbon_status";
                    window.location.href="/";

                } else if (location.termAgree) {
                    // 회원가입 후 로그인시 약관 동의 전송
                    const termAgreeResponse = await api.get(
                        '/users/agreeCheck', 
                        {
                            headers: { Authorization: `Bearer ${response.data.data.token}` }
                        }
                    );
                    console.log('termAgreeResponse', termAgreeResponse);
    
                    // 약관동의 성공
                    if (termAgreeResponse.data.code === "200") {
                        // window.location.href = "/carbon_status";
                        window.location.href = "/";
                    } else { // 실패
                        console.log('error msg: ', termAgreeResponse.data.msg);
                    }

                } else {
                    // 약관 동의 페이지로 이동
                    window.location.href = "/term";
                }
            }
        } else { // 실패
            setErrorMsg(response.data.msg);
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            return;
        }
    }

    // 이메일 유효성 정규식
    const emailRegex = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    return (
        <form 
            name="login"
            onSubmit={handleSubmit((data) => login(data))}
        >
            <div className="login_form_header t-center">
                <img src="/assets/images/common/thinkforbl_logo.png" alt="logo" />
            </div>
            
            <div className="input_wrap">
                <div className="email">
                    <div className="d-flex">
                        <label htmlFor="email">E-Mail</label>
                        <input 
                            type="text" 
                            id="email" 
                            name="email" 
                            placeholder="Please enter your e-mail address."
                                {...register("email", {
                                required: "This field is required",
                                pattern: {
                                    value: emailRegex,
                                    message: "Please enter a valid email address."
                                },
                            })}
                        />
                    </div>
                    {errors.email && <span className="err_msg">{errors.email.message}</span>}
                </div>

                <div className="password">
                    <div className="d-flex">
                        <label htmlFor="password">Password</label>
                        <input 
                            type="password" 
                            id="password" 
                            name="password" 
                            placeholder="Please enter your password."
                            {...register("password", { required: true })}
                        />
                    </div>
                    {errorMsg !== "" && <span className="err_msg">{errorMsg}</span>}
                    {errors.password && <span className="err_msg" style={{ width: "100%", background: "#fff", textAlign: "right" }}>This field is required</span>}
                </div>

                <div className="d-flex s-ard">
                    <div className="">
                        <input 
                            type="checkbox" 
                            className="mr-5" 
                            id="saveAccount" 
                            name="saveAccount" 
                            {...register("saveAccount")}
                        />
                        <label htmlFor="saveAccount">Save Account information</label>
                    </div>
                    <div className="">
                        <input 
                            type="checkbox" 
                            className="mr-5" 
                            id="autoLogin" 
                            name="autoLogin" 
                            {...register("autoLogin")}
                        />
                        <label htmlFor="autoLogin">Automatic Login</label>
                    </div>
                </div>
            </div>

            <div className="content_footer d-flex s-ard">
                <Link 
                    to={{
                        pathname: "/term",
                        state: "reg"
                    }} 
                    className="button "
                >
                    Join
                </Link>
                <button className="button" type="submit">Login</button>
            </div>

            <div className="login_footer">
                <p className="footer_copy">
                    <span>
                        <img src="/assets/images/common/thinkfarm_logo.png" alt="thinkfarm logo" />
                        ThinkFarm Copyright &copy; ThinkforBL. Inc. All rights Reserved. Provacy Policy
                    </span>
                </p>
            </div>

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </form>
    );
}

export default Login;