/* eslint-disable */
import Chart from 'react-apexcharts'
import { useState, useEffect } from 'react';

function LineColumnChart({ data }) {
    const [state, setState] = useState({
        series: [
            {
                name: '',
                type: 'column',
                data: []
            }, 
            {
                name: '',
                type: 'column',
                data: []
            }, 
            {
                name: '',
                type: 'line',
                data: []
            },
            {
                name: '',
                type: 'line',
                data: []
            },
            {
                name: '',
                type: 'line',
                data: []
            }
        ],
        options: {
            colors: ['#5098bc', '#5b9bd5', '#94b7df', '#bcd0ea', '#cecccc'],
            chart: {
                height: 350,
                type: 'line',
            },
            stroke: {
                width: [0, 0, 2, 2, 2]
            },
            title: {},
            dataLabels: {},
            labels: [],
            xaxis: {},
            yaxis: []
        }
    });

    const date = data[0]?.api;
    const milk = data[0]?.apiList.filter((list) => list.name === "Milk")[0];
    const feed = data[0]?.apiList.filter((list) => list.name === "Feed")[0];
    const fce = data[0]?.apiList.filter((list) => list.name === "FCE")[0];
    const standardFce = data[0]?.apiList.filter((list) => list.name === "StandardFCE")[0];
    const standardMilk = data[0]?.apiList.filter((list) => list.name === "StandardMilk")[0];

    useEffect(() => {
        data.length > 0 &&
        setState({
            series: [
                {
                    name: milk?.title,
                    type: 'column',
                    data: milk?.data
                }, 
                {
                    name: feed?.title,
                    type: 'column',
                    data: feed?.data
                }, 
                {
                    name: fce?.title,
                    type: 'line',
                    data: fce?.data
                },
                {
                    name: standardFce?.title,
                    type: 'line',
                    data: standardFce?.data
                },
                {
                    name: standardMilk?.title,
                    type: 'line',
                    data: standardMilk?.data
                },
            ],
            options: {
                colors: ['#5098bc', '#5b9bd5', '#94b7df', '#bcd0ea', '#cecccc'],
                chart: {
                    height: 350,
                    type: 'line',
                },
                stroke: {
                    width: [0, 0, 2, 2, 2]
                },
                title: {},
                dataLabels: {},
                labels: date?.data,
                xaxis: {},
                yaxis: [
                    {
                        seriesName: milk?.name,
                        // axisTicks: {
                        //     show: true
                        // },
                        // axisBorder: {
                        //     show: true,
                        // },
                        title: {
                            text: "(L/kg)"
                        }
                    },
                    {
                        seriesName: milk?.name,
                        show: false
                    },
                    {
                        opposite: true,
                        seriesName: fce?.name,
                        // axisTicks: {
                        //     show: true
                        // },
                        // axisBorder: {
                        //     show: true,
                        // },
                        title: {
                            text: "(%)"
                        }
                    },
                    {
                        seriesName: fce?.name,
                        show: false
                    },
                    {
                        seriesName: fce?.name,
                        show: false
                    },
                ]
            }
        })
    }, [data]);

    return (
        <div className="chart line">
            <Chart options={state.options} series={state.series} type="line" width="100%"/>
        </div>
    );
}

export default LineColumnChart;

