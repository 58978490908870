/* eslint-disable */
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, Link } from 'react-router-dom';

import api from '../../libs/api';

import { Alert, AlertBack } from '../../components/Alert';


function UserInfoEdit() {
    const [userInfo, setUserInfo] = useState([]);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertBackOpen, setAlertBackOpen] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");

    useEffect(() => {
        getUserInfo();
    }, []);

    async function getUserInfo() {
        const response = await api.get('/users/view');
        
        // 성공
        if (response.data.code === "200") {
            setUserInfo(response.data.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    console.log('userInfo', userInfo);
    
    const params = {
        name: userInfo?.name,
        phoneNumber: userInfo?.phoneNumber,
        memberId: userInfo?.memberId,
    }
    const { watch, register, reset, handleSubmit, formState: { errors }, getValues } = useForm({
        defaultValues: useMemo(() => {
            return params;
        }, [userInfo])
    });
    const urlParams = useParams();

    useEffect(() => {
        reset(params);
    }, [userInfo]);

    // 수정/등록 서버 전송
    async function saveUserInfo(params) {
        console.log(params);
        const response = await api.post("/users/update", params);

        // 성공
        if (response.data.code === "200") {
            console.log(response);
            setAlertMsg(urlParams.code === "reg" ? "등록이 완료되었습니다." : "수정이 완료되었습니다.");
            setAlertBackOpen(true);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    return (
        <div className="container">
            <form 
                name="user_info" 
                onSubmit={handleSubmit((data) => saveUserInfo(data))}
            >
                <h1 className="title">User information</h1>
                <div className="con">
                    <div className="content_header d-flex border-b mb-10">
                        <span className="icon_wrap not_bg">
                            <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                        </span>
                        <span className="div_title mr-auto">
                            Change user information
                        </span>
                    </div>
                    <div className="content_body">
                        <div className="w100 mb-10">
                            <div className="d-flex">
                                <label htmlFor="name">User name</label>
                                {errors.name && <span className="err_msg">This field is required</span>} 
                            </div>
                            <div className="input_wrap">
                                <input 
                                    type="text" 
                                    id="name" 
                                    name="name" 
                                    {...register("name", { required: true })}
                                />
                            </div>
                        </div>
                        <div className="w100 mb-10">
                            <div className="d-flex">
                                <label htmlFor="pass1">Password</label>
                                {errors.pass1 && <span className="err_msg">This field is required</span>} 
                            </div>
                            <div className="input_wrap">
                                <input 
                                    type="password" 
                                    id="pass1" 
                                    name="pass1" 
                                    {...register("pass1", { required: true })}
                                />
                            </div>
                        </div>
                        <div className="w100 mb-10">
                            <div className="d-flex">
                                <label htmlFor="pass2">Password Confirmation</label>
                                {errors.pass2 && <span className="err_msg">{errors.pass2.message}</span>} 
                            </div>
                            <div className="input_wrap">
                                <input 
                                    type="password" 
                                    id="pass2" 
                                    name="pass2" 
                                    {...register("pass2", { 
                                        required: "This field is required",
                                        validate: (val) => {
                                            if (watch('pass1') !== val) {
                                                return "Password incorrect";
                                            }
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className="w100 mb-10">
                            <div className="d-flex">
                                <label htmlFor="phone">Phone</label>
                                {errors.phoneNumber && <span className="err_msg">This field is required</span>} 
                            </div>
                            <div className="input_wrap">
                                <input 
                                    type="text" 
                                    id="phone" 
                                    name="phoneNumber" 
                                    {...register("phoneNumber", { required: true })}
                                />
                            </div>
                        </div>
                        <div className="w100 mb-20">
                            <div className="d-flex">
                                <label htmlFor="farmName">Email</label>
                                {errors.memberId && <span className="err_msg">This field is required</span>} 
                            </div>
                            <div className="input_wrap">
                                <input 
                                    type="text" 
                                    id="memberId" 
                                    name="memberId" 
                                    readOnly
                                    {...register("memberId", { required: true })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="content_footer d-flex s-btw">
                        <Link to="/user_info" className="button cancel">Cancel</Link>
                        <button type="submit" className="button">
                            {urlParams.code === "reg" ? "Registration" : "Change information"}
                        </button>
                    </div>
                </div>
            </form>

            {
                alertBackOpen && <AlertBack alertMsg={alertMsg} setAlertOpen={setAlertBackOpen} />
            }
            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    );
}

export default UserInfoEdit;