/* eslint-disable */
import axios from 'axios';
import { getCookie } from './auth/Cookie';
import { refresh, refreshErrorHandle } from './auth/Refresh';

const token = getCookie("accessToken");
const farmCode = localStorage.getItem("farmCode");

let apiHeader = {};

if (window.location.pathname === "/login") {
    apiHeader = {
        Authorization: `Bearer ${token}`,
    }
} else {
    apiHeader = {
        Authorization: `Bearer ${token}`,
        lastFarmCode: farmCode ? farmCode : ""
    }
}

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: apiHeader
});

api.interceptors.request.use(refresh, refreshErrorHandle);

export default api;