/* eslint-disable */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ko } from 'date-fns/esm/locale';
import DateTermBtn from '../../../components/DateTermBtn';
import api from './../../../libs/api';
import LineChart from './../../../components/charts/LineChart';
import { getDate, calculateDate } from './../../../libs/getDate';
import LineAreaChart from './../../../components/charts/LineAreaChart';

import { useRecoilState } from "recoil";
import { farmCode } from './../../../store/store';
import { Alert } from '../../../components/Alert';
import getMessage from "../../../libs/getMessage";
import {locale} from "moment";
import CustomDatePicker from "../../../libs/CustomDatePicker";

function FarmScore({ current, setAlertMsg, setAlertOpen }) {
    const [startDate, setStartDate] = useState(calculateDate(-30));
    const [endDate, setEndDate] = useState(calculateDate(-1));
    const [dateTerm, setDateTerm] = useState("day");
    const [farmScoreData, setFarmScoreData] = useState([]);
    const locale = localStorage.getItem("locale");

    useEffect(() => {
        getFarmScore();
    }, [startDate, endDate, current]);

    async function getFarmScore() {
        const params = {
            farmCode : current,
            startDate : getDate(startDate),
            endDate : getDate(endDate)
        }

        const response = await api.post('/carbon/carbonScore', params);
        
        // 성공
        if (response.data.code === "200") {
            setFarmScoreData(response.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    } 

    console.log('farmScoreData', farmScoreData);

    return (
        <div className="farm_score_div content_wrap">
            <div className="score_wrap d-flex">
                <img src="/assets/images/common/icon_carbon.png" alt="" />
                <div className="title">
                    <span className="sub">{getMessage("Common.farm")}</span>
                    <h2 className="score" style={{fontSize: localStorage.getItem('locale') === 'ph' ? '23px':'24px' }}>{getMessage("Carbonstatus.carbonreductionscore")}{farmScoreData.farmScore} &#40; Top {farmScoreData.topPer}% &#41;</h2>

                </div>
            </div>

            <div className="toolbar">
                <Link to="/carbon_status/cow_list" className="round_btn">{getMessage("Common.viewcow")}</Link>
            </div>

            <div className="farm_score main_content">
                <div className="content_header d-flex">
                    <span className="icon_wrap">
                        <img src="/assets/images/common/icon_home.png" alt="" />
                    </span>
                    <span className="div_title">{getMessage("Common.farmscore")}</span>
                    <div className="ml-auto">
                        {/* <DateTermBtn dateTerm={dateTerm} setDateTerm={setDateTerm} /> */}
                    </div>
                    <div className="datepicker_wrap from_to">
                        <img src="/assets/images/common/icon_calendar.png" className="calendar" alt="" />
                        <CustomDatePicker
                            selectedDate={startDate}
                            endDate={endDate}
                            onStartDateChange={(date) => setStartDate(date)}
                            onEndDateChange={(date) => setEndDate(date)}
                            locale={locale}
                        />
                        <img src="/assets/images/common/icon_calendar_arrow.png" className="arrow" alt="" />
                    </div>
                </div>
                <div className="graph">
                    {
                        farmScoreData.datas &&
                        // <LineChart data={farmScoreData.datas} />
                        <LineAreaChart data={farmScoreData.datas} />
                    }
                </div>
            </div>
        </div>
    )
}

function CarbonStatusComp({ current, setAlertMsg, setAlertOpen }) {
    const [startDate, setStartDate] = useState(calculateDate(-30));
    const [endDate, setEndDate] = useState(calculateDate(-1));
    const [dateTerm, setDateTerm] = useState("day");
    const [carbonStatusData, setCarbonStatusData] = useState([]);
    const locale = localStorage.getItem("locale");

    useEffect(() => {
        getFarmStatus();
    }, [startDate, endDate, current]);

    async function getFarmStatus() {
        const params = {
            farmCode : current,
            startDate : getDate(startDate),
            endDate : getDate(endDate)
        }
        const response = await api.post('/carbon/carbonStatus', params);
        
        // 성공
        if (response.data.code === "200") {
            setCarbonStatusData(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    console.log('carbonStatusData', carbonStatusData);

    return (
        <div className="main_content">
            <div className="content_header d-flex">
                <span className="icon_wrap">
                    <img src="/assets/images/common/icon_carbon_status.png" alt="" />
                </span>
                <span className="div_title">{getMessage("Carbonstatus.carbonstatus")}</span>
                <div className="ml-auto">
                    {/* <DateTermBtn dateTerm={dateTerm} setDateTerm={setDateTerm} /> */}
                </div>
                <div className="datepicker_wrap from_to">
                    <img src="/assets/images/common/icon_calendar.png" className="calendar" alt="" />
                    <CustomDatePicker
                        selectedDate={startDate}
                        endDate={endDate}
                        onStartDateChange={(date) => setStartDate(date)}
                        onEndDateChange={(date) => setEndDate(date)}
                        locale={locale}
                    />
                    <img src="/assets/images/common/icon_calendar_arrow.png" className="arrow" alt="" />
                </div>
            </div>
            <div className="graph">
                {
                    carbonStatusData &&
                    <LineChart data={carbonStatusData} />
                }
            </div>
        </div>
    )
}

function OverfeedingLevel({ current, setAlertMsg, setAlertOpen }) {
    const [overfeedingLevel, setOverfeedingLevel] = useState([]);

    useEffect(() => {
        getOverfeedingLevel();
    }, [current]);

    async function getOverfeedingLevel() {
        const params = {
            farmCode : current,
            inputDate : getDate(calculateDate(-1))
        }
        const response = await api.post('/carbon/overfeedCows', params);
        
        // 성공
        if (response.data.code === "200") {
            setOverfeedingLevel(response.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    console.log('overfeedingLevel', overfeedingLevel);

    return (
        <div className="main_content abnormal_dairy_cow ">
            <div className="content_header d-flex">
                <span className="icon_wrap">
                    <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                </span>
                <span className="div_title">Overfeeding Level</span>
            </div>
            <div className="score_wrap d-flex">
                <img src="/assets/images/common/cow_zoom.png" alt="" />
                <div className="title">
                    <span className="sub">{getMessage("Main.total")}{overfeedingLevel.total} {getMessage("Main.cows")}</span>
                    <h2 className="score">{getMessage("Carbonstatus.thefollowing")}{overfeedingLevel.count}{getMessage("Carbonstatus.cowsarewastingtomuchfeed")}</h2>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th className="bg cowNo">{getMessage("Main.cowsno")}</th>
                        <th className="bg">{getMessage("Carbonstatus.carbonreductionscore")}</th>
                        <th className="bg">{getMessage("Carbonstatus.actualfeed")}<span>&#40;kg&#41;</span></th>
                        <th className="bg">{getMessage("Carbonstatus.appropriatefeed")}<span>&#40;kg&#41;</span></th>
                        <th className="bg">{getMessage("Carbonstatus.overfeeding")}<span>&#40;kg&#41;</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="small">
                        <td colSpan="5">&#40;{overfeedingLevel.count}/{overfeedingLevel.total}&#41; {getMessage("Main.cows")}</td>
                    </tr>

                    {
                        overfeedingLevel.datas &&
                        overfeedingLevel.datas.map((cow) => {
                            return (
                                <tr key={cow.cowNumber}>
                                    <td><Link to={`/carbon_status/cow_list/${cow.cowNumber}`} className="">{cow.cowNumber}&#40;{cow.cowNumber2}&#41;</Link></td>
                                    <td><Link to={`/carbon_status/cow_list/${cow.cowNumber}`} className={cow.stateCarbon}>{cow.carbonScore}</Link></td>
                                    <td><Link to={`/carbon_status/cow_list/${cow.cowNumber}`} className="">{cow.realFeed}</Link></td>
                                    <td><Link to={`/carbon_status/cow_list/${cow.cowNumber}`} className="">{cow.standardFeed}</Link></td>
                                    <td><Link to={`/carbon_status/cow_list/${cow.cowNumber}`} className="">{cow.overFeed}</Link></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

function CarbonStatus() {
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;

    return (
        <div className="content healthy_cow">
            <FarmScore current={current} setAlertMsg={setAlertMsg} setAlertOpen={setAlertOpen} />

            <CarbonStatusComp current={current} setAlertMsg={setAlertMsg} setAlertOpen={setAlertOpen} />

            <OverfeedingLevel current={current} setAlertMsg={setAlertMsg} setAlertOpen={setAlertOpen} />

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    )
}

export default CarbonStatus;