/* eslint-disable */
import { Link } from 'react-router-dom';
import Select from "react-select";
import { useState, useEffect } from 'react';
import CustomOption from './../../../components/CustomOption';
import api from './../../../libs/api';
import { getDate, calculateDate } from './../../../libs/getDate';
import { useHistory } from 'react-router-dom';

import { useRecoilState } from "recoil";
import { farmCode } from './../../../store/store';
import { Alert } from '../../../components/Alert';
import getMessage from "../../../libs/getMessage";

function ProductivityCowList() {
    const [productivityCowList, setProductivityCowList] = useState([]);
    const [titleScore, setTitleScore] = useState([]);
    
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;

    useEffect(() => {
        getProductivityCowList();
        getTitleScore();
    }, [currentFarmCode]);

    async function getProductivityCowList() {
        const params = {
            farmCode : current,
            inputDate : getDate(calculateDate(-1))
        }
        const response = await api.post('/productivity/farmCowList', params);
        
        // 성공
        if (response.data.code === "200") {
            console.log(response);
            setProductivityCowList(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    
    async function getTitleScore() {
        const params = {
            farmCode : "F000000025",
            inputDate : getDate(calculateDate(-1))
        }
        const response = await api.post('/productivity/titleScore', params);
        
        // 성공
        if (response.data.code === "200") {
            setTitleScore(response.data.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    const groupOptions = [
        { value: "all", label: "ALL" },
        { value: "Score", label: "Score" },
        { value: "Score_danger", label: "danger" },
        { value: "Score_good", label: "good" },
        { value: "Score_excellent", label: "excellent" },
        { value: "Milk", label: "Milk yield" },
        { value: "Milk_danger", label: "danger" },
        { value: "Milk_good", label: "good" },
        { value: "Milk_excellent", label: "excellent" },
        { value: "FCE", label: "FCE" },
        { value: "FCE_danger", label: "danger" },
        { value: "FCE_good", label: "good" },
        { value: "FCE_excellent", label: "excellent" },
        { value: "HC", label: "HC" },
        { value: "HC_danger", label: "danger" },
        { value: "HC_good", label: "good" },
        { value: "HC_excellent", label: "excellent" },
    ];
    const [selectOption, setSelectOption] = useState(groupOptions[0]);

    const history = useHistory();

    function groupChange(option) {
        if (option.value !== "all") {
            // 그룹으로 이동
            if (option.value === "Score" || option.value === "Milk" || option.value === "FCE" || option.value === "HC") {
                history.push(`/productivity/cow_group/${option.value}`);
            } else {
                // cow no 없이 상세로 이동
                history.push({
                    pathname: `/productivity/cow_list/${option.value}`,
                    state: { defaultValue: option }
                });
            }
        }
    }

    function selectCow(option) {
        history.push(`/productivity/cow_list/${option.value}`);
    }

    const filterStyles = {
        control: base => ({
            ...base,
            height: 50,
            minHeight: 50
        })
    }

    let cowOptions = [];
    for (let cow of productivityCowList) {
        cowOptions.push({
            value: cow.cowNumber,
            label: cow.cowNumber,
            status: cow.statePro
        });
    }

    console.log('productivityCowList', productivityCowList);

    setTimeout(() => {
        const toolbarSelect = document.getElementsByClassName("toolbarSelect");
        const select1 = toolbarSelect[0].getElementsByClassName("select__single-value")[0];
        const select1Value = select1.innerHTML;        
        let danger = document.createElement("span");
        danger.setAttribute("class", "danger");
        let good = document.createElement("span");
        good.setAttribute("class", "good");
        let excellent = document.createElement("span");
        excellent.setAttribute("class", "excellent");
        if (select1Value === "danger") {
            select1.prepend(danger);
        }
        if (select1Value === "good") {
            select1.prepend(good);
        }
        if (select1Value === "excellent") {
            select1.prepend(excellent);
        }
    }, 0);

    return (
        <div className="content cow_list">
            <div className="farm_score_div content_wrap one">
                <div className="score_wrap d-flex">
                    <img src="/assets/images/common/icon_productivity.png" alt="" />
                    <div className="title">
                        <span className="sub">{getMessage("Productivity.cowlist")}</span>
                        <h2 className="score">{getMessage("ProductivityCowList.productivityscore")}{titleScore.score} &#40; {getMessage("Productivity.top")} {titleScore.topPer} % &#41;</h2>
                    </div>
                </div>

                <div className="toolbar d-flex">
                    <Link to="/productivity" className="round_btn">{getMessage("Profitabilitycowdetail.viewfarm")}</Link>
                    <Select
                        className="select_container toolbarSelect ml-auto"
                        options={groupOptions}
                        onChange={groupChange}
                        // onChange={selectChange}
                        defaultValue={groupOptions[0]}
                        styles={filterStyles}
                        classNamePrefix="select"
                        components={{ Option: CustomOption }}
                    />
                </div>

                <div className="farm_score main_content">
                    <div className="cow_selector d-flex j-center">
                        {/* 
                        <a className="arrow_prev">
                            <img src="/assets/images/common/arrow_prev.png" alt="" />
                        </a> */}
                        <Select
                            className="select_container ml-auto mr-auto toolbarSelect mx-auto"
                            options={cowOptions}
                            onChange={selectCow}
                            placeholder="Enter Cow No."
                            styles={filterStyles}
                            classNamePrefix="select"
                            components={{ Option: CustomOption }}
                        />
                        {/* <Link to="" className="arrow_next">
                            <img src="/assets/images/common/arrow_next.png" alt="" />
                        </Link> */}
                    </div>
                    <div className="content_header d-flex">
                        <span className="icon_wrap">
                            <img src="/assets/images/common/icon_farm_detail.png" alt="" />
                        </span>
                        <span className="div_title">{getMessage("Productivity.listofdairycow")}</span>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th className="lh1">{getMessage("ProductivityCowList.cowsno")}</th>
                                <th className="lh1">{getMessage("ProductivityCowList.productivityscore")}</th>
                                <th className="lh1">{getMessage("ProductivityCowList.ageinmonths")} <br /><span>&#40;{getMessage("ProductivityCowList.parity")}&#41;</span></th>
                                <th className="lh1">{getMessage("ProductivityCowList.lastcalvingdate")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                productivityCowList &&
                                productivityCowList.map((cow) => {
                                    return (
                                        <tr key={cow.cowNumber}>
                                            <td>
                                                <Link to={`/productivity/cow_list/${cow.cowNumber}`} className="">{cow.cowNumber}&#40;{cow.cowNumber2}&#41;</Link>
                                            </td>
                                            <td>
                                                <Link to={`/productivity/cow_list/${cow.cowNumber}`} className={cow.statePro}>{cow.productivityScore}</Link>
                                            </td>
                                            <td>
                                                <Link to={`/productivity/cow_list/${cow.cowNumber}`} className="">{cow.currentAgeMonth}&#40;{cow.currentParity}&#41;</Link>
                                            </td>
                                            <td>
                                                <Link to={`/productivity/cow_list/${cow.cowNumber}`} className="">{cow.currentLastDelivery}</Link>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    )
}

export default ProductivityCowList;