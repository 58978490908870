/* eslint-disable */
import { useHistory } from 'react-router-dom';

function Alert({ alertMsg, setAlertOpen }) {

    return (
        <>
            <div className="popup alert">
                {/* <div className="popup_header">
                    
                </div> */}
                <div className="popup_body">
                    {alertMsg}
                </div>
                <div className="popup_footer d-flex">
                    <a className="button sm ml-auto" onClick={() => { setAlertOpen(false) }}>Ok</a>
                </div>
            </div>
            <div className="popup_overlay"></div>
        </>
    )
}

function AlertPath({ alertMsg, setAlertOpen, path, join }) {
    const history = useHistory();

    function link(path) {
        if (join) {
            history.push({
                pathname: path,
                termAgree: true
            })
        } else {
            history.push(path);
        }
    }

    return (
        <>
            <div className="popup alert">
                {/* <div className="popup_header">
                    
                </div> */}
                <div className="popup_body">
                    {alertMsg}
                </div>
                <div className="popup_footer d-flex">
                    <a className="button sm ml-auto" onClick={() => { link(path) }}>Ok</a>
                </div>
            </div>
            <div className="popup_overlay"></div>
        </>
    )
}

function AlertBack({ alertMsg, setAlertOpen }) {
    const history = useHistory();

    function goBack() {
        history.goBack();
    }

    return (
        <>
            <div className="popup alert">
                {/* <div className="popup_header">
                    
                </div> */}
                <div className="popup_body">
                    {alertMsg}
                </div>
                <div className="popup_footer d-flex">
                    <a className="button sm ml-auto" onClick={() => { goBack() }}>Ok</a>
                </div>
            </div>
            <div className="popup_overlay"></div>
        </>
    )
}

export { Alert, AlertPath, AlertBack };