/* eslint-disable */
import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useRecoilState } from "recoil";
import api from '../../libs/api';

import { farmCode } from '../../store/store';
import Select from 'react-select';
import CustomOptionSensor from './../../components/CustomOptionSensor';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';
import { Alert, AlertBack } from './../../components/Alert';

function SensorPopup({ setOpen, selectNone }) {
    return (
        <>
            <div className="popup">
                <div className="popup_header">
                    {/* <h3>다른 젖소가 사용중입니다.</h3> */}
                </div>
                <div className="popup_body">
                    <div className="d-flex">
                        <div className="popup_alert">
                            <img src="/assets/images/common/icon_alert.png" alt="" />
                        </div>
                        <span>
                            Sensor already in use. <br />
                            Do you still want to register?
                        </span>
                    </div>
                </div>
                <div className="popup_footer d-flex">
                    <a className="button cancel ml-auto mr-10" onClick={() => { setOpen(false); selectNone(); }}>No</a>
                    <a className="button mr-auto" onClick={() => { setOpen(false) }}>Yes</a>
                </div>
            </div>
            <div className="popup_overlay"></div>
        </>
    )
}

function SensorMapping() {    
    const [cowList, setCowList] = useState([]);
    const [sensorList, setSensorList] = useState([]);
    const [selectedSensor, setSelectedSensor] = useState();
    const [open, setOpen] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertBackOpen, setAlertBackOpen] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");

    const location = useLocation();
    const history = useHistory();

    let defaultCow = {
        label: location.state?.cowNumber,
        value: location.state?.cowNumber,
    };
    // console.log(defaultCow);
    
    const [selectedCow, setSelectedCow] = useState(defaultCow);
    
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;
    
    const params = {
        farmCode: current,
        cowNumber: selectedCow.value,
        sensorNumber: selectedSensor
    }
    const { register, reset, handleSubmit, control, formState: { errors } } = useForm({});
    useEffect(() => {
        setTimeout(() => {
            reset(params);
        }, 20);
    }, []);

    useEffect(() => {
        getCowList();
    }, [currentFarmCode]);
    async function getCowList() {
        const response = await api.get(`/farm/myCowList?farmCode=${current}`);
        
        // 성공
        if (response.data.code === "200") {
            setCowList(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    // console.log('cowList', cowList);
    
    let cowOptions = [];
    for (let list of cowList) {
        cowOptions.push({
            label: list.cowNumber,
            value: list.cowNumber,
        })
    }

    useEffect(() => {
        getSensorList();
    }, []);
    // 센서 리스트 조회
    async function getSensorList() {
        const response = await api.get(`/farm/mySensorList?farmCode=${current}`);
        
        // 성공
        if (response.data.code === "200") {
            setSensorList(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    let sensorOptions = [];
    for (let list of sensorList) {
        sensorOptions.push({
            label: list.sensorNumber,
            value: list.sensorNumber,
            state: list.state
        })
    }

    // 센서 onchange
    function useYChange(e) {
        console.log('e', e);
        let selectSensor = sensorList.filter((list) => list.sensorNumber === e.value)[0];
        
        if (selectSensor.state === 'Y') {
            setOpen(true);
        }
        setSelectedSensor(selectSensor.sensorNumber);
    }

    // 센서 맵핑
    async function sensorMapping(data) {
        const response = await api.post(`/farm/cowSensorMathing`, data);
        // console.log('response', response);
        
        // 성공
        if (response.data.code === "200") {
            setAlertMsg("등록이 완료되었습니다.");
            setAlertBackOpen(true);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    const filterStyles = {
        control: base => ({
            ...base,
            height: 50,
            minHeight: 50
        })
    }
    // console.log('selectedCow', selectedCow);

    function goBack() {
        history.goBack();
    }

    function selectNone() {
        setSelectedSensor("");
    }
    
    return (
        <div className="container">
            <div className="con">
                <form 
                    name="sensor_mapping" 
                    onSubmit={handleSubmit((data) => sensorMapping(data))}
                >
                    <h1 className="title">Cow Management</h1>
                    <div className="content_header d-flex">
                        <span className="icon_wrap not_bg">
                            <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                        </span>
                        <span className="div_title mr-auto">
                            Sensor registration
                        </span>
                    </div>
                    <div className="content_body">
                        <div className="">
                            <label className="" htmlFor="farmName">Select Cow</label> 
                            {errors.cowNumber && <span className="err_msg">This field is required</span>}<br />
                            <Controller
                                name="cowNumber"
                                control={control}
                                // rules={{ required: true }}
                                render={({ field:{ fieldState, onChange } }) => (
                                    <Select
                                        className="select_container toolbarSelect"
                                        options={cowOptions}
                                        defaultValue={defaultCow}
                                        value={selectedCow}
                                        onChange={(val) => { 
                                            setSelectedCow(val); 
                                            onChange(val.value);
                                        }}
                                        styles={filterStyles}
                                        classNamePrefix="select"
                                    />
                                )}
                            />
                        </div>
                        <div className="mt-10">
                            <label htmlFor="farmName">Select Sensor</label>
                            {errors.sensorNumber && <span className="err_msg">This field is required</span>}<br />
                            <Controller
                                name="sensorNumber"
                                control={control}
                                rules={{ required: true }}
                                render={({ field:{ fieldState, onChange } }) => (
                                    <Select
                                        className="select_container toolbarSelect"
                                        options={sensorOptions}
                                        // defaultValue={defaultCow}
                                        value={selectedSensor}
                                        onChange={(val) => { 
                                            useYChange(val); 
                                            setSelectedSensor(val);
                                            onChange(val.value);
                                        }}
                                        styles={filterStyles}
                                        classNamePrefix="select"
                                        components={{ Option: CustomOptionSensor }}
                                    />
                                )}
                            />
                            {/* <select 
                                id="sensorNumber" 
                                name="sensorNumber"
                            >
                                <option value="">고유번호</option>
                                {
                                    sensorList &&
                                    sensorList.map((list) => {
                                        return (
                                            <option 
                                                key={list.sensorNumber}
                                                value={list.sensorNumber}
                                            >
                                                {list.sensorNumber} {list.state ==="Y" && "(사용중)"}
                                            </option>
                                        )
                                    })
                                }
                            </select> */}
                        </div>
                    </div>
                    <div className="content_footer btn_wrap d-flex s-btw">
                        <a onClick={goBack} className="button cancel">Cancel</a>
                        {/* <Link to={{
                            pathname: `/cow_mng/${selectedCow === undefined ? location.state.cowNumber : selectedCow}`,
                            state: {
                                cowNumber: selectedCow === undefined ? location.state.cowNumber : selectedCow,
                                sensorNumber: selectedSensor
                            }
                        }} className="button">Registration</Link> */}
                        <button className="button">Registration</button>
                    </div>
                </form>
            </div>
            { open && <SensorPopup setOpen={setOpen} selectNone={selectNone} /> }

            {
                alertBackOpen && <AlertBack alertMsg={alertMsg} setAlertOpen={setAlertBackOpen} />
            }
            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    );
}

export default SensorMapping;