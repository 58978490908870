/* eslint-disable */
import Select from "react-select";
import { useState, useEffect } from 'react';
import CustomOption from './../../../components/CustomOption';
import api from './../../../libs/api';
import { getDate, calculateDate } from './../../../libs/getDate';
import { Link, useParams, useHistory } from 'react-router-dom';

import { useRecoilState } from "recoil";
import { farmCode } from './../../../store/store';
import { Alert } from "../../../components/Alert";

function HealthyCowGroup() {
    const [healthyCowGroup, setHealthyCowGroup] = useState([]);
    const [titleScore, setTitleScore] = useState([]);
    const [cowGroupD, setCowGroupD] = useState([]);
    const [cowGroupG, setCowGroupG] = useState([]);
    const [cowGroupE, setCowGroupE] = useState([]);
    
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    
    const urlParams = useParams();
    
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;

    const objchk = Object.keys(urlParams);

    useEffect(() => {
        getHealthyCowGroup();
    }, [urlParams, currentFarmCode]);

    async function getHealthyCowGroup() {
        const params = {
            farmCode : current,
            inputDate : getDate(calculateDate(-1)),
            groupKey: objchk.length === 0 ? "" : urlParams.key
        }
        const response = await api.post('/health/healthCowGroupList', params);
        
        // 성공
        if (response.data.code === "200") {
            setHealthyCowGroup(response.data.datas);

            setCowGroupD(response.data.datas.filter((group) => group.groupType === "D"));
            setCowGroupG(response.data.datas.filter((group) => group.groupType === "G"));
            setCowGroupE(response.data.datas.filter((group) => group.groupType === "E"));
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    useEffect(() => {
        getTitleScore();
    }, [currentFarmCode]);
    
    async function getTitleScore() {
        const params = {
            farmCode : current,
            inputDate : getDate(calculateDate(-1))
        }
        const response = await api.post('/health/titleScore', params);
        
        // 성공
        if (response.data.code === "200") {
            setTitleScore(response.data.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    const selectDefault = { 
        value: urlParams.key, 
        label: urlParams.key === "Milk" ? "Milk yield" : urlParams.key
    };

    const groupOptions = [
        { value: "all", label: "ALL" },
        { value: "Score", label: "Score" },
        { value: "Score_danger", label: "danger" },
        { value: "Score_good", label: "good" },
        { value: "Score_excellent", label: "excellent" },
    ];
    const [selectOption, setSelectOption] = useState(groupOptions[0]);

    const history = useHistory();

    function groupChange(option) {
        if (option.value !== "all") {
            // 그룹으로 이동
            if (option.value === "Score" || option.value === "Milk" || option.value === "FCE" || option.value === "HC") {
                history.push(`/health_condition/cow_group/${option.value}`);
            } else {
                // cow no 없이 상세로 이동
                history.push({
                    pathname: `/health_condition/cow_list/${option.value}`,
                    state: { defaultValue: option }
                });
            }
        }
    }

    function selectCow(option) {
        if (option.value !== "danger" && option.value !== "good" && option.value !== "excellent") {
            history.push(`/health_condition/cow_list/${option.value}`);
        }
    }

    const filterStyles = {
        control: base => ({
            ...base,
            height: 50,
            minHeight: 50
        })
    }

    let cowOptions = [];
    if (healthyCowGroup.length > 0) {
        if (cowGroupD[0]?.healthCowList?.length > 0) {
            cowOptions.push({ value: "danger", label: "danger" });
            for (let cow of cowGroupD[0]?.healthCowList) {
                cowOptions.push({
                    value: cow.cowNumber,
                    label: cow.cowNumber,
                    status: cow.stateHealth
                });
            }
        }   
        if (cowGroupG[0]?.healthCowList?.length > 0) {     
            cowOptions.push({ value: "good", label: "good" });
            for (let cow of cowGroupG[0]?.healthCowList) {
                cowOptions.push({
                    value: cow.cowNumber,
                    label: cow.cowNumber,
                    status: cow.stateHealth
                });
            }
        }
        if (cowGroupE[0]?.healthCowList?.length > 0) {
            cowOptions.push({ value: "excellent", label: "excellent" });
            for (let cow of cowGroupE[0]?.healthCowList) {
                cowOptions.push({
                    value: cow.cowNumber,
                    label: cow.cowNumber,
                    status: cow.stateHealth
                });
            }
        }
    }


    console.log('healthyCowGroup', healthyCowGroup);

    setTimeout(() => {
        const toolbarSelect = document.getElementsByClassName("toolbarSelect");
        const select1 = toolbarSelect[0].getElementsByClassName("select__single-value")[0];
        const select1Value = select1.innerHTML;
        let danger = document.createElement("span");
        danger.setAttribute("class", "danger");
        let good = document.createElement("span");
        good.setAttribute("class", "good");
        let excellent = document.createElement("span");
        excellent.setAttribute("class", "excellent");
        if (select1Value === "danger") {
            select1.prepend(danger);
        }
        if (select1Value === "good") {
            select1.prepend(good);
        }
        if (select1Value === "excellent") {
            select1.prepend(excellent);
        }
    }, 0);

    return (
        <div className="content cow_list">
            <div className="farm_score_div content_wrap one">
                <div className="score_wrap d-flex">
                    <img src="/assets/images/common/icon_graph.png" alt="" />
                    <div className="title">
                        <span className="sub">Farm</span>
                        <h2 className="score">Health Score {titleScore.score} &#40; Top {titleScore.topPer}% &#41;</h2>
                    </div>
                </div>

                <div className="toolbar d-flex">
                    <Link to="/health_condition" className="round_btn">View Farm</Link>
                    <Select
                        className="select_container toolbarSelect ml-auto"
                        options={groupOptions}
                        onChange={groupChange}
                        defaultValue={selectDefault}
                        styles={filterStyles}
                        classNamePrefix="select"
                        components={{ Option: CustomOption }}
                    />
                </div>

                <div className="farm_score main_content">
                    <div className="cow_selector d-flex j-center">
                        {/* 
                        <a className="arrow_prev">
                            <img src="/assets/images/common/arrow_prev.png" alt="" />
                        </a> */}
                        <Select
                            className="select_container ml-auto mr-auto toolbarSelect mx-auto"
                            options={cowOptions}
                            onChange={selectCow}
                            placeholder="Enter Cow No."
                            styles={filterStyles}
                            classNamePrefix="select"
                            components={{ Option: CustomOption }}
                        />
                        {/* <Link to="" className="arrow_next">
                            <img src="/assets/images/common/arrow_next.png" alt="" />
                        </Link> */}
                    </div>
                    <div className="content_header d-flex">
                        <span className="icon_wrap">
                            <img src="/assets/images/common/icon_farm_detail.png" alt="" />
                        </span>
                        <span className="div_title">List of Dairy Cow</span>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th className="lh1">Cow's No.</th>
                                <th className="lh1">Health <br /><span>&#40;Score&#41;</span></th>
                                <th className="lh1">Active <br /><span>&#40;Hour&#41;</span></th>
                                <th className="lh1">Sleep <br /><span>&#40;Hour&#41;</span></th>
                                <th className="lh1">Rumination <br /><span>&#40;Hour&#41;</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                cowGroupD[0]?.healthCowList.length > 0 &&
                                <tr>
                                    <td colSpan="5"><span className="danger"></span>Danger</td>
                                </tr>
                            }
                            {
                                cowGroupD[0] &&
                                cowGroupD[0]?.healthCowList?.map((cow) => {
                                    return (
                                        <tr key={cow.cowNumber}>
                                            <td>
                                                <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className="">{cow.cowNumber}&#40;{cow.cowNumber2}&#41;</Link>
                                            </td>
                                            <td>
                                                <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className={cow.stateHealth}>{cow.healthScore}</Link>
                                            </td>
                                            <td>
                                                <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className={cow.stateActivity}>{cow.activityH}</Link>
                                            </td>
                                            <td>
                                                <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className={cow.stateSleep}>{cow.sleepH}</Link>
                                            </td>
                                            <td>
                                                <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className={cow.stateRuminant}>{cow.ruminantH}</Link>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                            {
                                cowGroupG[0]?.healthCowList.length > 0 &&
                                <tr>
                                    <td colSpan="5"><span className="good"></span>Good</td>
                                </tr>
                            }
                            {
                                cowGroupG[0] &&
                                cowGroupG[0]?.healthCowList?.map((cow) => {
                                    return (
                                        <tr key={cow.cowNumber}>
                                            <td>
                                                <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className="">{cow.cowNumber}&#40;{cow.cowNumber2}&#41;</Link>
                                            </td>
                                            <td>
                                                <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className={cow.stateHealth}>{cow.healthScore}</Link>
                                            </td>
                                            <td>
                                                <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className={cow.stateActivity}>{cow.activityH}</Link>
                                            </td>
                                            <td>
                                                <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className={cow.stateSleep}>{cow.sleepH}</Link>
                                            </td>
                                            <td>
                                                <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className={cow.stateRuminant}>{cow.ruminantH}</Link>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                            
                            {
                                cowGroupE[0]?.healthCowList.length > 0 &&
                                <tr>
                                    <td colSpan="5"><span className="excellent"></span>Excellent</td>
                                </tr>
                            }
                            {
                                cowGroupE[0] &&
                                cowGroupE[0]?.healthCowList?.map((cow) => {
                                    return (
                                        <tr key={cow.cowNumber}>
                                            <td>
                                                <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className="">{cow.cowNumber}&#40;{cow.cowNumber2}&#41;</Link>
                                            </td>
                                            <td>
                                                <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className={cow.stateHealth}>{cow.healthScore}</Link>
                                            </td>
                                            <td>
                                                <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className={cow.stateActivity}>{cow.activityH}</Link>
                                            </td>
                                            <td>
                                                <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className={cow.stateSleep}>{cow.sleepH}</Link>
                                            </td>
                                            <td>
                                                <Link to={`/health_condition/cow_list/${cow.cowNumber}`} className={cow.stateRuminant}>{cow.ruminantH}</Link>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    )
}

export default HealthyCowGroup;