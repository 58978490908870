
/* eslint-disable */
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import getMessage from "../../libs/getMessage";

function Footer({ setFooterActive, setTab, tab }) {

    useEffect(() => {
        setFooterActive();
    }, []);

    function footerActive(path) {
        setTab(path);
    }
    

    if (window.location.pathname === "/login" || window.location.pathname === "/join" || window.location.pathname === "/term" || window.location.pathname === "/overseas_login_blocker") return null; // 로그인, 회원가입, 약관, 해외 로그인 차단 페이지 제외
    return (
        <footer>
            <ul className="d-flex t-center">
                <li 
                    className={tab === "main" ? "active" : ""}
                    onClick={() => { footerActive("main") }}
                >
                    <Link to="/">
                        <img src="/assets/images/common/dashboard_home.png" alt="" />
                        <span>
                            {getMessage("Footer.dashboard")}
                        </span>
                    </Link>
                </li>
                <li 
                    className={tab === "profitability" ? "active" : ""}
                    onClick={() => { footerActive("profitability") }}
                >
                    <Link to="/profitability">
                        <img src="/assets/images/common/dashboard_profitability.png" alt="" />
                        <span>
                            {getMessage("Footer.profitability")}
                        </span>
                    </Link>
                </li>
                <li 
                    className={tab === "productivity" ? "active" : ""}
                    onClick={() => { footerActive("productivity") }}
                >
                    <Link to="/productivity">
                        <img src="/assets/images/common/dashboard_productivity.png" alt="" />
                        <span>
                            {getMessage("Footer.productivity")}
                        </span>
                    </Link>
                </li>
                <li 
                    className={tab === "health_condition" ? "active" : ""}
                    onClick={() => { footerActive("health_condition") }}
                >
                    <Link to="/health_condition">
                        <img src="/assets/images/common/dashboard_healthy_cow.png" alt="" />
                        <span>
                            {getMessage("Footer.healthcondition")}
                        </span>
                    </Link>
                </li>
                <li 
                    className={tab === "carbon_status" ? "active" : ""}
                    onClick={() => { footerActive("carbon_status") }}
                >
                    <Link to="/carbon_status">
                        <img src="/assets/images/common/dashboard_carbon_status.png" alt="" />
                        <span>
                            {getMessage("Footer.carbonstatus")}
                        </span>
                    </Link>
                </li>
            </ul>
        </footer>
    );
}

export default Footer;