/* eslint-disable */
import { useEffect, useState } from 'react';

import api from './../../libs/api';
import { Link } from 'react-router-dom';
import { Alert } from '../../components/Alert';
import getMessage from "../../libs/getMessage";

function UserInfo() {
    const [userInfo, setUserInfo] = useState([]);
    
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);

    useEffect(() => {
        getUserInfo();
    }, []);

    async function getUserInfo() {
        const response = await api.get('/users/view');
        console.log('response', response);
        
        // 성공
        if (response.data.code === "200") {
            setUserInfo(response.data.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    console.log(userInfo);

    return (
        <div className="container">
            <h1 className="title">{getMessage("Userinfo.welcome")}{userInfo.name}</h1>
            <div className="current con">
                <div className="content_header d-flex">
                    <span className="icon_wrap not_bg">
                        <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                    </span>
                    <span className="div_title mr-auto">{getMessage("Userinfo.userinformation")}</span>
                    <Link to="/user_info/edit" className="button">
                        <img className="icon" src="/assets/images/common/icon_edit.png" alt="edit" />
                    </Link>
                </div>
                <div className="content_body">
                    <div className="d-flex">
                        <h4 className="tit">{getMessage("Userinfo.name")}</h4>
                        <p className="">{userInfo.name}</p>
                    </div>
                    <div className="d-flex">
                        <h4 className="tit">{getMessage("Userinfo.phone")}</h4>
                        <p className="">{userInfo.phoneNumber}</p>
                    </div>
                    <div className="d-flex">
                        <h4 className="tit">{getMessage("Userinfo.email")}</h4>
                        <p className="">{userInfo.email}</p>
                    </div>
                    <div className="d-flex">
                        <h4 className="tit">{getMessage("Userinfo.partner")}</h4>
                        <p className="">{userInfo.partnerId}</p>
                    </div>
                </div>
            </div>

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    );
}

export default UserInfo;