/* eslint-disable */
import { useEffect, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import api from './../../libs/api';

import { useRecoilState } from "recoil";
import { farmCode } from '../../store/store';
import { Alert } from '../../components/Alert';
import getMessage from "../../libs/getMessage";

function AccordionItem({ sensor, handleClick, click }) {
    const nodeRef = useRef(null);
    const reverse = [...sensor.sensorMathingLog].reverse(); // history 최신순으로 나와야할 경우 사용

    return (
        <>
            <tr 
                className={`accordionItem ${click === sensor.sensorNumber ? "on" : ""}`}
                onClick={()=> handleClick(sensor.sensorNumber)}
            >
                <td className="t-center">{sensor.sensorNumber}</td>
                <td className="t-center">{sensor.cowNumber ? sensor.cowNumber : "Not registered"}</td>
                <td className="t-center">{sensor.regdate ? sensor.regdate : "-"}</td>
                <td className="t-center">{sensor.state === "Y" ? "Use" : "Unused"}</td>
                {/* TODO Repair 상태 추가되면 수정해야함 */}
            </tr>
            <CSSTransition
                in={click === sensor.sensorNumber}
                timeout={300}
                classNames="accordion"
                unmountOnExit
                nodeRef={nodeRef}
            >
                <tr>
                    <td colSpan={4} className="p-5">
                        <div className="desc" ref={nodeRef}>
                            <div className="con mb-0">
                                <div className="d-flex">
                                    <span className="d_title mr-auto">
                                        #{sensor.sensorNumber} History
                                    </span>
                                </div>
                                <table>
                                    <thead>
                                        <tr className="border-b">
                                            <th className="bg t-center p-5">Registration date</th>
                                            <th className="bg t-center p-5">Cow No.</th>
                                            <th className="bg t-center p-5">Current</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            sensor.sensorMathingLog.length === 0
                                            ? <>
                                                <tr>
                                                    <td className="t-center p-5">{sensor.regdate ? sensor.regdate : "-"}</td>
                                                    <td className="t-center p-5">{sensor.cowNumber}</td>
                                                    <td className="t-center p-5">{sensor.state === "Y" ? "Use" : "Unused"}</td>
                                                    {/* TODO Repair 상태 추가되면 수정해야함 */}
                                                </tr>
                                            </>
                                            : <>
                                                {
                                                    sensor.sensorMathingLog.map((list, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td className="t-center p-5">{list.regdate ? list.regdate : "-"}</td>
                                                                <td className="t-center p-5">{list.cowNumber}</td>
                                                                <td className="t-center p-5">{list.state}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </td>
                </tr>
            </CSSTransition>
        </>
    )
}

function SensorMng() {
    const [sensorList, setSensorList] = useState([]);
    
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;

    useEffect(() => {
        getSensorList();
    }, [currentFarmCode]);

    async function getSensorList() {
        const response = await api.get(`/farm/mySensorList?farmCode=${current}`);
        
        // 성공
        if (response.data.code === "200") {
            setSensorList(response.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    console.log('sensorList', sensorList.datas);

    // accordion
    const [click, setClick] = useState(null);
    function handleClick(id) {

        if (click === id) {
            setClick(null);
        } else {
            setClick(id);
        }
    }

    return (
        <div className="container">
            <h1 className="title">{getMessage("Sensormng.sensormanagement")}</h1>
            <div className="content_header d-flex">
                <span className="icon_wrap not_bg">
                    <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                </span>
                <span className="div_title mr-auto">
                    {getMessage("Sensormng.sensorlist")} &#40;Total : {sensorList.count} EA&#41;
                </span>
            </div>
            <div className="content_body">
                <table>
                    <thead>
                        <tr>
                            <th className="t-center">{getMessage("Sensormng.sensorno")}</th>
                            <th className="t-center">{getMessage("Sensormng.sensorlist")}</th>
                            <th className="t-center">{getMessage("Sensormng.registrationdate")}</th>
                            <th className="t-center">{getMessage("Sensormng.current")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sensorList?.datas &&
                            sensorList?.datas.map((sensor) => {
                                return (
                                    // TODO 센서 맵핑시 중복 센서 생기는 거 처리되면 키값 변경해줘야할듯
                                    <AccordionItem
                                        sensor={sensor}
                                        // key={sensor.sensorNumber}
                                        key={sensor.sensorNumber + sensor.cowNumber}
                                        handleClick={handleClick}
                                        click={click}
                                        setClick={setClick}
                                    />
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    );
}

export default SensorMng;