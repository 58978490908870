/* eslint-disable */
import { useEffect, useMemo, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useParams, Link , useLocation } from 'react-router-dom';
import { useRecoilState } from "recoil";

import api from '../../libs/api';

import { farmCode } from '../../store/store';

import Select from 'react-select';
import { Alert } from '../../components/Alert';
import { numberPipe } from '../../libs/pipe';
import { isCompositeComponent } from 'react-dom/test-utils';
import getMessage from "../../libs/getMessage";

function TMR({ control, filterStyles, register, errors, tmrOptions }) {
    const { fields, append, remove } = useFieldArray({
        control: control,
        name: "tmr",
        keyName: "key" // 기본값은 id이고 변경 가능함
    });

    function addTmrRow() {
        append({ tmr: "", quan: "" });
    }

    console.log('tmrOptions', tmrOptions);

    return (
        <>
            {
                fields.map((item, i) => {
                    return (
                        <div className="d-flex feed_tmr_row" key={item.key}>
                            <Controller
                                name={`tmr[${i}].tmr`}
                                control={control}
                                rules={{ required: true }}
                                render={({ field:{ fieldState, onChange } }) => (
                                    <Select
                                        placeholder="Raw material name"
                                        className="select_container toolbarSelect"
                                        options={tmrOptions}
                                        defaultValue={item.tmr && { label: item.tmr, value: item.tmr }}
                                        onChange={(val) => { 
                                            onChange(val.value);
                                        }}
                                        styles={filterStyles}
                                        classNamePrefix="select"
                                    />
                                )}
                            />
                            <input 
                                className="ml-5"
                                type="text" 
                                id="kg" 
                                name="kg" 
                                placeholder="kg/day"
                                {...register(`tmr[${i}].quan`, { required: true })}
                            />
                            {
                                // 필드 추가
                                // TODO TMR 개수 제한 있을 경우 수정해주기
                                // fields.length < 5 &&
                                <p className="add_btn" onClick={addTmrRow}>
                                    <span className="plus">
                                        <img className="icon" src="/assets/images/common/icon_plus.png" alt="" />
                                    </span>
                                </p>
                            }
                            {
                                // 필드 삭제
                                <p className="remove" onClick={() => { remove(i) }}>
                                    <span className="plus">
                                        <img className="icon" src="/assets/images/common/icon_remove.png" alt="" />
                                    </span>
                                </p>
                            }
                        </div>
                    )
                })
            }
        </>
    )
}

// 사료관리 상세
function FeedDetailMng() {
    const [currentFeedData, setCurrentFeedData] = useState({});

    const [companyList, setCompanyList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [productDetail, setProductDetail] = useState([]);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;
    
    const location = useLocation();
    console.log('location', location.state);
    
    const params = {
        company: { label: currentFeedData?.companyName, value: currentFeedData?.companySeq },
        product: { label: currentFeedData?.feedName, value: currentFeedData?.feedSeq },
        feedAmountTot: numberPipe(currentFeedData?.feedAmountTot),
        feedCost: numberPipe(currentFeedData?.feedCost),
    }

    const { register, reset, handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: useMemo(() => {
            return params;
        }, [currentFeedData])
    });

    
    useEffect(() => {
        getCompanyList();
        
        // 수정시 기본 정보 호출/세팅
        if (location.state !== undefined) {
            getProductList(location.state?.companySeq);
            setCurrentFeedData(location.state);
            setProductDetail(location.state?.feedCompositionList);
        }
    }, []);

    // 농장 변경시
    useEffect(() => {
        getCurrentFeedData();
    }, [current]);

    // 사료 정보 불러오기
    async function getCurrentFeedData() {
        const response = await api.get(`feed/myFarm?farmCode=${current}`);

        // 성공
        if (response.data.code === "200") {
            getProductList(response.data.data.companySeq);
            setCurrentFeedData(response.data.data);
            setProductDetail(response.data.data.feedCompositionList);
        } else { // 실패
            //  setAlertMsg(response.data.msg);
            //  setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    console.log('currentFeedData', currentFeedData);

    useEffect(() => {
        reset(params);
    }, [currentFeedData]);

    // 사료 회사 리스트 불러오기
    async function getCompanyList() {
        const response = await api.get('/feed/companyList');

        // 성공
        if (response.data.code === "200") {
            setCompanyList(response.data.datas);
        } else { // 실패
            //  setAlertMsg(response.data.msg);
            //  setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    console.log('companyList', companyList);

    // 사료 리스트 불러오기
    async function getProductList(company) {
        const response = await api.get(`/feed/feedList?seq=${company}`);

        // 성공
        if (response.data.code === "200") {
            setProductList(response.data.datas);
        } else { // 실패
            //  setAlertMsg(response.data.msg);
            //  setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    console.log('productList', productList);

    // 사료 성분 불러오기
    async function getProductDetail(product) {
        const response = await api.get(`/feed/compositionList?seq=${product}`);

        // 성공
        if (response.data.code === "200") {
            setProductDetail(response.data.datas);
        } else { // 실패
            //  setAlertMsg(response.data.msg);
            //  setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    console.log('productDetail', productDetail);

    // 수정/등록 서버 전송
    async function saveFeedData(data) {
        const params = {
            farmCode: current,
            feedAmountTot: data.feedAmountTot.replace(/,/g, ""),
            feedCost: data.feedCost.replace(/,/g, ""),
            feedSeq: data.product.value
        }
        const response = await api.post("/feed/feedSave", params);

        // 성공
        if (response.data.code === "200") {
            setAlertMsg(location.state === undefined ? "등록이 완료되었습니다." : "수정이 완료되었습니다.");
            setAlertOpen(true);
        } else { // 실패
         // setAlertMsg(response.data.msg);
         // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    const filterStyles = {
        control: base => ({
            ...base,
            height: 50,
            minHeight: 50
        })
    }
    const locale = localStorage.getItem("locale");
    return (
        <div className="container">
            <form 
                name="feed_detail" 
                onSubmit={handleSubmit((data) => saveFeedData(data))}
            >
                <h1 className="title">{getMessage("Feeddetailmng.feedmanagement")}</h1>
                <div className="con">
                    <div className="content_header border-b mb-10 d-flex">
                        <span className="icon_wrap not_bg">
                            <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                        </span>
                        <span className="div_title mr-auto">
                            {getMessage("Feeddetailmng.currentfeed")} &rarr; {getMessage("Feeddetailmng.revision")}
                        </span>
                    </div>
                    <div className="content_body">
                        <div className="">
                            <label className="" htmlFor="farmName">{getMessage("Feeddetailmng.selectcompany")}</label>
                            {errors.company && <span className="err_msg">{getMessage("Feeddetailmng.errormsg")}</span>}<br />
                            <Controller
                                name="company"
                                control={control}
                                rules={{ required: true }}
                                render={({ field:{ fieldState, onChange } }) => (
                                    <Select
                                        className="select_container toolbarSelect"
                                        options={companyList.map(company => {
                                            return {
                                                value: company.key,
                                                label: company.value
                                            }
                                        })}
                                        defaultValue={{ label: location.state?.companyName, value: location.state?.companySeq }}
                                        value={{ label: currentFeedData.companyName, value: currentFeedData.companySeq }}
                                        onChange={(val) => { 
                                            setCurrentFeedData({
                                                ...currentFeedData,
                                                companyName: val.label,
                                                companySeq: val.value
                                            });
                                            getProductList(val.value);
                                        }}
                                        styles={filterStyles}
                                        placeholder="Feed company List"
                                        classNamePrefix="select"
                                    />
                                )}
                            />
                        </div>
                        <div className="mt-10">
                            <label className="" htmlFor="farmName">{getMessage("Feeddetailmng.selectproduct")}</label>
                            {errors.product && <span className="err_msg">{getMessage("Feeddetailmng.errormsg")}</span>}<br />
                            <Controller
                                name="product"
                                control={control}
                                rules={{ required: true }}
                                render={({ field:{ fieldState, onChange } }) => (
                                    <Select
                                        className="select_container toolbarSelect"
                                        options={productList.map(product => {
                                            return {
                                                value: product.key,
                                                label: product.value
                                            }
                                        })}
                                        defaultValue={{ label: location.state?.feedName, value: location.state?.feedSeq }}
                                        value={{ label: currentFeedData.feedName, value: currentFeedData.feedSeq }}
                                        onChange={(val) => { 
                                            setCurrentFeedData({
                                                ...currentFeedData,
                                                feedName: val.label,
                                                feedSeq: val.value
                                            });
                                            getProductDetail(val.value);
                                        }}
                                        styles={filterStyles}
                                        placeholder="Feed product List"
                                        classNamePrefix="select"
                                    />
                                )}
                            />
                        </div>
                        {
                            productDetail?.length > 0 &&
                            <div className="con">
                                <div className="d-flex">
                                    <span className="d_title mr-auto">{getMessage("Feeddetailmng.tmrmixingdetails")}</span>
                                </div>
                                <table className="striped">
                                    <thead>
                                        <tr className="border-b">
                                            <th className="bg t-center">{getMessage("Feeddetailmng.type")}</th>
                                            <th className="bg t-center">Unit: %</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            // currentFeedData.feedCompositionList?.map((detail) => {
                                            productDetail?.map((detail) => {
                                                return (
                                                    <tr key={detail.type}>
                                                        <td className="t-center">{detail.type}</td>
                                                        <td className="t-center">{detail.percent}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                        <div className="input_wrap mt-10">
                            <div className="d-flex">
                                <label className="" htmlFor="feedAmountTot">{getMessage("Feeddetailmng.dailyfeedamount")}&#40;kg/day&#41;</label>
                                {errors.feedAmountTot && <span className="err_msg mr-5">{getMessage("Feeddetailmng.errormsg")}</span>}
                            </div>
                            <div className="d-flex input_wrap p-0">
                                <input 
                                    type="text" 
                                    id="feedAmountTot" 
                                    name="feedAmountTot" 
                                    placeholder="Daily feed amount"
                                    // value={currentFeedData?.feedAmountTot}
                                    {...register("feedAmountTot", { required: true })}
                                />
                            </div>
                        </div>
                        <div className="mt-10">
                            <div className="d-flex">
                                <label className="" htmlFor="feedCost">{getMessage("Feeddetailmng.dailyfeedcost")}&#40;{locale === 'en'? 'KRW/day' : 'PHP/day'}&#41;</label>
                                {errors.feedCost && <span className="err_msg mr-5">{getMessage("Feeddetailmng.errormsg")}</span>}
                            </div>
                            <div className="d-flex input_wrap p-0">
                                <input 
                                    type="text" 
                                    id="feedCost" 
                                    name="feedCost" 
                                    placeholder="Daily feed cost"
                                    // value={currentFeedData?.feedCost}
                                    {...register("feedCost", { required: true })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="con">                        
                    <div className="content_header d-flex">
                        <span className="icon_wrap not_bg">
                            <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                        </span>
                        <span className="div_title mr-auto">
                            {getMessage("Feeddetailmng.attachaphoto")}
                        </span>
                    </div>
                    <h1 className="title fs-14">{getMessage("Feeddetailmng.uploadit")}</h1>
                    <div className="content_body">
                        <div className="film_wrap">
                            {/* <label htmlFor="camera" className="film_btn">
                                <img src="/assets/images/common/icon_plus.png" />
                            </label>
                            <input type="file" id="camera" /> */}
                            <a className="film_btn">
                                <img src="/assets/images/common/icon_plus.png" />
                            </a>
                            <p className="camera">
                                <img src="/assets/images/common/icon_camera.png" />
                            </p>
                            <span className="exp">
                                Please take a picture so that the <span className="purple">company and product name</span> can be seen well.
                            </span>
                        </div>
                    </div>
                    <div className="btn_wrap content_footer d-flex s-btw">
                        <Link to="/feed_mng" className="button cancel">{getMessage("Customize.cancel")}</Link>
                        <button type="submit" className="button">
                            {getMessage("Customize.application")}
                        </button>
                    </div>
                </div>
            </form>

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    )
}

export default FeedDetailMng;