/* eslint-disable */
import { Link } from 'react-router-dom';

function NotFound() {

    return (
        <div className="popup notice container">
            <h1 className="title">404 Error</h1>
            <div className="content_header d-flex">
                <span className="icon_wrap not_bg">
                    <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                </span>
                <span className="div_title mr-auto">
                404 Not found
                </span>
            </div>
            <div className="content_body">
                
            </div>
            <div className="content_footer d-flex s-ard">
                <Link to="/" className="button mt-20">Go homepage</Link>
            </div>
        </div>
    )
}

export default NotFound;