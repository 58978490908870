/* eslint-disable */
import { useEffect, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import api from './../../libs/api';
import { Link } from 'react-router-dom';

import { Alert } from '../../components/Alert';
import Dompurify from 'dompurify';


// qna 아코디언
function QnaItem({ qna, handleClick, click }) {
    const nodeRef = useRef(null);

    return (
        <>
            <tr 
                className={`accordionItem ${click === qna.seq ? "on" : ""}`}
                onClick={()=> handleClick(qna.seq)}
            >
                <td>{qna.gubun}</td>
                <td>{qna.hopeDate} {qna.hopeTime}</td>
                <td><div className="ellipsis">{qna.title}</div></td>
                <td>{qna.answerYn === "Y" ? "Done" : "Ongoing"}</td>
            </tr>
            <CSSTransition
                in={click === qna.seq}
                timeout={300}
                classNames="accordion"
                unmountOnExit
                nodeRef={nodeRef}
            >
                <tr>
                    <td colSpan={4} className="p-5">
                        <div className="desc" ref={nodeRef}>
                            <div className="con mb-0">
                                <div className="d-flex">
                                    <span className="d_title mr-auto">Detail</span>
                                </div>
                                <div className="d-flex">
                                    <h4 className="tit">Title</h4>
                                    <input type="text" value={qna.title} readOnly />
                                </div>
                                <div className="d-flex">
                                    {/* TODO 답변 시간 내려오면 변경해줘야함 */}
                                    {
                                        qna.answerYn === "Y" 
                                        ? `The consultation was completed at 1:31 p.m. on September 8.`
                                        : "Your application for consultation has been successfully received."
                                    }
                                </div>
                                <div className="d-flex">
                                    <textarea value={qna.contents ? qna.contents : ""} readOnly />
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </CSSTransition>
        </>
    )
}
// QNA
function Qna() {
    const [qnaList, setQnaList] = useState([]);

    useEffect(() => {
        getQnaList();
    }, []);

    // qna list
    async function getQnaList() {
        const response = await api.get('/board/telConsultingList');
        
        // 성공
        if (response.data.code === "200") {
            setQnaList(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    console.log('qnaList', qnaList);

    // accordion
    const [click, setClick] = useState(null);
    function handleClick(id) {
        if (click === id) {
            setClick(null);
        } else {
            setClick(id);
        }
    }

    return (
        <div className="con">
            <div className="content_header d-flex">
                <span className="icon_wrap not_bg">
                    <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                </span>
                <span className="div_title mr-auto">
                    My inquiry
                </span>
                <Link to="/service_center/consultation_request" className="button underline">
                    Consulting apply
                </Link>
            </div>
            <div className="content_body">
                <table>
                    <colgroup width="20%" />
                    <colgroup width="30%" />
                    <colgroup width="30%" />
                    <colgroup width="20%" />
                    <thead>
                        <tr>
                            <th className="bg">Type</th>
                            <th className="bg">Application date</th>
                            <th className="bg">Title</th>
                            <th className="bg">Current</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            qnaList &&
                            qnaList.map((qna) => {
                                return (
                                    <QnaItem
                                        qna={qna}
                                        key={qna.seq}
                                        handleClick={handleClick}
                                        click={click}
                                        setClick={setClick}
                                    />
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

// faq 아코디언
function FaqItem({ faq, handleClick, click }) {
    const nodeRef = useRef(null);

    return (
        <li 
            className={`faq_item accordionItem ${click === faq.seq ? "on" : ""}`}
            onClick={()=> handleClick(faq.seq)}
        >
            <div className="title faq_q">
                <p className="text ellipsis">Q. {faq.question}</p>
            </div>
            <CSSTransition
                in={click === faq.seq}
                timeout={300}
                classNames="accordion"
                unmountOnExit
                nodeRef={nodeRef}
            >
                <div className="desc faq_a_wrap" ref={nodeRef}>
                    <div className="faq_a" dangerouslySetInnerHTML={{__html: Dompurify.sanitize(faq.answer)}}></div>
                </div>
            </CSSTransition>
        </li>
    )
}

// FAQ
function Faq() {
    const [faqList, setFaqList] = useState([]);
    const [faqCodeList, setFaqcodeList] = useState([]);
    const [faqCategory, setFaqCategory] = useState("");

    useEffect(() => {
        getFaqCodeList();
        getFaqList();
    }, [faqCategory]);

    // faq 구분 코드 FaqGubun
    async function getFaqCodeList() {
        const response = await api.get('/common/codeList?comKind=FaqGubun');

        // 성공
        if (response.data.code === "200") {
            setFaqcodeList(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    // console.log('faqCodeList', faqCodeList);

    // faq list
    async function getFaqList() {
        const response = await api.get(`/board/faqList?keyGubun=${faqCategory}`);

        // 성공
        if (response.data.code === "200") {
            setFaqList(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    
    console.log('faqList', faqList);

    // faq tab active
    function faqTabActive(code) {
        console.log('code', code);
        setFaqCategory(code);
    }

    // accordion
    const [click, setClick] = useState(null);
    function handleClick(id) {
        if (click === id) {
            setClick(null);
        } else {
            setClick(id);
        }
    }

    return (
        <div className="con">
            <div className="content_header d-flex">
                <span className="icon_wrap not_bg">
                    <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                </span>
                <span className="div_title mr-auto">
                    Frequently asked question and answers
                    <span className="exp">We summarized the answers to the frequently asked questions. <br />We hope it helps a lot.</span>
                </span>
            </div>
            <div className="content_body">
                <ul className="faq_tab_list sub_tab_list d-flex f-wrap mt-5">
                    <li 
                        className={faqCategory === "" ? "active" : ""} 
                        onClick={() => { faqTabActive("") }}
                    >
                        전체
                    </li>
                    {
                        faqCodeList.map((code) => {
                            return (
                                <li 
                                    key={code.key}
                                    className={code.key === faqCategory ? "active" : ""}
                                    onClick={() => { faqTabActive(code.key) }}
                                >
                                    {code.value}
                                </li>
                            )
                        })
                    }
                </ul>
                <div className="d-flex con_tit_wrap mt-20">
                    <h4 className="con_tit">Content</h4>
                    <h4 className="con_tit ml-auto">Detail</h4>
                </div>
                <ul className="board_list">
                    {
                        faqList.map((faq) => {
                            return (
                                <FaqItem 
                                    faq={faq}
                                    key={faq.seq}
                                    handleClick={handleClick}
                                    click={click}
                                    setClick={setClick}
                                />
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

// Contact US
function ContactUs() {
    return (
        <div className="">
            <div className="con">
                <div className="content_header d-flex">
                    <span className="icon_wrap not_bg">
                        <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                    </span>
                    <span className="div_title mr-auto">
                        If you want to meet us
                    </span>
                </div>
                <div className="content_body map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.4411102363692!2d127.03590321556753!3d37.49751313583521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca3fe1c03b4dd%3A0xe68e9b8a5e8b8ff6!2zKOyjvCnslL3tgaztj6zruYTsl5g!5e0!3m2!1sko!2skr!4v1660027367129!5m2!1sko!2skr" width="100%" height="250"  loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="google_map" />
                </div>
            </div>
            
            <div className="con">
                <div className="content_header d-flex">
                    <span className="icon_wrap not_bg">
                        <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                    </span>
                    <span className="div_title mr-auto">
                        Location
                    </span>
                </div>
                <div className="content_body location">
                    <ul>
                        <li className="d-flex">
                            <span className="title">Address</span>
                            <span className="ml-auto">419 PMK Bldg. 8F, Nonhyeon-ro Gangnam-gu, Seoul</span>
                        </li>
                        <li className="d-flex">
                            <span className="title">Subway</span>
                            <span className="ml-auto">5min walk from Yeoksam Station Line 2 Exit 2</span>
                        </li>
                        <li className="d-flex">
                            <span className="title">Operating hour</span>
                            <span className="ml-auto">09:30~12:00, 13:00~18:30</span>
                        </li>
                        <li className="d-flex">
                            <span className="title">Call num.</span>
                            <span className="ml-auto">
                                <a href="tel:02-562-6545">02-562-6545</a>
                            </span>
                        </li>
                    </ul>
                </div>
                <div className="content_footer withdrawal">
                    <div className=" t-left">
                        <Link to="/service_center/membership_withdrawal">Membership Withdrawal &gt;</Link>
                    </div>
                </div>
            </div>
		</div>
    )
}

function ServiceCenter() {
    const [tabIdx, setTabIdx] = useState(0);
    
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    
    function setTab(idx) {
        setTabIdx(idx);
    }

    return (
        <div className="container">
        <h1 className="title">CS/ Usage guide</h1>
            <div className="con">
                <ul className="service_tab tab_header d-flex s-btw">
                    <li className={ tabIdx === 0 ? "active" : "" }>
                        <a onClick={() => { setTab(0) }}>1:1</a>
                    </li>
                    <li className={ tabIdx === 1 ? "active" : "" }>
                        <a onClick={() => { setTab(1) }}>FAQ</a>
                    </li>
                    <li className={ tabIdx === 2 ? "active" : "" }>
                        <a onClick={() => { setTab(2) }}>Contact Us</a>
                    </li>
                </ul>
            </div>

            <div className="tab_content">
                { tabIdx === 0 && <Qna /> }
                { tabIdx === 1 && <Faq /> }
                { tabIdx === 2 && <ContactUs /> }
            </div>

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    );
}

export default ServiceCenter;