/* eslint-disable */
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ko } from 'date-fns/esm/locale';
import DateTermBtn from '../../../components/DateTermBtn';
import api from './../../../libs/api';
import { getDate, calculateDate } from './../../../libs/getDate';
import LineAreaChart from './../../../components/charts/LineAreaChart';
import LineColumnChart from './../../../components/charts/LineColumnChart';

import { useRecoilState } from "recoil";
import { farmCode } from './../../../store/store';
import { Alert } from '../../../components/Alert';

import { CSSTransition } from 'react-transition-group';
import { numberPipe } from '../../../libs/pipe';
import getMessage from "../../../libs/getMessage";
import CustomDatePicker from "../../../libs/CustomDatePicker";

function FarmScore({ current, setAlertMsg, setAlertOpen }) {
    const [startDate, setStartDate] = useState(calculateDate(-30));
    const [endDate, setEndDate] = useState(calculateDate(-1));
    const [dateTerm, setDateTerm] = useState("day");
    const [farmScoreData, setFarmScoreData] = useState([]);
    const locale = localStorage.getItem("locale");

    useEffect(() => {
        getFarmScore();
    }, [startDate, endDate, current]);

    async function getFarmScore() {
        const params = {
            farmCode : current,
            startDate : getDate(startDate),
            endDate : getDate(endDate)
        }

        const response = await api.post('/productivity/productivityScore', params);
        
        // 성공
        if (response.data.code === "200") {
            setFarmScoreData(response.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    console.log('farmScoreData', farmScoreData);

    return (
        <div className="farm_score_div content_wrap">
            <div className="score_wrap d-flex">
                <img src="/assets/images/common/icon_productivity.png" alt="" />
                <div className="title">
                    <span className="sub">
                        {getMessage("Common.farm")}
                    </span>
                    <h2 className="score">
                        {getMessage("Common.farmscore")}
                        {farmScoreData.farmScore} &#40;
                        Top {farmScoreData.topPer}% &#41;</h2>
                </div>
            </div>

            <div className="toolbar">
                <Link to="/productivity/cow_list" className="round_btn">
                    {getMessage("Common.viewcow")}
                </Link>
            </div>
            <div className="farm_score main_content">
                <div className="content_header d-flex">
                    <span className="icon_wrap">
                        <img src="/assets/images/common/icon_home.png" alt="" />
                    </span>
                    <span className="div_title">
                        {getMessage("Common.farmscore")}
                    </span>
                    <div className="ml-auto">
                        {/* <DateTermBtn dateTerm={dateTerm} setDateTerm={setDateTerm} /> */}
                    </div>
                    <div className="datepicker_wrap from_to">
                        <img src="/assets/images/common/icon_calendar.png" className="calendar" alt="" />
                        <CustomDatePicker
                            selectedDate={startDate}
                            endDate={endDate}
                            onStartDateChange={(date) => setStartDate(date)}
                            onEndDateChange={(date) => setEndDate(date)}
                            locale={locale}
                        />
                        <img src="/assets/images/common/icon_calendar_arrow.png" className="arrow" alt="" />
                    </div>
                </div>
                <div className="graph">
                    {
                        // TODO-JK : 그래프
                        farmScoreData.datas &&
                        <LineAreaChart data={farmScoreData.datas } title={"productivity_farmScore"} />
                    }
                </div>
            </div>
        </div>
    )
}

function FarmStatus({ current, setAlertMsg, setAlertOpen }) {
    const [startDate, setStartDate] = useState(calculateDate(-30));
    const [endDate, setEndDate] = useState(calculateDate(-1));
    const [dateTerm, setDateTerm] = useState("day");
    const [farmStatusData, setFarmStatusData] = useState([]);
    const locale = localStorage.getItem("locale");

    useEffect(() => {
        getFarmStatus();
    }, [startDate, endDate, current]);

    async function getFarmStatus() {
        const params = {
            farmCode : current,
            startDate : getDate(startDate),
            endDate : getDate(endDate)
        }
        const response = await api.post('/productivity/productivityStatus', params);
        
        // 성공
        if (response.data.code === "200") {
            setFarmStatusData(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    // console.log('farmStatusData', farmStatusData);
    
    return (
        <div className="main_content">
            <div className="content_header d-flex">
                <span className="icon_wrap">
                    <img src="/assets/images/common/icon_home.png" alt="" />
                </span>
                <span className="div_title">
                    {getMessage("Common.farmstatus")}
                </span>
                <div className="ml-auto">
                    {/* <DateTermBtn dateTerm={dateTerm} setDateTerm={setDateTerm} /> */}
                </div>
                <div className="datepicker_wrap from_to">
                    <img src="/assets/images/common/icon_calendar.png" className="calendar" alt="" />
                    <CustomDatePicker
                        selectedDate={startDate}
                        endDate={endDate}
                        onStartDateChange={(date) => setStartDate(date)}
                        onEndDateChange={(date) => setEndDate(date)}
                        locale={locale}
                    />
                    <img src="/assets/images/common/icon_calendar_arrow.png" className="arrow" alt="" />
                </div>
            </div>
            <div className="graph">
                {
                    farmStatusData &&
                    <LineColumnChart data={farmStatusData} />
                }
            </div>
        </div>
    )
}

function AbnormalDairyCow({ current, setAlertMsg, setAlertOpen }) {
    const [abnormalDairyCow, setAbnormalDairyCow] = useState([]);

    useEffect(() => {
        getAbnormalDairyCow();
    }, [current]);

    async function getAbnormalDairyCow() {
        const params = {
            farmCode : current,
            inputDate : getDate(calculateDate(-1))
        }
        const response = await api.post('/productivity/abnormalCows', params);
        
        // 성공
        if (response.data.code === "200") {
            setAbnormalDairyCow(response.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    return (
        <div className="main_content abnormal_dairy_cow">
            <div className="content_header d-flex">
                <span className="icon_wrap">
                    <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                </span>
                <span className="div_title">Abnormal Dairy Cow</span>
            </div>
            <div className="score_wrap d-flex">
                <img src="/assets/images/common/cow_zoom.png" alt="" />
                <div className="title">
                    <span className="sub">{getMessage("Main.total")}{abnormalDairyCow.total} {getMessage("Main.cows")}</span>
                    <h2 className="score">{getMessage("Productivity.pleasecheckthestatusofthenext")}{abnormalDairyCow.count} {getMessage("Main.cows")}</h2>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th className="bg">{getMessage("Main.cowsno")}</th>
                        <th className="bg">{getMessage("Productivity.score")}</th>
                        <th className="bg">{getMessage("Productivity.milkyield")}<span>&#40;L&#41;</span></th>
                        <th className="bg">FCE <span>&#40;%&#41;</span></th>
                        <th className="bg">{getMessage("Productivity.healthycondition")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="small">
                        <td colSpan="5">&#40;{abnormalDairyCow.count}/{abnormalDairyCow.total}&#41; {getMessage("Main.cows")}</td>
                    </tr>
                    {
                        abnormalDairyCow.datas &&
                        abnormalDairyCow.datas.map((cow) => {
                            return (
                                <tr key={cow.cowNumber}>
                                    <td><Link to={`/productivity/cow_list/${cow.cowNumber}`} className="">{cow.cowNumber}&#40;{cow.cowNumber2}&#41;</Link></td>
                                    <td><Link to={`/productivity/cow_list/${cow.cowNumber}`} className={cow.statePro}>{cow.productivityScore}</Link></td>
                                    <td><Link to={`/productivity/cow_list/${cow.cowNumber}`} className={cow.stateMilk}>{cow.milkYield}</Link></td>
                                    <td><Link to={`/productivity/cow_list/${cow.cowNumber}`} className={cow.stateFCE}>{cow.fce}</Link></td>
                                    <td><Link to={`/productivity/cow_list/${cow.cowNumber}`} className={cow.stateHealth}>{cow.healthConditionScore}</Link></td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

// tmr suggestion accordion
function AccordionItem({ suggestion, handleClick, click }) {
    const nodeRef = useRef(null);
    return (
        <>
            <tr 
                className={`accordionItem ${click === suggestion.num ? "on" : ""}`}
                onClick={()=> handleClick(suggestion)}
            >
                <td className="t-center pr-10">
                    {suggestion.regdate}
                </td>
                <td className="t-center pr-10">
                    {
                        suggestion.type.indexOf("(TMR suggest)") > -1
                        ? suggestion.type.split("(TMR suggest)")[1]
                        : suggestion.type
                    }
                </td>
                <td className="t-center pr-10">
                    {suggestion.tmrs}
                </td>
            </tr>
            {
                suggestion &&
                <CSSTransition
                    in={click === suggestion.num}
                    timeout={300}
                    classNames="accordion"
                    unmountOnExit
                    nodeRef={nodeRef}
                >
                    <tr>
                        <td colSpan={3} className="p-5">
                            <div className="desc" ref={nodeRef}>
                                {
                                    suggestion.tmrList.map((tmr, i) => {
                                        return (
                                            <div className="con tmr_suggestions_detail" key={tmr.tmrDetaiList + suggestion.num}>
                                                <span className="">
                                                    #{i + 1}. TMR {suggestion.tmrs.split(",")[i]}. - Unit Price: {getMessage("Common.money")} {numberPipe(tmr.totalPrice)} / {numberPipe(tmr.totalKg)} kg
                                                </span>
                                                <div className="d-flex sub_tit">
                                                    <span className="">{getMessage("Productivity.tmrmixingdetails")}</span>
                                                </div>
                                                <table className="">
                                                    <colgroup>
                                                        <col width="*" />
                                                        <col width="70px" />
                                                        <col width="70px" />
                                                    </colgroup>
                                                    <thead>
                                                        <tr className="border-b">
                                                            <th className="bg t-center pr-10">{getMessage("Productivity.type")}</th>
                                                            <th className="bg t-center pr-10">Unit: kg</th>
                                                            {/*<th className="bg t-center pr-10">Unit: &#8361;</th>*/}
                                                            <th className="bg t-center pr-10">Unit: {getMessage("Common.money")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tmr.tmrDetaiList.map((list) => {
                                                                return (
                                                                    <tr key={list.etitle}>
                                                                        <td className="bg t-center pr-10">{list.etitle}</td>
                                                                        <td className="bg t-center pr-10">{numberPipe(list.kg, localStorage.getItem("locale"))}</td>
                                                                        <td className="bg t-center pr-10">{numberPipe(list.price, localStorage.getItem("locale"))}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </td>
                    </tr>
                </CSSTransition>
            }
        </>
    )
}
function TMRSuggestions({ current }) {
    const [startDate, setStartDate] = useState(calculateDate(-30));
    const [endDate, setEndDate] = useState(calculateDate(0));
    const [dateTerm, setDateTerm] = useState("day");
    const locale = localStorage.getItem("locale");
    const [suggestionList, setSuggestionList] = useState([]);

    useEffect(() => {
        getTMRSuggestions();
    }, [startDate, endDate, current]);

    async function getTMRSuggestions() {
        const params = {
            farmCode : current,
            startDate : getDate(startDate),
            endDate : getDate(endDate)
        }
        
        const response = await api.post(`/farm/serviceOneTimeList`, params);
        console.log('response', response);

        // 성공
        if (response.data.code === "200") {
            setSuggestionList(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    console.log('suggestionList', suggestionList);

    // accordion
    const [click, setClick] = useState(null);
    function handleClick(suggestion) {
        if (click === suggestion.num) {
            setClick(null);
        } else {
            setClick(suggestion.num);
        }
    }

    return (
        <div className="main_content tmr_suggestions">
            <div className="content_header d-flex">
                <span className="icon_wrap">
                    <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                </span>
                <span className="div_title">TMR {getMessage("Productivity.suggestions")}</span>
                <div className="ml-auto">
                    {/* <DateTermBtn dateTerm={dateTerm} setDateTerm={setDateTerm} /> */}
                </div>
                <div className="datepicker_wrap from_to">
                    <img src="/assets/images/common/icon_calendar.png" className="calendar" alt="" />
                    <CustomDatePicker
                        selectedDate={startDate}
                        endDate={endDate}
                        onStartDateChange={(date) => setStartDate(date)}
                        onEndDateChange={(date) => setEndDate(date)}
                        locale={locale}
                    />
                    <img src="/assets/images/common/icon_calendar_arrow.png" className="arrow" alt="" />
                </div>
            </div>
            
            <div className="content_body">
                <table>
                    <colgroup>
                        <col width="135px" />
                        <col width="*" />
                        <col width="75px" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th className="t-center pr-10">Date</th>
                            <th className="t-center pr-10">Type</th>
                            <th className="t-center pr-10">TMR</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            suggestionList &&
                            suggestionList.map((suggestion) => {
                                return (
                                    <AccordionItem
                                        suggestion={suggestion}
                                        key={suggestion.num}
                                        handleClick={handleClick}
                                        click={click}
                                        setClick={setClick}
                                    />
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function Productivity() {
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);

    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;

    useEffect(() => {
        
    }, [currentFarmCode]);

    return (
        <div className="content healthy_cow">
            <FarmScore current={current} setAlertMsg={setAlertMsg} setAlertOpen={setAlertOpen} />

            <FarmStatus current={current} setAlertMsg={setAlertMsg} setAlertOpen={setAlertOpen} />

            <AbnormalDairyCow current={current} setAlertMsg={setAlertMsg} setAlertOpen={setAlertOpen} />

            <TMRSuggestions current={current} />

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    )
}

export default Productivity;