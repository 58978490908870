/* eslint-disable */
import { useEffect, useMemo, useState, useRef } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useParams, Link } from 'react-router-dom';
import { useRecoilState } from "recoil";

import api from '../../libs/api';
import { numberPipe } from '../../libs/pipe';

import { farmCode } from '../../store/store';

import Select from 'react-select';
import { Alert } from './../../components/Alert';
import { CSSTransition } from 'react-transition-group';
import getMessage from "../../libs/getMessage";

// 사료 성분 아코디언
function AccordionItem({ currentFeedData, handleClick, click}) {
    const nodeRef = useRef(null);

    return (
        <>
            <div 
                className={`accordionItem d-flex bt-none dashed_b w-100 ${click === currentFeedData.companySeq ? "on" : ""}`}
                onClick={()=> handleClick(currentFeedData.companySeq)}
            >
                <h4 className="tit">Product name</h4>
                <div className="title pb-0 pr-20">
                    <p className="text">{currentFeedData.feedName}</p>
                </div>
            </div>
            <CSSTransition
                in={click === currentFeedData.companySeq}
                timeout={300}
                classNames="accordion"
                unmountOnExit
                nodeRef={nodeRef}
            >
                <div className="desc" ref={nodeRef}>
                    <div className="con">
                        <div className="d-flex">
                            <span className="d_title mr-auto">TMR Mixing Details</span>
                        </div>
                        <table className="striped">
                            <thead>
                                <tr className="border-b">
                                    <th className="bg t-center">Type</th>
                                    <th className="bg t-center">Unit: %</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentFeedData.feedCompositionList?.map((detail) => {
                                        return (
                                            <tr key={detail.type}>
                                                <td className="t-center">{detail.type}</td>
                                                <td className="t-center">{detail.percent}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </CSSTransition>
        </>
    )
}

// 사료관리 데이터 없을 경우
function NoData() {
    return (
        <div className="t-center nodata">
            Welcome !!<br /><br />
            Please register the feed information you are using. <br /><br />
            <Link to="/feed_mng/detail">
                <img src="/assets/images/common/icon_plus_lg.png" alt="register" />
            </Link>
        </div>
    )
}

// 현재 사료
function CurrentFeed({ currentFeedData }) {
    // accordion
    const [click, setClick] = useState(null);
    const locale = localStorage.getItem("locale");
    function handleClick(id) {
        if (click === id) {
            setClick(null);
        } else {
            setClick(id);
        }
    }

    return (
        <div className="con">
            <div className="content_body">
                <div className="current con">
                    <div className="content_body">
                        <div className="d-flex dashed_b">
                            <h4 className="tit">Company name</h4>
                            <p className="">{currentFeedData.companyName}</p>
                        </div>
                        <AccordionItem
                            currentFeedData={currentFeedData}
                            handleClick={handleClick}
                            click={click}
                            setClick={setClick}
                        />
                        <div className="d-flex dashed_b">
                            <h4 className="tit">Daily feed amount</h4>
                            <p className="">{numberPipe(currentFeedData.feedAmountTot)} kg/day</p>
                        </div>
                        <div className="d-flex ">
                            <h4 className="tit">Cost</h4>
                            {/*<p className="">{numberPipe(currentFeedData.feedCost)} won/day</p>*/}
                            {locale === 'en' ? <p className="">{numberPipe(currentFeedData.feedCost)} KRW/day</p> : <p className="">{numberPipe(Math.floor(currentFeedData.feedCost/23.21))} PHP/day</p>  }
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    )
}

// 사료관리
function FeedMng() {
    const [currentFeedData, setCurrentFeedData] = useState({});

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;
    
    useEffect(() => {
        getCurrentFeedData(); // 수정시 농장 정보
    }, [current]);

    // 사료 정보 불러오기
    async function getCurrentFeedData() {
        const response = await api.get(`feed/myFarm?farmCode=${current}`);

        // 성공
        if (response.data.code === "200") {
            setCurrentFeedData(response.data.data);
        } else { // 실패
            //  setAlertMsg(response.data.msg);
            //  setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    console.log('currentFeedData', currentFeedData);

    return (
        <div className="container feed_mng">
            <h1 className="title">{getMessage("Feedmng.feedmanagement")}</h1>
            <div className="con">
                <div className="content_header d-flex">
                    <span className="icon_wrap not_bg">
                        <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                    </span>
                    <span className="div_title mr-auto">
                        {getMessage("Feedmng.currentfeed")}
                    </span>
                    {
                        Object.keys(currentFeedData).length > 0  &&
                        <Link className="button" to={{
                            pathname: "/feed_mng/detail",
                            state: currentFeedData
                        }}>
                            <img className="icon" src="/assets/images/common/icon_edit.png" alt="edit" />
                        </Link>
                    }
                </div>
                <div className="content_body">
                    { Object.keys(currentFeedData).length > 0 ? <CurrentFeed currentFeedData={currentFeedData} /> : <NoData /> }
                </div>
            </div>

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    )
}

export default FeedMng;