import React from "react";
import { Route } from "react-router-dom";
import isLogin from "./isLogin";

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return isLogin() ? <Component {...props} /> : window.location.replace("/login");
            }}
        />
    );
};

export default PrivateRoute;
