import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
/**
 *
 * @param selectedDate : start date & select start date
 * @param endDate : endDate
 * @param onStartDateChange : change start date
 * @param onEndDateChange : change end date
 * @param locale : locale value for internationalization
 */
export default function CustomDatePicker({selectedDate, endDate, onStartDateChange, onEndDateChange, locale}) {
    const getDateFormat = () =>{
        if (locale === 'en') {
            return 'yy.MM.dd';
        } else if (locale === 'ph') {
            return 'MM.dd.yy';
        }
        return 'yy-MM-dd';
    };

    return (
        <>
            <DatePicker
                selected={selectedDate}
                onChange={(date) => onStartDateChange(date)}
                selectsStart
                startDate={selectedDate}
                endDate={endDate}
                dateFormat={getDateFormat()}
            />
            <span className="from_to">~</span>
            <DatePicker
                selected={endDate}
                onChange={(date) => onEndDateChange(date)}
                selectsEnd
                startDate={selectedDate}
                endDate={endDate}
                minDate={selectedDate}
                dateFormat={getDateFormat()}
            />
        </>
    );
}

// export default CustomDatePicker;