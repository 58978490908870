/* eslint-disable */
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from 'react';

import { removeCookie } from '../../libs/auth/Cookie';
import { AlertPath } from './../Alert';
import api from './../../libs/api';
import getMessage from "../../libs/getMessage";


function Header({ setFooterActive, setTab, tab }) {
    const history = useHistory();
    const pathname = window.location.pathname;

    const [menuOpen, setMenuOpen] = useState(false);
    const [alertPathOpen, setAlertPathOpen] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");

    useEffect(() => {
        setFooterActive();
    }, [pathname]);

    // 뒤로가기
    function goBack() {
        history.goBack();
        setFooterActive();
    }

    // 메뉴 열기
    function open() {
        setMenuOpen(true);
    }
    // 메뉴 닫기
    function close() {
        setFooterActive();
        setMenuOpen(false);
    }

    function changeLang() {
        if (localStorage.getItem("locale") === "en"|| localStorage.getItem("locale") === null) {
            localStorage.setItem('locale', 'ph');
        } else {
            localStorage.setItem('locale', 'en');
        }
        window.location.reload();
    }
    // 로그아웃
    async function logout() {
        const response = await api.get('/siteLogout');

        // 성공
        if (response.data.code === "200") {
            setAlertMsg(getMessage("Header.logout"));
            setAlertPathOpen(true);
            localStorage.removeItem("userInfo");
            localStorage.removeItem("farmCode");
            localStorage.removeItem("expiresAt");
            localStorage.removeItem("termAgree")
            removeCookie("accessToken");
            removeCookie("refreshToken");
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    if (window.location.pathname === "/login" || window.location.pathname === "/join" || window.location.pathname === "/term" || window.location.pathname === "/overseas_login_blocker") return null; // 로그인, 회원가입, 약관, 해외 로그인 차단 페이지 제외
    return (
        <>
            <header>
                <div className="header d-flex s-btw">
                    <a onClick={goBack} className="back_btn">
                        <img src="/assets/images/common/arrow_back.png" alt=""/>
                    </a>
                    <h1 id="logo">
                        <Link to="/main">
                            <img src="/assets/images/common/header_logo.png" alt=""/>
                        </Link>
                    </h1>
                    {/* <a className="menu_toggle"> */}
                    <a onClick={open} className="menu_toggle">
                        <img src="/assets/images/common/menu_btn.png" alt=""/>
                    </a>
                </div>
            </header>

            <div id="menu" className={menuOpen ? "open" : ""}>
                <div className="info">
                    <div className="top d-flex">
                        <h3 className="user mr-auto">
                            {getMessage("Header.welcomeaboard")}
                        </h3>
                        <ul className="d-flex">
                            <li className="pointer" onClick={logout}>
                                <img src="/assets/images/common/icon_logout.png" alt=""/>
                            </li>
                            <li className="pointer" onClick={changeLang}>
                                {
                                    localStorage.getItem('locale') === 'en' || localStorage.getItem('locale') === null
                                    ? <img src="/assets/images/common/icon_en.png" alt=""/>
                                        : <img src="/assets/images/common/icon_ph.png" alt=""/>
                                }

                                {/*<img src="/assets/images/common/icon_language.png" alt=""/>*/}
                            </li>
                            <li className="pointer">
                                <img src="/assets/images/common/icon_notification.png" alt=""/>
                            </li>
                            <li className="pointer">
                                <img src="/assets/images/common/icon_setting.png" alt=""/>
                            </li>
                            <li className="pointer" onClick={close}>
                                <img src="/assets/images/common/icon_close.png" alt=""/>
                            </li>
                        </ul>
                    </div>
                    <div className="bottom">
                        <div className="content_header d-flex">
                            <span className="icon_wrap not_bg">
                                <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt=""/>
                            </span>
                            <span className="div_title mr-auto">
                                {getMessage("Header.myfarm")}
                            </span>
                        </div>
                        <ul id="gnb">
                            <li><Link onClick={close} to="/user_info">
                                {getMessage("Header.information")}
                            </Link></li>
                            <li><Link onClick={close} to="/farm_mng">
                                {getMessage("Header.farmmanagement")}
                            </Link></li>
                            <li><Link onClick={close} to="/cow_mng">
                                {getMessage("Header.cowmanagement")}
                            </Link></li>
                            <li><Link onClick={close} to="/sensor_mng">
                                {getMessage("Header.sensormanagement")}
                            </Link></li>
                            <li><Link onClick={close} to="/feed_mng">
                                {getMessage("Header.feedmanagement")}
                            </Link></li>
                            <li><Link onClick={close} to="/service_mng">
                                {getMessage("Header.servicesinuse")}
                            </Link></li>
                            <li><Link onClick={close} to="/credit_usage_history">
                                {getMessage("Header.creditusagehistory")}
                            </Link></li>
                            <li><Link onClick={close} to="/service_center">
                                {getMessage("Header.csusageguide")}
                            </Link></li>
                            <li><Link onClick={close} to="/app_info">
                                {getMessage("Header.abouttheapp")}
                            </Link></li>

                            {/* <li><Link onClick={close} to="/user_info">회원정보</Link></li>
                            <li><Link onClick={close} to="/farm_mng">농장관리</Link></li>
                            <li><Link onClick={close} to="/cow_mng">젖소관리</Link></li>
                            <li><Link onClick={close} to="/sensor_mng">센서관리</Link></li>
                            <li><Link onClick={close} to="/feed_mng">사료관리</Link></li>
                            <li><Link onClick={close} to="/service_mng">이용중인 서비스</Link></li>
                            <li><Link onClick={close} to="/credit_usage_history">포인트 사용 내역</Link></li>
                            <li><Link onClick={close} to="/service_center">고객센터/사용가이드</Link></li>
                            <li><Link onClick={close} to="/app_info">앱정보</Link></li> */}
                        </ul>
                    </div>
                </div>
            </div>
            
            <div id="overlay" onClick={close} className={menuOpen ? "open" : ""}></div>

            {
                alertPathOpen && <AlertPath alertMsg={alertMsg} setAlertOpen={setAlertPathOpen} path="/login" />
            }
        </>
    );
}

export default Header;