/* eslint-disable */
import { useEffect, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import api from './../../libs/api';
import { Link } from 'react-router-dom';

import { useRecoilState } from "recoil";
import { farmCode } from '../../store/store';
import getMessage from "../../libs/getMessage";

function AccordionItem({ cow, handleClick, click }) {
    const nodeRef = useRef(null);

    return (
        <>
            <tr 
                className={`accordionItem ${click === cow.seq ? "on" : ""}`}
                onClick={()=> handleClick(cow.seq)}
            >
                <td>{cow.cowNumber}</td>
                <td className="t-center">
                    {
                        cow.sensorNumber
                        ? cow.sensorNumber 
                        : <Link className="sensor_R" to={{
                            pathname: "/cow_mng/sensor_mapping",
                            state: cow
                        }}>New Registration</Link>
                    }
                </td>
                <td className="t-center">{cow.currentWeight}</td>
                <td className="t-center">{cow.currentAgeMonth}</td>
            </tr>
            <CSSTransition
                in={click === cow.seq}
                timeout={300}
                classNames="accordion"
                unmountOnExit
                nodeRef={nodeRef}
            >
                <tr>
                    <td colSpan={4} className="p-5">
                        <div className="desc" ref={nodeRef}>
                            <div className="con">
                                <div className="d-flex">
                                    <span className="d_title mr-auto">Cow Information</span>
                                    <Link 
                                        to={`/cow_mng/${cow.cowNumber}`}
                                        // to={{
                                        //     pathname: `/cow_mng/${cow.cowNumber}`,
                                        //     state: { cow: cow }
                                        // }}
                                    >
                                        <img className="icon" src="/assets/images/common/icon_edit.png" alt="edit" />
                                    </Link>
                                </div>
                                <div className="d-flex">
                                    <h4 className="tit">ID</h4>
                                    <p className="">{cow.cowIdentifyNumber}</p>
                                </div>
                                <div className="d-flex">
                                    <h4 className="tit">Breed</h4>
                                    <p className="">{cow.cowBreed}</p>
                                </div>
                                <div className="d-flex">
                                    <h4 className="tit">Date of Birth</h4>
                                    <p className="">{cow.cowBirth}</p>
                                </div>
                                <div className="d-flex">
                                    <h4 className="tit">Number of Pregnancy</h4>
                                    <p className="">{cow.currentPregnancy}</p>
                                </div>
                                <div className="d-flex">
                                    <h4 className="tit">Parity</h4>
                                    <p className="">{cow.currentParity}</p>
                                </div>
                                <div className="d-flex">
                                    <h4 className="tit">Expected Calving Date</h4>
                                    <p className="">{cow.currentExpectedCalving}</p>
                                </div>
                                <div className="d-flex">
                                    <h4 className="tit">Lactation Stage</h4>
                                    <p className="">{cow.currentLactationStage}</p>
                                </div>
                                <div className="d-flex">
                                    <h4 className="tit">Age in Months</h4>
                                    <p className="">{cow.currentAgeMonth}</p>
                                </div>
                                <div className="d-flex">
                                    <h4 className="tit">Body Weight</h4>
                                    <p className="">{cow.currentWeight}</p>
                                </div>
                            </div>
                            <div className="con">
                                <div className="d-flex">
                                    <span className="d_title mr-auto">Reproduction Information</span>
                                </div>
                                <div className="tr th d-flex">
                                    <h5 className="tit">&#40;Recently&#41;</h5>
                                </div>
                                <div className="tr d-flex">
                                    <h5 className="tit">Gestation Date &#40;{}&#41;</h5>
                                    <p className="ml-auto">{cow.recentlyDate}</p>
                                </div>
                                <div className="tr d-flex">
                                    <h5 className="tit">- First Service Date</h5>
                                    <p className="ml-auto">{cow.recentlyFirstDate}</p>
                                </div>
                                <div className="tr d-flex">
                                    <h5 className="tit">- Second Service Date</h5>
                                    <p className="ml-auto">{cow.recentlySecondDate}</p>
                                </div>
                                
                                <div className="tr th d-flex">
                                    <h5 className="tit">&#40;Previous&#41;</h5>
                                </div>
                                <div className="tr d-flex">
                                    <h5 className="tit">
                                        Gestation Date 
                                    </h5>
                                    <p className="ml-auto">{cow.previousDate}</p>
                                </div>
                                <div className="tr d-flex">
                                    <h5 className="tit">- First Service Date</h5>
                                    <p className="ml-auto">{cow.previousFirstDate}</p>
                                </div>
                                <div className="tr d-flex">
                                    <h5 className="tit">- Second Service Date</h5>
                                    <p className="ml-auto">{cow.previousSecondDate}</p>
                                </div>
                            </div>
                            <div className="con">
                                <div className="d-flex">
                                    <span className="d_title mr-auto">Genetic Information</span>
                                </div>
                                <table>
                                    <thead>
                                        <tr className="border-b">
                                            <th className="bg">Blood</th>
                                            <th className="bg t-center">Grade</th>
                                            <th className="bg t-center pr-10">Sperm</th>
                                            <th className="bg t-right pr-10">Cow's No.</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="">Father</td>
                                            <td className="t-center">{cow.cowFarterGrade}</td>
                                            <td className="t-center">{cow.cowFarterSperm}</td>
                                            <td className="t-right pr-10">{cow.cowFarterNumber}</td>
                                        </tr>
                                        <tr>
                                            <td className="">Mother</td>
                                            <td className="t-center">{cow.cowMotherGrade}</td>
                                            <td className="t-center">{}</td>
                                            <td className="t-right pr-10">{cow.cowMotherNumber}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </td>
                </tr>
            </CSSTransition>
        </>
    )
}

function CowMng() {
    const [cowList, setCowList] = useState([]);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;

    useEffect(() => {
        getCowList();
    }, [currentFarmCode]);

    async function getCowList() {
        const response = await api.get(`/farm/myCowList?farmCode=${current}`);
        console.log('response', response);
        
        // 성공
        if (response.data.code === "200") {
            setCowList(response.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    console.log('cowList', cowList);

    // accordion
    const [click, setClick] = useState(null);
    function handleClick(id) {
        if (click === id) {
            setClick(null);
        } else {
            setClick(id);
        }
    }

    return (
        <div className="container">
            <h1 className="title">{getMessage("Cowmng.cowmanagement")}</h1>
            <div className="con">
                <div className="content_header d-flex">
                    <span className="icon_wrap not_bg">
                        <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                    </span>
                    <span className="div_title mr-auto">
                        {getMessage("Cowmng.mycow")} &#40;Total : {cowList.count}&#41;
                    </span>
                    <Link to="/cow_mng/reg" className="button">
                        <img className="icon" src="/assets/images/common/icon_reg.png" alt="registration" />
                    </Link>
                </div>
                <div className="content_body">
                    <table>
                        <thead>
                            <tr>
                                <th>{getMessage("Main.cowsno")}</th>
                                <th className="t-center">{getMessage("Cowmng.sensor")}</th>
                                <th className="t-center">{getMessage("Cowmng.weight")}</th>
                                <th className="t-center">{getMessage("Cowmng.ageinmonth")}&#40;parity&#41;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                cowList?.datas &&
                                cowList?.datas.map((cow) => {
                                    return (
                                        <AccordionItem
                                            cow={cow}
                                            key={cow.seq}
                                            handleClick={handleClick}
                                            click={click}
                                            setClick={setClick}
                                        />
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    );
}

export default CowMng;
  