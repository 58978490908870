/* eslint-disable */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import api from '../../libs/api';

import { Alert } from '../../components/Alert';
import { removeCookie } from './../../libs/auth/Cookie';

function WithdrawalConfirm({ setStepIdx, saveWithdrawalData }) {
    return (
        <>
            <div className="popup">
                <div className="popup_header">
                
                </div>
                <div className="popup_body">
                    <div className="d-flex">
                        <div className="popup_alert">
                            <img src="/assets/images/common/icon_alert.png" alt="" />
                        </div>
                        <span>
                            Are you sure you want to leave? <br />
                            You can rejoin up to n days after withdrawal.
                        </span>
                    </div>
                </div>
                <div className="popup_footer d-flex">
                    <a className="button cancel ml-auto mr-10" onClick={() => { setStepIdx(0) }}>No</a>
                    <a className="button mr-auto" onClick={saveWithdrawalData}>Yes</a>
                </div>
            </div>
            <div className="popup_overlay"></div>
        </>
    )
}

function WithdrawalDone() {

    // 회원 탈퇴 후 Yes 클릭시 로그아웃
    function logout() {
        window.location.href = "/login";
    }

    return (
        <div>
            <div className="content_body t-center">
                You've been processed for withdrawal. <br /><br />
                Thank you for using it.
            </div>
            <div className="content_footer d-flex s-ard">
                <a onClick={logout} className="button cancel">Yes</a>
            </div>
        </div>
    )
}

function MembershipWithdrawal() {   
    const [reason, setReason] = useState({});
    const [stepIdx, setStepIdx] = useState(0);
    
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);

    const [reasonList, setReasonList] = useState([
        { label: "It's hard to use the service", value: "It's hard to use the service" },
        { label: "Due to frequent errors and failures", value: "Due to frequent errors and failures" },
        { label: "Because the benefits are small", value: "Because the benefits are small" },
        { label: "Plan to sign up for a new account", value: "Plan to sign up for a new account" },
        { label: "I'm not satisfied with the service", value: "I'm not satisfied with the service" },
        { label: "etc...(Direct enter)", value: "etc" },
    ]);

    // TODO 회원탈퇴 이유 목록 하드코딩?/ API인 경우에 해야함
    async function getReasonList() {
        const response = await api.post("/farm/farmSave", reason);

        // // 성공
        // if (response.data.code === "200") {
        //     setReasonList(response.data.datas);
        // } else { // 실패
        //     // setAlertMsg(response.data.msg);
        //     // setAlertOpen(true);
        //     console.log('error msg: ', response.data.msg);
        // }
    }

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: "",
    });
    
    useEffect(() => {
        getReasonList();
    }, []);

    // 회원탈퇴 서버 전송
    async function saveWithdrawalData() {
        console.log('reason', reason);
        const response = await api.post("/users/deleteDemand", reason);
        console.log('response', response);

        // 성공
        if (response.data.code === "200") {
            setStepIdx(2); 
            // 로그아웃 시키기
            // localStorage.removeItem("userInfo");
            // localStorage.removeItem("farmCode");
            // localStorage.removeItem("expiresAt");
            // localStorage.removeItem("termAgree")
            // removeCookie("accessToken");
            // removeCookie("refreshToken");
        } else { // 실패
            setAlertMsg(response.data.msg);
            setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    function withdrawal(data) {
        let params = {
            demandWhy: data.demandWhy === "etc" ? data.etcReason : data.demandWhy,
            deleteMemo: data.deleteMemo,
        }
        setReason(params);
        setStepIdx(1);
    }

    return (
        <div className="container">
            <h1 className="title">CS/ Usage guide</h1>
            {
                stepIdx !== 2 &&
                <form 
                    name="withdrawal" 
                    onSubmit={handleSubmit((data) => withdrawal(data))}
                >
                    <div className="content_header d-flex mb-10">
                        <span className="icon_wrap not_bg">
                            <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                        </span>
                        <span className="div_title mr-auto">
                            Membership withdrawal
                        </span>
                    </div>
                    <div className="content_body">
                        <div className="mb-10">
                            <label htmlFor="withdrawalReason">1. Please Check, why you are leaving the service.</label> <br />
                            {errors.withdrawalReason && <span className="err_msg">This field is required</span>}
                            <ul className="mt-5">
                                {
                                    reasonList.map((list) => {
                                        return (
                                            <li key={list.value}>
                                                <div className="switch radio">
                                                    <input 
                                                        type="radio"
                                                        name="demandWhy" 
                                                        id={list.value}
                                                        value={list.value}
                                                        {...register("demandWhy", { required: true })}
                                                    />
                                                    <label htmlFor={list.value}>
                                                        <span className="out">
                                                            <span className="in"></span>
                                                        </span>
                                                        <span className="ml-20">
                                                            {list.label}
                                                        </span>
                                                    </label>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            <input 
                                type="text" 
                                id="etcReason" 
                                name="etcReason" 
                                className="w-100"
                                placeholder="Enter your answer"
                                {...register("etcReason", 
                                    { required: watch("demandWhy") === "etc" ? true : false }
                                )}
                                readOnly={watch("demandWhy") !== "etc"}
                                value={
                                    watch("demandWhy") !== "etc" ? "" : watch("etcReason")
                                }
                            />
                            {(errors.etcReason && watch("demandWhy") === "etc") && <span className="err_msg">This field is required</span>}
                        </div>
                        <div className="">
                            <label htmlFor="roughage">2. Please tell me the improvement of the service</label> <br />
                            <div className="textarea_wrap">
                                <textarea 
                                    id="deleteMemo" 
                                    name="deleteMemo" 
                                    placeholder="Enter your answer" 
                                    // className="mt-5"
                                    {...register("deleteMemo", { required: true })} 
                                />
                            </div>
                            {errors.deleteMemo && <span className="err_msg">This field is required</span>}
                        </div>
                    </div>
                    <div className="btn_wrap content_footer d-flex s-btw">
                        <Link to="/service_center" className="button cancel">Cancel</Link>
                        <button type="submit" className="button">
                            Withdrawal  
                        </button>
                    </div>
                </form>
            }

            { 
                stepIdx === 1 && 
                <WithdrawalConfirm 
                    setStepIdx={setStepIdx} 
                    saveWithdrawalData={saveWithdrawalData} 
                /> 
            }
            { stepIdx === 2 && <WithdrawalDone /> }

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    );
}

export default MembershipWithdrawal;