/* eslint-disable */
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import Select from "react-select";
import { useState, useEffect } from 'react';

import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';

import CustomOption from './../../../components/CustomOption';
import DateTermBtn from './../../../components/DateTermBtn';
import api from './../../../libs/api';
import { calculateDate, getDate } from '../../../libs/getDate';
import LineChart from './../../../components/charts/LineChart';
import GroupedBarChart from './../../../components/charts/GroupedBarChart';

import { useRecoilState } from "recoil";
import { farmCode } from './../../../store/store';
import { Alert } from '../../../components/Alert';
import CustomDatePicker from "../../../libs/CustomDatePicker";

function FarmDetail({ cowNumber, groupKey, groupType, current, setAlertMsg, setAlertOpen }) {
    const [startDate, setStartDate] = useState(calculateDate(-1));
    const [dateTerm, setDateTerm] = useState("day");
    const [farmDetailGraph, setFarmDetailGraph] = useState([]);
    const locale = localStorage.getItem("locale");

    useEffect(() => {
        getFarmDetailGraph();
    }, [cowNumber, groupKey, groupType, startDate, current]);
    async function getFarmDetailGraph() {
        const params = {
            cowNumber: cowNumber,
            inputDate : getDate(startDate),
            seachInputDate : getDate(startDate),
            farmCode: current,
            groupKey: groupKey,
            groupType: groupType,
        }
        
        const response = await api.post('/health/cowPerData', params);
        // 성공
        if (response.data.code === "200") {
            setFarmDetailGraph(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    console.log('farmDetailGraph', farmDetailGraph);

    const categories = ['Productivity Score', 'Milk yield', 'FCE', 'HC'];

    return (
        <div className="farm_details main_content">
            <div className="content_header d-flex">
                <span className="icon_wrap">
                    <img src="/assets/images/common/icon_farm_detail.png" alt="" />
                </span>
                <span className="div_title">Cow Details</span>
                <div className="date_btn_wrap ml-auto">
                    {/* <DateTermBtn dateTerm={dateTerm} setDateTerm={setDateTerm} /> */}
                </div>
                <div className="datepicker_wrap from_to solo">
                    <img src="/assets/images/common/icon_calendar.png" className="calendar" alt="" />
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        // locale={ko}
                        dateFormat={locale === 'en' ? 'yy.MM.dd' : 'dd.MM.yy' }
                    />
                    <img src="/assets/images/common/icon_calendar_arrow.png" className="arrow" alt="" />
                </div>
            </div>
            
            <div className="graph">
                <div className="status_guide">
                    <span className="danger"></span>danger
                    <span className="good"></span>good
                    <span className="excellent"></span>excellent
                </div>

                <GroupedBarChart 
                    data={farmDetailGraph[1]} 
                    legend={false} 
                    categories={"Active"} 
                />

                <GroupedBarChart 
                    data={farmDetailGraph[3]} 
                    legend={false} 
                    categories={"Sleep"} 
                />

                <GroupedBarChart 
                    data={farmDetailGraph[2]} 
                    legend={false} 
                    categories={"Rumination"} 
                />

                <GroupedBarChart 
                    data={farmDetailGraph[0]} 
                    legend={true} 
                    categories={"THI"} 
                />
            </div>
        </div>
    )
}

function HealthyCowDetail() {
    const [startDate, setStartDate] = useState(calculateDate(-30));
    const [endDate, setEndDate] = useState(calculateDate(-1));
    const [dateTerm, setDateTerm] = useState("day");
    const [groupCowList, setGroupCowList] = useState([]);
    const [healthyCowDetail, setHealthyCowDetail] = useState([]);
    const [titleScore, setTitleScore] = useState([]);
    const [graphArray, setGraphArray] = useState(["Milk"]);
    
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);

    const urlParams = useParams();
    const history = useHistory();
    const location = useLocation();
    
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;

    let groupKey = "";
    let groupType = "";
    let cowNumber = "";

    if (urlParams.id.indexOf("_") > -1) {
        let keyType = urlParams.id.split("_");
        groupKey = keyType[0];

        switch (keyType[1]) {
            case "danger": groupType = "D"; break;
            case "good": groupType = "G"; break;
            case "excellent": groupType = "E"; break;
        }
    } else {
        cowNumber = urlParams.id;
    }

    useEffect(() => {
        getGroupCowList();
    }, [cowNumber, groupKey, groupType, startDate, endDate, currentFarmCode]);
    async function getGroupCowList() {
        const params = {
            farmCode : current,
            inputDate : getDate(endDate),
            groupKey : groupKey,
            groupType: groupType,
            cowNumber: cowNumber 
        }
        const response = await api.post('/health/healthCowSearchList', params);
        
        // 성공
        if (response.data.code === "200") {
            setGroupCowList(response.data.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    // console.log('groupCowList', groupCowList);

    useEffect(() => {
        getHealthyCowDetail();
    }, [startDate, endDate, cowNumber, graphArray, groupKey, groupType, currentFarmCode]);
    async function getHealthyCowDetail() {
        const params = {
            cowNumber: cowNumber,
            startDate : getDate(startDate),
            endDate : getDate(endDate),
            farmCode: current,
            inputDate: getDate(endDate),
            arrGraphType: graphArray,
            groupKey : groupKey,
            groupType: groupType,
        }
        const response = await api.post('/health/healthCowGraph', params);
        
        // 성공
        if (response.data.code === "200") {
            setHealthyCowDetail(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    console.log('healthyCowDetail', healthyCowDetail);

    useEffect(() => {
        getTitleScore();
    }, [currentFarmCode]);
    
    async function getTitleScore() {
        const params = {
            farmCode : current,
            inputDate : getDate(calculateDate(-1))
        }
        const response = await api.post('/health/titleScore', params);
        
        // 성공
        if (response.data.code === "200") {
            setTitleScore(response.data.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    // 이전, 다음 젖소번호
    let prevCowNo = 0;
    let nextCowNo = 0;
    if (groupCowList.cowList !== undefined) {
        const currentCowNo = groupCowList.cowList.findIndex(cow => cow.number === groupCowList.cowNumber);

        prevCowNo = groupCowList.cowList[currentCowNo - 1] === undefined ? groupCowList.cowList[groupCowList.cowList.length - 1] : groupCowList.cowList[currentCowNo - 1];

        nextCowNo = groupCowList.cowList[currentCowNo + 1] === undefined ? groupCowList.cowList[0] : groupCowList.cowList[currentCowNo + 1];
    }

    const groupOptions = [
        { value: "all", label: "ALL" },
        { value: "Score", label: "Score" },
        { value: "Score_danger", label: "danger" },
        { value: "Score_good", label: "good" },
        { value: "Score_excellent", label: "excellent" },
    ];
    const [selectOption, setSelectOption] = useState(groupOptions[0]);

    function groupChange(option) {
        if (option.value !== "all") {
            // 그룹으로 이동
            if (option.value === "Score" || option.value === "Milk" || option.value === "FCE" || option.value === "HC") {
                history.push(`/health_condition/cow_group/${option.value}`);
            } else {
                // cow no 없이 상세로 이동
                history.push(`/health_condition/cow_list/${option.value}`);
            }
        }
    }

    function selectCow(option) {
        if (option.value !== "danger" && option.value !== "good" && option.value !== "excellent") {
            history.push(`/health_condition/cow_list/${option.value}`);
        }
    }

    let cowOptions = [];
    if (groupCowList.cowList !== undefined) {
        for (let cow of groupCowList?.cowList) {
            cowOptions.push({
                value: cow.number,
                label: cow.number,
                status: cow.status
            });
        }
    }

    const selectDefault = { 
        value: groupCowList.cowNumber, 
        label: groupCowList.cowNumber
    };

    const filterStyles = {
        control: base => ({
            ...base,
            height: 50,
            minHeight: 50
        })
    }

    function checkboxChange(e) {
        if (e.target.checked) {
            if (graphArray.indexOf(e.target.name) < 0) {
                setGraphArray([...graphArray, e.target.name]);
            }
        } else {
            if (graphArray.indexOf(e.target.name) > -1 && e.target.name !== "Milk") {
                setGraphArray(graphArray.filter((arr) => arr !== e.target.name));
            }
        }
    }
        
    let danger = document.createElement("span");
    danger.setAttribute("class", "danger");
    let good = document.createElement("span");
    good.setAttribute("class", "good");
    let excellent = document.createElement("span");
    excellent.setAttribute("class", "excellent");
    setTimeout(() => {
        const toolbarSelect = document.getElementsByClassName("toolbarSelect");
        const select1 = toolbarSelect[0].getElementsByClassName("select__single-value")[0];
        const select1Value = select1?.innerHTML;
        if (select1Value === "danger") {
            select1.prepend(danger);
        }
        if (select1Value === "good") {
            select1.prepend(good);
        }
        if (select1Value === "excellent") {
            select1.prepend(excellent);
        }
    }, 0);    
    setTimeout(() => {
        const toolbarSelect = document.getElementsByClassName("toolbarSelect");
        const select2 = toolbarSelect[1].getElementsByClassName("select__single-value")[0];

        const status = cowOptions.filter((option) => option.value === groupCowList.cowNumber)[0]?.status;

        if (status === "D") {
            select2.prepend(danger);
        }
        if (status === "G") {
            select2.prepend(good);
        }
        if (status === "E") {
            select2.prepend(excellent);
        }
    }, 0);
    const locale = localStorage.getItem("locale");
    return (
        <div className="content health_condition_detail">
            <div className="farm_score_div content_wrap">
                <div className="score_wrap d-flex">
                    <img src="/assets/images/common/icon_graph.png" alt="" />
                    <div className="title">
                        <span className="sub">Farm</span>
                        <h2 className="score">Health Score {titleScore.score} &#40; Top {titleScore.topPer}% &#41;</h2>
                    </div>
                </div>

                <div className="toolbar d-flex">
                    <Link to="/health_condition" className="round_btn">View Farm</Link>
                    <Select
                        className="select_container toolbarSelect ml-auto"
                        options={groupOptions}
                        onChange={groupChange}
                        defaultValue={location?.state?.defaultValue}
                        styles={filterStyles}
                        classNamePrefix="select"
                        components={{ Option: CustomOption }}
                    />
                </div>

                <div className="cow_detail main_content">
                    <div className="cow_selector d-flex j-center">
                        {
                            prevCowNo &&
                            <Link to={`/health_condition/cow_list/${prevCowNo.number}`} className="arrow_prev">
                                <img src="/assets/images/common/arrow_prev.png" alt="" />
                            </Link>
                        }
                        <Select
                            className="select_container ml-auto mr-auto toolbarSelect"
                            options={cowOptions}
                            onChange={selectCow}
                            value={selectDefault}
                            styles={filterStyles}
                            classNamePrefix="select"
                            components={{ Option: CustomOption }}
                        />
                        {
                            nextCowNo &&
                            <Link to={`/health_condition/cow_list/${nextCowNo.number}`} className="arrow_next">
                                <img src="/assets/images/common/arrow_next.png" alt="" />
                            </Link>
                        }
                    </div>
                    <div className="content_header d-flex">
                        <span className="icon_wrap not_bg">
                            <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                        </span>
                        <span className="div_title">#{groupCowList.cowNumber}</span>
                        <div className="ml-auto">
                            {/* <DateTermBtn dateTerm={dateTerm} setDateTerm={setDateTerm} /> */}
                        </div>
                        <div className="datepicker_wrap from_to">
                            <img src="/assets/images/common/icon_calendar.png" className="calendar" alt="" />
                            <CustomDatePicker
                                selectedDate={startDate}
                                endDate={endDate}
                                onStartDateChange={(date) => setStartDate(date)}
                                onEndDateChange={(date) => setEndDate(date)}
                                locale={locale}
                            />
                            <img src="/assets/images/common/icon_calendar_arrow.png" className="arrow" alt="" />
                        </div>
                    </div>
                    <div className="graph">
                        <div className="checkbox_wrap d-flex">
                            <div className="checkbox">
                                <input type="checkbox" id="active" name="ActivityH" onChange={checkboxChange} />
                                <label htmlFor="active">Active</label>
                            </div>

                            <div className="checkbox">
                                <input type="checkbox" id="sleep" name="SleepH" onChange={checkboxChange} />
                                <label htmlFor="sleep">Sleep</label>
                            </div>

                            <div className="checkbox">
                                <input type="checkbox" id="rumination" name="RuminantH" onChange={checkboxChange} />
                                <label htmlFor="rumination">Rumination</label>
                            </div>

                            <div className="checkbox">
                                <input type="checkbox" id="milk_production" name="Milk" onChange={checkboxChange} checked readOnly />
                                <label htmlFor="milk_production">Milk Production</label>
                            </div>

                        </div>
                        <div className="checkbox_wrap d-flex">
                            <div className="checkbox">
                                <input type="checkbox" id="thi" name="THI" onChange={checkboxChange} />
                                <label htmlFor="thi">THI</label>
                            </div>

                            <div className="checkbox">
                                <input type="checkbox" id="humidity" name="Humidity" onChange={checkboxChange} />
                                <label htmlFor="humidity">Humidity</label>
                            </div>

                            <div className="checkbox">
                                <input type="checkbox" id="temperature" name="Temperature" onChange={checkboxChange} />
                                <label htmlFor="temperature">Temperature</label>
                            </div>
                        </div>
                        <LineChart data={healthyCowDetail} title="farm_status" />
                    </div>
                </div>
            </div>      

            <FarmDetail 
                cowNumber={cowNumber} 
                groupKey={groupKey} 
                groupType={groupType} 
                current={current} 
                setAlertMsg={setAlertMsg} 
                setAlertOpen={setAlertOpen} 
            />

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    )
}

export default HealthyCowDetail;