/* eslint-disable */
import { useEffect, useState } from 'react';

import api from './../../libs/api';

import { useRecoilState } from "recoil";
import { farmCode } from '../../store/store';
import { numberPipe } from '../../libs/pipe';
import { Alert } from '../../components/Alert';
import { Link } from 'react-router-dom';
import getMessage from "../../libs/getMessage";

function ServiceChange({ serviceList, current, setAlertMsg, setAlertOpen }) {
    const [tabIdx, setTabIdx] = useState(0);
    // const [basicService, setBasicService] = useState([]);
    const [dailyService, setDailyService] = useState([]);
    const [oneTimeService, setOneTimeService] = useState([]);
    const [premiumService, setPremiumService] = useState([]);
    // const [packageService, setPackageService] = useState([]);

    const [open, setOpen] = useState(false);
    const [popupType, setPopupType] = useState();
    
    const [targetPopupOpen, setTargetPopupOpen] = useState(false);
    const [targetProduction, setTargetProduction] = useState(0); // 목표 산유량

    const [serviceArr, setServiceArr] = useState([]);
    const [serviceIdxArr, setServiceIdxArr] = useState([]);

    const [orderItem, setOrderItem] = useState({}); // 자유형(구독형, 선택형) 구매 상품
    const [pkCode, setPkCode] = useState(""); // pkg 코드

    const [dailyCredit, setDailyCredit] = useState(0); // 일일 사용 포인트
    // const [monthlyCredit, setMonthlyCredit] = useState(0);

    useEffect(() => {
        // 현재 사용중인 서비스에 따라 탭 이동
        // if (serviceList.myServiceType === "ITEM") {
        //     setTabIdx(0);
        // } else {
        //     setTabIdx(1);
        // }

        // setBasicService(serviceList.basicTypeList); // Built-in Services
        setDailyService(serviceList.dailyTypeList); // Subscription Services
        setOneTimeService(serviceList.oneTimeTypeList); // Optional Services
        setPremiumService(serviceList.preminumTypeList); // Premium Services
        // setPackageService(serviceList.packageTypeList?.reverse()); // Package Serivces

        
        // 포인트 계산
        // if (serviceList.basicTypeList) { // 기본형
        //     for (let list of serviceList.basicTypeList) {
        //         setMonthlyCredit(monthlyCredit + Number(list.itemPoint));
        //     }
        // }
        let point = 0;
        if (serviceList.dailyTypeList) { // 구독형
            for (let list of serviceList.dailyTypeList) {
                if (list.myTempItem === "1") {
                    point = point + Number(list.itemPoint);
                    setDailyCredit(point);
                    // setDailyCredit(dailyCredit + Number(list.itemPoint));
                }
            }
        }
        if (serviceList.preminumTypeList) { // 프리미엄
            for (let list of serviceList.preminumTypeList) {
                if (list.myTempItem === "1") {
                    point = point + Number(list.itemPoint);
                    setDailyCredit(point);
                    // setDailyCredit(dailyCredit + Number(list.itemPoint));
                }
            }
        }

    }, [serviceList, current]);

    console.log('serviceList', serviceList);
    
    // 사용중인 서비스, 서비스 idx 각 배열에 추가
    function usageArray() {
        let useIdxArray = [];
        let useArray = [];
        const useDaily = dailyService?.filter((list) => list.myTempItem === "1");
        const usePremium = premiumService?.filter((list) => list.myTempItem === "1");
        const useService = useDaily?.concat(usePremium);
        if (useService) {
            for (let list of useService) {
                useIdxArray.push(list.itemCode); // 사용중인 subscription service itemCode 추가
                useArray.push(list); // 사용중인 subscription service 추가
            }

            setServiceIdxArr(useIdxArray);
            setServiceArr(useArray);
        }
    }

    // 사용중인 서비스 idx 배열에 추가
    useEffect(() => {
        usageArray();
    }, [dailyService, premiumService, current]);

    // 자유형/패키지형 탭
    // function setTab(idx) {
    //     if (idx === 1) {
    //         usageArray();
    //     }
    //     setTabIdx(idx);
    // }

    // 체크박스 변경 이벤트
    function checkboxChange(e, list) {
        if (e.target.checked) {
            setServiceIdxArr([
                ...serviceIdxArr,
                list.itemCode
            ]); // itemCode 배열에 추가

            setServiceArr([
                ...serviceArr,
                list
            ]); // list 배열에 추가

            setDailyCredit(dailyCredit + Number(list.itemPoint)); // 포인트 계산
        } else {
            setServiceIdxArr(serviceIdxArr.filter((service) => service !== list.itemCode)); // idx 배열에서 삭제

            setServiceArr(serviceArr.filter((service) => JSON.stringify(service) !== JSON.stringify(list))); // list 배열에서 삭제

            setDailyCredit(dailyCredit - Number(list.itemPoint)); // 포인트 계산
        }

        console.log('serviceArr', serviceArr);
    }

    // 팝업 오픈
    function popupOpen(type, item) {
        setPopupType(type);
        console.log('type', type, 'item', item);
        console.log('serviceArr', serviceArr);

        if (type === "onetime") {
            // Optional Service
            setOrderItem(item.itemCode);
            setOpen(true);
        } else {
            // Subscription Service validation
            if (serviceArr.length < 1) {
                setAlertMsg(getMessage("Purchasingproduct.alertmsg"));
                setAlertOpen(true);
            } else {
                setOpen(true);
            }
        }
    }

    // 패키지 선택
    function selectPk(code) {
        setPkCode(code);
    }

    // 구매하기
    async function order(type) {
        let params = {
            farmCode: current,
        }

        let url = "";

        if (tabIdx === 1) {
            // 패키지형
            params.serviceType = pkCode; // 패키지 코드
            params.serviceItems = "";
        } else {
            // 자유형
            if (type === "daily") { // 구독형
                url = "/farm/serviceSave";

                let resultArr = serviceIdxArr.filter((service, i) => serviceIdxArr.indexOf(service) === i); // 중복 제거
                params.serviceItems = resultArr.join(","); // 배열 문자열로 변환
                params.serviceType = "ITEM";
                
            } else { // 선택형
                url = "/farm/serviceOneTimeInsert";

                params.itemCode = orderItem;

                if (orderItem === "IT002") {
                    params.targetProduction = targetProduction;
                }
            }
        }
        console.log(params);

        const response = await api.post(url, params);
        console.log('response', response);

        // 성공
        if (response.data.code === "200") {
            setOpen(false);
            setAlertMsg("서비스 구매가 완료되었습니다.");
            setAlertOpen(true);
        } else { // 실패
            setOpen(false);
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }
    
    return (
        <div className="">
            <h1 className="title">{getMessage("Purchasingproduct.servicesinuse")} &gt; {getMessage("Purchasingproduct.purchasingaproduct")}</h1>
            <div className="con">
                <div className="content_header d-flex">
                    <span className="icon_wrap not_bg">
                        <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                    </span>
                    <span className="div_title mr-auto">
                        {getMessage("Purchasingproduct.selectservicetype")} &#40;{getMessage("Purchasingproduct.applied")} : { tabIdx === 0 ? "Custom" : "Package" }&#41;
                        <span className="exp">{getMessage("Purchasingproduct.packagetypecannotbechangedtofreetype")}</span>
                    </span>
                </div>
                {/* TODO 패키지 다시 생길 경우 주석 해제해야함 */}
                {/* <div className="content_body">
                    <ul className="service_tab tab_header d-flex">
                        <li className={ tabIdx === 0 ? "active" : "" }>
                            <a onClick={() => { setTab(0) }}>Custom</a>
                        </li>
                        <li className={ tabIdx === 1 ? "active ml-auto" : "ml-auto" }>
                            <a onClick={() => { setTab(1) }}>Package</a>
                        </li>
                    </ul>
                </div> */}
            </div>
            <div className="tab_content">
                {
                    tabIdx === 0 && // Custom
                    <div className="tab01 ">
                        {/* <div className="con">
                            <div className="sub_con d-flex">
                                <h5 className="sub_tit">Built-in Services</h5>
                                <span className="ml-auto">200 / month</span>
                            </div>
                            <ul>
                                {
                                    basicService?.map((list, i) => {
                                        return (
                                            <li className="border_round" key={list.itemCode}>
                                                <span className="mr-5 number title_round">{i+1}</span> 	
                                                {list.itemName}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div> */}
                        <div className="con">
                            <div className="sub_con d-flex">
                                <h5 className="sub_tit">{getMessage("Purchasingproduct.subscriptionservice")}</h5>
                            </div>
                            <ul>
                                {
                                    dailyService?.map((list, i) => {
                                        return (
                                            <li key={list.itemCode} className="d-flex">
                                                <div className="border_round txt">
                                                    <span className="mr-5 text title_round">{numberPipe(list.itemPoint)}{getMessage("Purchasingproduct.creditday")}</span>
                                                    <span className="ml-auto text">{list.itemName}</span>
                                                </div>
                                                
                                                <div className="switch">
                                                    <input 
                                                        type="checkbox" 
                                                        id={`subscription${i}`}
                                                        defaultChecked={list.myTempItem === "1" ? true : false}
                                                        onChange={(e) => { 
                                                            checkboxChange(e, list);
                                                        }}
                                                    />
                                                    <label htmlFor={`subscription${i}`}>
                                                        <span className="rail"></span>
                                                        <span className="switch"></span>
                                                    </label>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="con">
                            <h5 className="sub_tit">{getMessage("Purchasingproduct.optionalservices")}</h5>
                            <span className="exp">{getMessage("Purchasingproduct.immediately")}</span>
                            <ul>
                                {
                                    oneTimeService?.map((list, i) => {
                                        return (
                                            <li key={list.itemCode} className="d-flex">
                                                <div className="border_round txt">
                                                    <span className="mr-5 text title_round">{numberPipe(list.itemPoint)}{getMessage("Purchasingproduct.credit")}</span>
                                                    <span className="text">{list.itemName}</span>
                                                </div>
                                                
                                                <a className="order_btn button sm" onClick={() => { popupOpen("onetime", list) }}>{getMessage("Purchasingproduct.buy")}</a>
                                            </li>
                                            // 스위치형으로 바뀔 경우 대비
                                            // <li key={list.itemCode} className="d-flex">
                                            //     <div className="border_round txt">
                                            //         <span className="mr-5 text title_round">{numberPipe(list.itemPoint)} credit/day</span>
                                            //         <span className="ml-auto text">{list.itemName}</span>
                                            //     </div>
                                                
                                            //     <div className="switch">
                                            //         <input 
                                            //             type="checkbox" 
                                            //             id={`optional${i}`}
                                            //             defaultChecked={list.myTempItem === "1" ? true : false}
                                            //             onChange={(e) => { 
                                            //                 checkboxChange(e, list);
                                            //             }}
                                            //         />
                                            //         <label htmlFor={`optional${i}`}>
                                            //             <span className="rail"></span>
                                            //             <span className="switch"></span>
                                            //         </label>
                                            //     </div>
                                            // </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="con">
                            <h5 className="sub_tit">{getMessage("Purchasingproduct.premiumservice")}</h5>
                            <span className="exp">{getMessage("Purchasingproduct.bymilkt")}</span>
                            <ul>
                                {
                                    premiumService?.map((list, i) => {
                                        return (
                                            <li key={list.itemCode} className="d-flex">
                                                <div className="border_round txt">
                                                    <span className="mr-5 text title_round">{numberPipe(list.itemPoint)}{getMessage("Purchasingproduct.creditday")}</span>
                                                    <span className="ml-auto text">{list.itemName}</span>
                                                </div>
                                                
                                                <div className="switch">
                                                    <input 
                                                        type="checkbox" 
                                                        id={`premium${i}`}
                                                        defaultChecked={list.myTempItem === "1" ? true : false}
                                                        onChange={(e) => { 
                                                            checkboxChange(e, list);
                                                        }}
                                                    />
                                                    <label htmlFor={`premium${i}`}>
                                                        <span className="rail"></span>
                                                        <span className="switch"></span>
                                                    </label>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="con">
                            <div className="content_header d-flex">
                                <span className="icon_wrap not_bg">
                                    <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                                </span>
                                <span className="div_title mr-auto">
                                    {getMessage("Purchasingproduct.summaryofpurchasedetails")}
                                </span>
                                <span className="cow_count">
                                    &#40;{getMessage("PurchasingProduct.countofcows")}{serviceList?.farmCowCnt}&#41;
                                </span>
                            </div>
                            <div className="content_body">
                                <table className="dashed">
                                    <colgroup>
                                        <col width="*" />
                                        <col width="80px" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th className="bg">{getMessage("Purchasingproduct.servicename")}</th>
                                            <th className="bg pr-10 t-right">{getMessage("Purchasingproduct.credit")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            serviceArr?.map((list, i) => {
                                                return (
                                                    <tr key={list.itemCode}>
                                                        {/* <td>{i === 0 && "Subscription"}</td> */}
                                                        <td>{list.itemName}</td>
                                                        <td className="pr-10 t-right">{numberPipe(Number(list?.itemPoint)*Number(serviceList?.farmCowCnt))}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        
                                        <tr className="total">
                                            <td>Total</td>
                                            {/* <td>The first day of every month</td> */}
                                            <td className="pr-10 t-right">
                                                {
                                                    numberPipe(Number(dailyCredit)*Number(serviceList?.farmCowCnt)) 
                                                    ? numberPipe(Number(dailyCredit)*Number(serviceList?.farmCowCnt)) 
                                                    : 0
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }

                {/* custom/package 탭 */}
                {/* {
                    tabIdx === 1 && // Package
                    <div className="tab02 ">
                        {
                            packageService?.map((pkg) => {
                                return (
                                    <div className="con" key={pkg.pkCode}>
                                        <div className="d-flex">
                                            <input 
                                                type="radio" 
                                                name="package" 
                                                id={pkg.pkCode}
                                                onChange={() => { selectPk(pkg.pkCode) }}
                                            />
                                            <label htmlFor={pkg.pkCode}>
                                                <div className="d-flex po-re header">
                                                    <span className="out">
                                                        <span className="in"></span>
                                                    </span>
                                                    <div className="d-flex w-100 con_tit">
                                                        <h3 className="mr-auto">{pkg.pkName}</h3>
                                                        <span>{numberPipe(pkg.pkPoint)} / daily</span>
                                                    </div>
                                                </div>
                                                <table className="pkg_table dashed">
                                                    <colgroup>
                                                        <col width="22%" />
                                                        <col width="56%" />
                                                        <col width="22%" />
                                                    </colgroup>
                                                    <thead>
                                                        <tr>
                                                            <th className="bg">Type</th>
                                                            <th className="bg">Service name</th>
                                                            <th className="bg pr-10 t-right">credit</th>
                                                        </tr>
                                                    </thead>
                                                    {
                                                        pkg.itemList.map((list, i) => {
                                                            return (
                                                                <tbody 
                                                                    key={list.itemCode}
                                                                    className={
                                                                        list.itemType ==="B" 
                                                                        ? "builtin" 
                                                                        : list.itemType === "D"
                                                                            ? "subscription"
                                                                            : "optional"
                                                                    }
                                                                >
                                                                    {
                                                                        list.itemType ==="B" &&
                                                                        <tr className={`builtin${i}`}>
                                                                            <td><span>Built-in</span></td>
                                                                            <td>{list.itemName}</td>
                                                                            <td className="pr-10 t-right">{numberPipe(list.itemPoint)}</td>
                                                                        </tr>
                                                                    }
                                                                    {
                                                                        list.itemType ==="D" &&
                                                                        <tr className={`subscription${i}`}>
                                                                            <td><span>Subscription</span></td>
                                                                            <td>{list.itemName}</td>
                                                                            <td className="pr-10 t-right">{numberPipe(list.itemPoint)}</td>
                                                                        </tr>
                                                                    }
                                                                    {
                                                                        list.itemType ==="O" &&
                                                                        <tr className={`optional${i}`}>
                                                                            <td><span>Optional</span></td>
                                                                            <td>{list.itemName}</td>
                                                                            <td className="pr-10 t-right">{numberPipe(list.itemPoint)}</td>
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            )
                                                        })
                                                    }
                                                    <tbody className="total">
                                                        <tr>
                                                            <td>Total</td>
                                                            <td>The first day of every month</td>
                                                            <td className="pr-10 t-right">{numberPipe(pkg.pkPoint)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td>Daily</td>
                                                            <td className="pr-10 t-right">{numberPipe(pkg.pkPoint)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </label>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                } */}

                {/* TODO Expectation 서비스 적용시 예상수치 API 생기면 해야함 */}
                {/* <div className="con">
                    <div className="content_header d-flex">
                        <span className="icon_wrap not_bg">
                            <img src="/assets/images/common/icon_abnormal_daily_cow.png" alt="" />
                        </span>
                        <span className="div_title mr-auto">
                            Expectation
                        </span>
                    </div>
                    <div className="content_body">
                        <table className="dashed striped expectation">
                            <thead>
                                <tr>
                                    <th className="bg">Subject</th>
                                    <th className="bg">Unit.</th>
                                    <th className="bg t-right pr-10">Before</th>
                                    <th className="bg t-right pr-10">After</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Milk Production</td>
                                    <td>L/day</td>
                                    <td className="t-right pr-10">400</td>
                                    <td className="t-right pr-10">420</td>
                                </tr>
                                <tr>
                                    <td>Feed cost</td>
                                    <td>Kg/day</td>
                                    <td className="t-right pr-10">320,000</td>
                                    <td className="t-right pr-10">280,000</td>
                                </tr>
                                <tr>
                                    <td>Profit</td>
                                    <td>won/day</td>
                                    <td className="t-right pr-10">160,000</td>
                                    <td className="t-right pr-10">224,000</td>
                                </tr>
                                <tr>
                                    <td>Carbon Emission</td>
                                    <td>CH4g/day</td>
                                    <td className="t-right pr-10">68,000</td>
                                    <td className="t-right pr-10">5950</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> */}
                
                <div className="content_footer btn_wrap d-flex">
                    <Link to="/service_mng" className="button cancel">{getMessage("Customize.cancel")}</Link>
                    <a className="button ml-auto" onClick={() => { popupOpen("daily", "") }}>{getMessage("Customize.application")}</a>
                </div>
            </div>
            {
                open && <ServicePopup popupType={popupType} setOpen={setOpen} order={order} orderItem={orderItem} setTargetPopupOpen={setTargetPopupOpen} />
            }
            {
                targetPopupOpen && <TargetProductionPopup popupType={popupType} order={order} setTargetPopupOpen={setTargetPopupOpen} setTargetProduction={setTargetProduction} />
            }
        </div>
    );
}

function ServicePopup({ popupType, setOpen, order, orderItem, setTargetPopupOpen }) {
    console.log('orderItem', orderItem);

    return (
        <>
            <div className="popup">
                <div className="popup_header">
                    {/* <h3>서비스 변경</h3> */}
                </div>
                <div className="popup_body">
                    <div className="d-flex">
                        <div className="popup_alert">
                            <img src="/assets/images/common/icon_alert.png" alt="" />
                        </div>
                        <span>
                            {
                                popupType === "onetime" &&
                                <p>
                                    {getMessage("Purchasingproduct.msg0")}<br/>
                                    구매하시겠습니까?
                                </p>
                            }
                            {
                                popupType === "daily" &&
                                <p>
                                    {getMessage("Purchasingproduct.msg1")}<br/>
                                    {getMessage("Purchasingproduct.msg2")}
                                </p>
                            }
                        </span>
                    </div>
                    
                </div>
                <div className="popup_footer d-flex">
                    <a className="button cancel ml-auto mr-10" onClick={() => { setOpen(false) }}>No</a>
                    
                    <a className="button mr-auto" onClick={() => { order(popupType) }}>Yes</a>
                    {/* TODO 목표산유량 입력 받게되면 이거 주석해제하면됨 */}
                    {/* {
                        orderItem !== "IT002"
                        ? <a className="button mr-auto" onClick={() => { order(popupType) }}>Yes</a>
                        : <a className="button mr-auto" onClick={() => { setTargetPopupOpen(popupType); setOpen(false); }}>Yes</a>
                    } */}
                </div>
            </div>
            <div className="popup_overlay"></div>
        </>
    )
}

// 목표 산유량 팝업
function TargetProductionPopup({ popupType, order, setTargetPopupOpen, setTargetProduction }) {

    return (
        <>
            <div className="popup">
                <div className="popup_header">
                    {/* <h3>서비스 변경</h3> */}
                </div>
                <div className="popup_body">
                    <p>목표 산유량을 입력해주세요.</p>
                    <input type="text" onChange={(e) => { setTargetProduction(e.target.value) }} />                    
                </div>
                <div className="popup_footer d-flex">
                    <a className="button cancel ml-auto mr-10" onClick={() => { setTargetPopupOpen(false) }}>No</a>
                    <a className="button mr-auto" onClick={() => { order(popupType); setTargetPopupOpen(false); }}>Yes</a>
                </div>
            </div>
            <div className="popup_overlay"></div>
        </>
    )
}


function PurchasingProduct() {
    const [serviceList, setServiceList] = useState({});
    const [cowCount, setCowCount] = useState();
    
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    
    const [currentFarmCode, setCurrentFarmCode] = useRecoilState(farmCode);
    let current = currentFarmCode.farmCode === undefined ? currentFarmCode : currentFarmCode.farmCode;

    useEffect(() => {
        getServiceList();
    }, [currentFarmCode]);

    async function getServiceList() {
        const response = await api.get(`/farm/serviceList?farmCode=${current}`);
        // console.log('response', response);

        // 성공
        if (response.data.code === "200") {
            setServiceList(response.data.data);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log('error msg: ', response.data.msg);
        }
    }

    return (
        <div className="container">            
            <ServiceChange 
                serviceList={serviceList} 
                current={current} 
                setAlertMsg={setAlertMsg} 
                setAlertOpen={setAlertOpen} 
            />

            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </div>
    );
}

export default PurchasingProduct;