/* eslint-disable */
import { useState, useEffect } from 'react';

import api from '../libs/api';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { Alert, AlertPath } from '../components/Alert';
import { useHistory } from 'react-router-dom';

function Join() {
    const [partnerList, setPartnerList] = useState([]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertPathOpen, setAlertPathOpen] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const history = useHistory();

    useEffect(() => {
        getPartnerList();
    }, []);  

    // 파트너 리스트 불러오기
    async function getPartnerList() {
        const response = await api.get('/users/partnerList');

        // 성공
        if (response.data.code === "200") {
            setPartnerList(response.data.datas);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true)
            console.log('error msg: ', response.data.msg);
        }
    }

    console.log('partnerList', partnerList);

    // 파트너 options 세팅
    let partnerArr = [];    
    for (let partner of partnerList) {
        partnerArr.push({
            label: partner.value,
            value: partner.key,
        })
    }
    
    const { watch, register, handleSubmit, formState: { errors }, control } = useForm({});

    // 회원가입 서버 전송
    async function registration(params) {
        console.log(params);
        const response = await api.post("/users/insert", params);
        console.log(response);

        // 성공
        if (response.data.code === "200") {
            setAlertMsg("회원가입이 완료되었습니다.");
            setAlertPathOpen(true);
        } else { // 실패
            // setAlertMsg(response.data.msg);
            // setAlertOpen(true);
            console.log(response.data.msg)
        }
    }
    
    const filterStyles = {
        control: base => ({
            ...base,
            height: 50,
            minHeight: 50
        })
    }
    
    // 이메일 유효성 정규식
    const emailRegex = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    function goToLogin() {
        history.push("/login");
    }

    return (
        <form 
            name="login"
            className="registration"
            onSubmit={handleSubmit((data) => registration(data))}
        >
            <div className="login_form_header t-center">
                <img src="/assets/images/common/thinkforbl_logo.png" alt="logo" />
            </div>
            
            <div className="input_wrap">                
                <div className="userId">
                    <div className="d-flex">
                        <label htmlFor="email">ID</label>
                        <input 
                            type="text" 
                            id="userId" 
                            name="userId" 
                            placeholder="Enter your e-mail account" 
                            {...register("userId", {
                                required: "This field is required",
                                pattern: {
                                    value: emailRegex,
                                    message: "Please enter your email in the correct format"
                                },
                            })}
                        />
                    </div>
                    {errors.userId && <span className="err_msg">{errors.userId.message}</span>}
                </div>

                <div className="pass1">
                    <div className="d-flex">
                        <label htmlFor="pass1">Password</label>
                        <input 
                            type="password" 
                            id="pass1" 
                            name="pass1" 
                            placeholder="Enter your password" 
                            {...register("pass1", { required: true })}
                        />
                    </div>
                    {errors.pass1 && <span className="err_msg" style={{ width: "100%", background: "#fff", textAlign: "right" }}>This field is required</span>}
                </div>
                
                <div className="pass2">
                    <div className="d-flex">
                        <label htmlFor="passwordConfirm">Password confirm</label>
                        <input 
                            type="password" 
                            id="pass2" 
                            name="pass2" 
                            placeholder="Confirm your password"
                            {...register("pass2", { 
                                required: "This field is required",
                                validate: (val) => {
                                    if (watch('pass1') !== val) {
                                        return "Password incorrect";
                                    }
                                }
                            })}
                        />
                    </div>
                    {errors.pass2 && <span className="err_msg" style={{ width: "100%", background: "#fff", textAlign: "right" }}>{errors.pass2.message}</span>}
                </div>

                <div className="name">
                    <div className="d-flex">
                        <label htmlFor="name">User name</label>
                        <input 
                            type="text" 
                            id="name" 
                            name="name" 
                            placeholder="Enter your name" 
                            {...register("name", { required: true })}
                        />
                    </div>
                    {errors.name && <span className="err_msg">This field is required</span>}
                </div>

                <div className="phoneNumber">
                    <div className="d-flex">
                        <label htmlFor="phone">Phone</label>
                        <input 
                            type="text" 
                            id="phoneNumber" 
                            name="phoneNumber" 
                            placeholder="Enter your Phone number" 
                            {...register("phoneNumber", { required: true })}
                        />
                    </div>
                    {errors.phone && <span className="err_msg">This field is required</span>}
                </div>

                <div className="partnerId">
                    <div className="">
                        <label htmlFor="name">Select Partner</label>
                        <Controller
                            name="partnerId"
                            control={control}
                            rules={{ required: true }}
                            render={({ field:{ fieldState, onChange } }) => (
                                <Select
                                    className="select_container toolbarSelect"
                                    options={
                                        partnerList.map(partner => {
                                            return {
                                                label: partner.value,
                                                value: partner.key,
                                            }
                                        })
                                    }
                                    onChange={(val) => { 
                                        onChange(val.value);
                                    }}
                                    styles={filterStyles}
                                    classNamePrefix="select"
                                />
                            )}
                        />
                    </div>
                    {errors.partnerId && <span className="err_msg">This field is required</span>}
                </div>
            </div>

            <div className="content_footer">
                <div className="d-flex mt-10 s-ard">
                    <a className="button cancel" onClick={goToLogin}>Cancel</a>
                    <button type="submit" className="button ">Join</button>
                </div>
            </div>

            <div className="login_footer">
                <p className="footer_copy">
                    <span>
                        <img src="/assets/images/common/thinkfarm_logo.png" alt="thinkfarm logo" />
                        ThinkFarm Copyright &copy; ThinkforBL. Inc. All rights Reserved. Provacy Policy
                    </span>
                </p>
            </div>
            
            {
                alertPathOpen && <AlertPath alertMsg={alertMsg} setAlertOpen={setAlertPathOpen} path="/login" join={true} />
            }
            {
                alertOpen && <Alert alertMsg={alertMsg} setAlertOpen={setAlertOpen} />
            }
        </form>
    );
}

export default Join;