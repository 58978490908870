/* eslint-disable */

import { useState, useEffect } from "react";
import Chart from 'react-apexcharts'

// 차트
function Radar({ data }) {

    const [state, setState] = useState({
        options: {
            chart: {
                type: 'radar',
                dropShadow: {
                    enabled: true,
                    blur: 1,
                    left: 1,
                    top: 1
                }
            },
            stroke: {
                width: 2,
                // dashArray: [0, 2],
            },
            colors: ['#bab9fc', '#ff4c4c'],
            fill: {
                opacity: 0.5
            },
            markers: {
                size: 0
            },
            xaxis: {
                categories: ['Productivity', 'Health Condition', 'Carbon status', 'Profitability', 'Milk Production'],
            }
        },
        series: [],
    });

    useEffect(() => {
        data?.scoreArr &&
        setState({
            options: {
                chart: {
                    type: 'radar',
                    dropShadow: {
                        enabled: true,
                        blur: 1,
                        left: 1,
                        top: 1
                    }
                },
                stroke: {
                    width: 2,
                    // dashArray: [0, 2],
                },
                colors: ['#bab9fc', '#ff4c4c'],
                fill: {
                    opacity: 0.5,
                },
                markers: {
                    size: 0
                },
                xaxis: {
                    categories: ['Milk Production', 'Health Condition', 'Carbon status', 'Profitability', 'Productivity'],
                }
            },
            series: [
                {
                    name: "Now",
                    data: data.scoreArr
                },
                {
                    name: "Goal",
                    data: data.goalScoreArr
                },
            ],
        })
    }, [data]);

    return (
        <div className="chart radar t-center">
            <Chart options={state.options} series={state.series} type="radar" width="100%" height="340px" />
        </div>
    );
}

export default Radar;