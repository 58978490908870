import { components } from 'react-select';

function CustomOption({ children, ...props }) {
    return (
        <components.Option {...props}>
            <span className={props.data.status ? props.data.status : children}></span>
            {children}
        </components.Option>
    )
}

export default CustomOption;